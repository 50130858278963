import React from 'react'
import './FooterLanguageLinks.scss'
import {useTracking} from 'react-tracking'
import {collectEventAnalytics} from 'metrics/Analytics'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {LocaleContext} from 'contexts/LocaleContext'

const FooterLanguageLinks = () => {

    const tracking = useTracking()

    const changeLanguageCode = (language, changeLanguageCallback) => {
        changeLanguageCallback(language.code)
        collectEventAnalytics(tracking,
            'footer',
            'click',
            `language: ${language.name}`,
            'change language',
            {language: language.name})
    }

    const renderFooterLink = (language, changeLanguageCallback, i) => (
        <span key={i}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
                <button id={`language-link-${language.code}`}
                        aria-label={language.name}
                        title={language.name}
                        onClick={changeLanguageCode.bind(this, language, changeLanguageCallback)}>
                    {language.name}
                </button>
            </a>
        </span>
    )

    return (
        <SiteLayoutContext.Consumer>
            {siteConfig =>
                <LocaleContext.Consumer>
                    {localeData =>
                        <div className="Footer-footerLanguageHolder">
                            {siteConfig.language.selectorEnabled && siteConfig.language.languages.map((language, i) => (
                                renderFooterLink(language, localeData.changeLanguage, i)
                            ))}
                        </div>
                    }
                </LocaleContext.Consumer>
            }
        </SiteLayoutContext.Consumer>
    )
}

export default FooterLanguageLinks
