import React from 'react'

/**
 * Saved Items context that provides & updates a list saved items within the session
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} savedItemsData - List of saved items
 */
export const SavedItemsContext = React.createContext(/* savedItemsData */)
