import React, {useState, useContext, memo} from 'react'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useTracking} from 'react-tracking'
import SimpleSearch from 'components/SimpleSearch'
import PreviewBanner from 'components/PreviewBanner'
import HeaderDropdown from 'components/HeaderDropdown'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {CollectionContext} from 'contexts/CollectionContext'
import {collectEventAnalytics} from 'metrics/Analytics'
import doubleDecode from 'utils/doubleDecode'
import './Header.scss'
import he from "he";

const Header = memo(function Header() {

    const tracking = useTracking()
    const intl = useIntl()
    let logoRefs = React.createRef()
    const collectionConfig = useContext(CollectionContext)
    const siteConfig =  useContext(SiteLayoutContext)
    const [logoInfo, setLogoInfo] = useState({
        isBanner: false,
        logoTopMargin: 0,
        logoLeftMargin: 0
    })

    const isBannerLogo = () => {
        const logoImage = logoRefs.current

        return logoImage.naturalHeight !== 0
            && logoImage.naturalWidth * 150 / logoImage.naturalHeight > 800
            && logoImage.naturalWidth / logoImage.naturalHeight > 2
    }

    const handleImageLoaded = () => {
        const logoImage = logoRefs.current
        let topMargin = 0
        let leftMargin = 0

        let isBanner
        let logoTopMargin
        let logoLeftMargin

        if (isBannerLogo()) {
            isBanner = true
            logoTopMargin = topMargin
            logoLeftMargin = leftMargin
        } else {
            if (logoImage.naturalHeight < 150) {
                topMargin = (150 - logoImage.naturalHeight) / 2
            }
            if (logoImage.naturalWidth < 150) {
                leftMargin = (150 - logoImage.naturalWidth) / 2
            }

            isBanner = false
            logoTopMargin = topMargin
            logoLeftMargin = leftMargin
        }

        setLogoInfo({
            ...logoInfo,
            isBanner,
            logoTopMargin,
            logoLeftMargin,
            opacity: 1
        })
    }

    const headerProps = (collectionConfig && collectionConfig.body && collectionConfig.body.header &&
        (collectionConfig.body.header.overrideHeaderGlobal ? collectionConfig.body.header : false)) || siteConfig.header
    // If there is no logo, force the centered display of the title.
    const mobileTitleDisplay = () => {
        return headerProps.logo.url === '' || !headerProps.logo.useOptional || headerProps.hideHeaderText
            ? 'Header-mobileTitleDisplay ' : ''
    }

    // Remove the forcing for browser / tablet
    const mobileTitleHide = () => {
        return headerProps.logo.url !== '' && headerProps.logo.useOptional
            ? 'Header-mobileTitleHide ' : ''
    }

    // Handle the WCT setting that forces a title hide in browser and tablet modes.
    const browserTabletTitleHide = () => {
        return (headerProps.logo.url !== ''
            && headerProps.logo.useOptional) && headerProps.hideHeaderText
            ? 'Header-browserTabletTitleHide ' : ''
    }

    /*const siteNameText = this.props.collectionSiteName || this.props.intl.formatMessage(messages.siteName)
    const clickUrl = this.props.headerUrl*/

    // These classes are switched on if the header image is a banner.
    const bannerModeOverlayText = logoInfo.isBanner ? 'Header-overlayTitleText ' : ''
    const bannerModeVerticalCenter = logoInfo.isBanner ? 'Header-verticalCenter ' : ''
    const bannerModeLogoFullWidth = logoInfo.isBanner ? 'Header-logoImageBanner ' : ''
    const bannerModeLogoPadding = logoInfo.isBanner ? 'Header-bannerPadding ' : ''

    // We set the logo margin to center it if the logo is smaller than 150 x 150.
    const logoMargin = {
        'marginTop': logoInfo.logoTopMargin,
        'marginLeft': logoInfo.logoLeftMargin,
        // it prevents flickering of the logo briefly
        'opacity': logoInfo.opacity || 0
    }

    const siteNameMessage = intl.messages.SITE_CONFIG_siteName
        ? he.decode(intl.formatMessage({id: 'SITE_CONFIG_siteName', defaultMessage: ''})) : ''
    const handleHeaderTitle = () => {
        const headerTitle = collectionConfig && collectionConfig.messages && collectionConfig.messages.SITE_CONFIG_siteName
            ? collectionConfig.messages.SITE_CONFIG_siteName.length > 0
                ? collectionConfig.messages.SITE_CONFIG_siteName
                : siteNameMessage
            : siteNameMessage
        return doubleDecode(headerTitle)
    }

    const handleImage = (siteConfig) => {

        let imgSuffix
        let imgPrefix = ''
        if (process.env.NODE_ENV !== 'production') {
            imgPrefix = `https://${process.env.REACT_APP_STATIC_HOST}`
        }

        //short-circuit eval
        collectionConfig
            && collectionConfig.status === 200
            && collectionConfig.body.header.overrideHeaderGlobal
            && collectionConfig.body.header.logo.url && collectionConfig.body.header.logo.useOptional
        ? imgSuffix = collectionConfig.body.header.logo.url
        : imgSuffix = siteConfig.header.logo.url

        const imgSrc = `${imgPrefix}${imgSuffix}`

        return (
            <img
                data-id={'siteLogo'}
                alt={siteNameMessage}
                src={imgSrc}
                tabIndex={1}
                className={'Header-logoImage ' + bannerModeLogoFullWidth + bannerModeLogoPadding}
                style={logoMargin}
                onLoad={handleImageLoaded}
                ref={logoRefs}
            />

        )
    }

    const getHomeUrl = (siteConfig) => {
        let headerUrl = '/'
        if(collectionConfig && collectionConfig.body) {
            const collectionSpecificHeader = collectionConfig.body.header && collectionConfig.body.header.overrideHeaderGlobal;
            const customURL = collectionConfig.body.customUrlEnabled && collectionConfig.body.customURL != null
                ? collectionConfig.body.customURL : null
            if (collectionSpecificHeader) {
                headerUrl = customURL || `/digital/collection/${collectionConfig.body.id}`;
            } else {
                headerUrl = (siteConfig.homepage || {}).customURL || headerUrl;
            }
        } else {
            headerUrl = (siteConfig.homepage || {}).customURL || headerUrl;
        }

        return headerUrl
    }

    const renderLogo = (siteConfig) => {
        let link = getHomeUrl(siteConfig)
        return link.includes('http') ?
            <a href={getHomeUrl(siteConfig)} tabIndex={-1}
               onClick={() => {collectEventAnalytics(tracking, 'navigation',
                     'click', 'Header Home', 'home link', {source: 'header'})}}>
                {handleImage(siteConfig)}
            </a>
            : <Link to={getHomeUrl(siteConfig)} tabIndex={-1}
                 onClick={() => {collectEventAnalytics(tracking, 'navigation',
                     'click', 'Header Home', 'home link', {source: 'header'})}}>
                {handleImage(siteConfig)}
            </Link>
    }

    const renderTitle = (siteConfig) => {
        return <a href={getHomeUrl(siteConfig)} tabIndex={-1} onClick={() => {collectEventAnalytics(tracking, 'navigation',
            'click', 'Header Home')}}>
            <h2 className={'Header-titleText header-text ' + bannerModeOverlayText}
                tabIndex={2} data-id={'headerText'}>
                {handleHeaderTitle()}
            </h2>
        </a>
    }

    return (
        <div className={'Header-headerContainer navbar-default'}>
            {siteConfig.preview.enabled ? <PreviewBanner /> : undefined}
            {/* Actual Header */}
            <div className={'Header-header'} >
                {/* Logo and Name holder */}
                <div className={'Header-logoNameContainer'}>
                    {/* Logo */}
                    <div className={'Header-logoHolder'} >
                        {siteConfig.header.logo.url && siteConfig.header.logo.useOptional
                            ? <div className={'Header-logoWrapper'} >
                                {renderLogo(siteConfig)}
                            </div>
                            : undefined
                        }
                    </div>
                    {/* Site Name */}
                    <div
                        id='headerNameDiv'
                        className={'Header-nameHolder '
                        + mobileTitleDisplay()
                        + mobileTitleHide()
                        + browserTabletTitleHide()}>
                        {renderTitle(siteConfig)}
                    </div>
                </div>
                {/* Search Bar + buttons + hamburger menu */}
                <div className={'Header-controls ' + bannerModeVerticalCenter}>
                    {/* hamburger menu */}
                    <HeaderDropdown />
                    {/* Simple Search Component */}
                    <SimpleSearch/>
                </div>
            </div>
            <div className="clearfix"/>
            <div className={'Header-valerieBar'}>
                <div className={'Header-valerieBarContainer'} />
            </div>
        </div>
    )
})

export default Header
