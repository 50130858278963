import {useReducer} from 'react'

const initialState = {
    totalResults: 0,
    paginatedItems: [{}]
}

/**
 * Creates an itemPagingReady action for the useReducer dispatch call
 *
 * @param {array} paginatedItems Paginated item search results
 * @param {number} totalResults The total number of search results
 * @returns {{payload: {itemPaging}, type: string}}
 */
export const itemPagingReady = (paginatedItems, totalResults) => {
    const normalizedItemIds = paginatedItems.map(item => ({
        collectionAlias: item.collectionAlias,
        itemId: item.itemId
    }))
    return {
        type: 'itemPagingReady',
        payload: {
            paginatedItems: normalizedItemIds,
            totalResults
        }
    }
}

/**
 * Object that contains implementations of all the actions used by this hook with useReducer
 *
 * @type {object}
 */
const actions = {
    itemPagingReady: (state, action) => {
        const { paginatedItems, totalResults } = action.payload
        return { ...state, paginatedItems: [ ...paginatedItems], totalResults }
    }
}

/**
 * Item paging reducer function for useReducer hook
 *
 * @param state The requested state for the context to use
 * @param action The name of the action to perform
 * @returns {*}
 */
const itemPagingReducer = (state, action) => {
    return actions[action.type](state, action)
}

/**
 * This hook provides item paging state as well as a dispatch to perform various actions around item paging data
 *
 * @returns {[itemPagingState, dispatch]}
 */
export const useItemPaging = () => {

    return useReducer(itemPagingReducer, initialState)
}
