import encodeUrl from 'encodeurl'

const getPattern = (field) => {
    return new RegExp(`/${field}/([^/?]+)`)
}

/**
 * Maps the search field name from the URL to the SearchRequest object
 * that we send to the server to perform a search
 *
 * key:   search field name in URL
 * value: search field name in SearchRequest
 *
 * @type {Map}
 */
export const searchFields = {
    collection: 'collection',
    searchterm: 'query',
    field: 'field',
    mode: 'mode',
    conn: 'connector',
    order: 'order',
    ad: 'orderDirection',
    page: 'page',
    facetfield: 'facetField',
    parentid: 'parentId',
    includecompound: 'includeCompound'
}

/**
 * Parses the URL to obtain the search request object
 *
 * @param location - The current url path
 * @returns {Promise<Object>} - SearchRequest object for search body
 */
export const parseUrlForSearchRequest = (location) => new Promise((resolve) => {
    let searchRequest = {}

    for (let [urlField, searchRequestField] of Object.entries(searchFields)) {

        let replacementRegEx = /%2F/gi;
        let fieldValue = ''
        let fieldMatch

        fieldMatch = location.pathname.match(getPattern(urlField))

        if (fieldMatch && fieldMatch[1]) {
            fieldValue = fieldMatch[1]
        }

        searchRequest[searchRequestField] = encodeUrl(fieldValue)
            .replace(replacementRegEx, '%252F')
    }

    resolve(searchRequest)
})

/**
 * Parses the url for a single collection id or a string of collection ids delimited by '!'.  Returns null if one or
 * more collections are not found
 *
 * @param {Object} location - Contains the url provided by react router dom
 * @returns {List<String>> A list of collection ids, null if no collections are found
 */
export const parseUrlForCollectionIds = (location) => {

    let collections = null
    const collectionsMatch = location.pathname.match(getPattern('collection'))

    if (collectionsMatch && collectionsMatch[1]) {
        collections = collectionsMatch[1]
    }

    return collections && collections.split('!')
}

/**
 * Parses the string for a single collection id or a string of collection ids delimited by '!'.  Returns null if one or
 * more collections are not found
 *
 * @param {string} path - Part of the path we wish to search for collection ids
 * @returns {List<String>> A list of collection ids, null if no collections are found
 */
export const parsePathForCollectionIds = (path) => {

    let collections = null
    const collectionsMatch = path.match(getPattern('collection'))

    if (collectionsMatch && collectionsMatch[1]) {
        collections = collectionsMatch[1]
    }

    return collections && collections.split('!')
}

/**
 * Parses the url for the page number
 *
 * @param {Object} location - Contains the url provided by react router dom
 * @returns {String} the page number
 */
export const parseUrlForPage = (location) => {
    let page = null
    const pageMatch = location.pathname.match(getPattern('page'))
    if (pageMatch && pageMatch[1]) {
        page = pageMatch[1]
    }
    return page
}

/**
 * Add the page path to the url.  If the page already exists in the path, overlay it.
 *
 * e.g. ...search/page/5
 *
 * @param {object} location
 * @param {Number} pageNumber
 * @returns {string} url with page path
 */
export const addPageToUrl = (location, pageNumber) => {
    let url = location.pathname.replace(getPattern('page'), `/page/${pageNumber}`)

    const match = url.match(getPattern('page'))
    if (!match) {
        url = `${location.pathname}/page/${pageNumber}`
    }

    return url
}

export const removePageFromUrl = (location) => {
    return location.pathname.replace(getPattern('page'), ``)
}

export const getQueryStringParams = (searchRequest, resultsPerPage, pageNumber) => {
    const collectionPath = searchRequest.collection ? `/collection/${encodeURI(searchRequest.collection)}` : ''
    const searchterm = searchRequest.query ? `/searchterm/${(searchRequest.query)}` : ''
    const field = searchRequest.field ? `/field/${searchRequest.field}` : ''
    const mode = searchRequest.mode ? `/mode/${searchRequest.mode}` : ''
    const conn = searchRequest.connector ? `/conn/${searchRequest.connector}` : ''
    const sortOrderAndDirection = searchRequest.order ? `/order/${searchRequest.order}/ad/${searchRequest.orderDirection}` : ''
    const page = pageNumber ? `/page/${pageNumber}` : ''
    const maxRecordsPath = `/maxRecords/${resultsPerPage}`

    return {
        collectionPath,
        searchterm,
        field,
        mode,
        conn,
        sortOrderAndDirection,
        page,
        maxRecordsPath
    }
}
