import React, {useState, useCallback, useContext, useRef} from 'react'
import PropTypes from 'prop-types'
import {Modal, Button} from 'react-bootstrap'
import {MAX_SAVED_ITEMS} from 'constants/SavedItems'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {SavedItemsContext} from 'contexts/SavedItemsContext'
import {useIntl} from 'react-intl'
import useAnalytics from 'hooks/useAnalytics'
import {SavedItemsAction} from 'hooks/useSavedItems'
import './SaveRemoveAllItemsButton.scss'
import he from "he";
import * as ReactDOM from "react-dom/server";

const SaveRemoveAllItemsButton = (props) => {

    const {results} = props

    const intl = useIntl()
    const sendAnalytics = useAnalytics()
    const authData = useContext(AuthenticationContext)
    const [savedItemsData, savedItemsDispatch] = useContext(SavedItemsContext)
    const [show, setShow] = useState(false)
    const saveButtonText = useRef()
    const starIcon = useRef()

    const {savedItemIds} = savedItemsData

    // The "save all" button is "selected" when every search result item on the page is contained in the
    // saved items list
    const isButtonSelected = useCallback(() => {
        return results.items.every((itemSearchResult) => {
            return savedItemIds.some((savedItem) => {
                return savedItem.id === itemSearchResult.itemId
                    && savedItem.alias === itemSearchResult.collectionAlias
            })
        })
    }, [results.items, savedItemIds])

    const handleClose = useCallback(() => {
        setShow(false)
    }, [setShow])

    const solidIcon = useCallback(() => {
        let iconClassList = starIcon.current.classList

        if (isButtonSelected()) {
            if (iconClassList) {
                // open
                iconClassList.remove('fa-star')
                iconClassList.add('fa-star-o')
            }
        } else {
            if (iconClassList) {
                // solid
                iconClassList.remove('fa-star-o')
                iconClassList.add('fa-star')
            }
        }
    }, [starIcon, isButtonSelected])

    const openIcon = useCallback(() => {
        let iconClassList = starIcon.current.classList

        if (isButtonSelected()) {
            if (iconClassList) {
                iconClassList.remove('fa-star-o')
                iconClassList.add('fa-star')
            }
        } else {
            if (iconClassList) {
                iconClassList.remove('fa-star')
                iconClassList.add('fa-star-o')
            }
        }
    }, [starIcon, isButtonSelected])

    const addRemoveAllItems = useCallback(() => {
        const allSelected = isButtonSelected()
        const updatedSavedItems = JSON.parse(JSON.stringify(savedItemIds))

        if (allSelected) {
            // items to remove
            let indices = []
            for (let i = 0; i < savedItemIds.length; i++) {
                results.items.forEach(item => {
                    if (item.itemId === savedItemIds[i].id
                        && item.collectionAlias === savedItemIds[i].alias) {
                        indices.push(i)
                    }
                })
            }

            indices.reverse().forEach(index => updatedSavedItems.splice(index, 1))
            savedItemsDispatch({
                type: SavedItemsAction.SET_SAVED_ITEM_IDS, payload: {
                    savedItemIds: updatedSavedItems,
                    authname: authData.name
                }
            })
        } else {
            // items to add
            results.items.forEach(item => updatedSavedItems.push({alias: item.collectionAlias, id: item.itemId}))
            const uniqSavedItems = []
            let maxReached = false
            updatedSavedItems.forEach(savedItem => {
                if (!uniqSavedItems.some(uniqSavedItem => savedItem.id === uniqSavedItem.id
                    && savedItem.alias === uniqSavedItem.alias)) {
                    if (uniqSavedItems.length < MAX_SAVED_ITEMS) {
                        uniqSavedItems.push(savedItem)
                    } else {
                        maxReached = true
                    }
                }
            })

            if (maxReached) {
                setShow(true)
            }
            savedItemsDispatch({
                type: SavedItemsAction.SET_SAVED_ITEM_IDS, payload: {
                    savedItemIds: uniqSavedItems,
                    authname: authData.name
                }
            })
        }
    }, [authData, savedItemIds, savedItemsDispatch, results.items, isButtonSelected])

    const handleOnClick = useCallback((e) => {
        e.preventDefault()
        e.stopPropagation()
        sendAnalytics('saved items', 'click', 'click save/remove all button', 'save/remove all search results')
        addRemoveAllItems()
    }, [sendAnalytics, addRemoveAllItems])

    const hoverSaveRemove = useCallback(() => {
        if (/(Android|iPod|iPhone|iPad|IEMobile|Windows Phone)/i.test(navigator.userAgent)) {
            return
        }
        solidIcon()
    }, [solidIcon])

    const exitHoverSaveRemove = useCallback(() => {
        if (/(Android|iPod|iPhone|iPad|IEMobile|Windows Phone)/i.test(navigator.userAgent)) {
            return
        }
        openIcon()
    }, [openIcon])

    const removeAllButton = (
        <button type='button' className="SaveRemoveAllItemsButton-favoriteButton btn savedButton"
                aria-label={he.decode(intl.formatMessage({id: 'SITE_KEY_clickToRemoveSaved', defaultMessage: ' '}))}
                onMouseEnter={hoverSaveRemove}
                onMouseLeave={exitHoverSaveRemove}
                onClick={handleOnClick}>
            <span ref={starIcon} className="SaveRemoveAllItemsButton-saveIcon fa fa-star"/>
            <span ref={saveButtonText}>
                {he.decode(intl.formatMessage({id: 'SITE_KEY_removeAll', defaultMessage: ' '}))}
            </span>
        </button>
    )

    const saveAllButton = (
        <button type='button' className="SaveRemoveAllItemsButton-favoriteButton btn saveButton"
                aria-label={he.decode(intl.formatMessage({id: 'SITE_KEY_clickToSave', defaultMessage: ' '}))}
                onMouseEnter={hoverSaveRemove}
                onMouseLeave={exitHoverSaveRemove}
                onClick={handleOnClick}>
            <span ref={starIcon} className="SaveRemoveAllItemsButton-saveIcon fa fa-star-o"/>
            <span ref={saveButtonText}>
                {he.decode(intl.formatMessage({id: 'SITE_KEY_saveAll', defaultMessage: ' '}))}
            </span>
        </button>
    )

    const renderButton = useCallback(() => {
        return isButtonSelected() ? removeAllButton : saveAllButton
    }, [isButtonSelected, removeAllButton, saveAllButton])

    const SAVED_ITEMS_TMX = ReactDOM.renderToString(
        <b>{he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsPageTitle', defaultMessage: ' '}))}</b>)
    const formattedMaxMsg = he.decode(intl.formatMessage({
        defaultMessage: `You have reached the maximum number of {MAX_SAVED_ITEMS, number} for {SAVED_ITEMS_TMX}.`,
        id: 'SITE_KEY_MaxSavedItems'
    }, {SAVED_ITEMS_TMX, MAX_SAVED_ITEMS}))

    const removeSavedItemsInstructionsMsg = he.decode(intl.formatMessage({
        defaultMessage: `You cannot save more items until you visit the {SAVED_ITEMS_TMX} `
            + 'page and remove some items.', id: 'SITE_KEY_RemoveSavedInstruction'
    }, {SAVED_ITEMS_TMX}))

    return (
        <div className="SaveRemoveAllItemsButton-buttonContainer">
            {renderButton()}
            <Modal className="SaveRemoveAllItemsButton-favoritesModal" show={show}
                   onHide={handleClose} autoFocus>
                <Modal.Header closeButton className="SaveRemoveAllItemsButton-maxItemsModalHeader">
                    <Modal.Title componentClass='h1'>
                        {he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsLimit', defaultMessage: ' '}))}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="SaveRemoveAllItemsButton-modalText">
                        <span dangerouslySetInnerHTML={{__html: formattedMaxMsg}} />
                        <br/><br/>
                        <span dangerouslySetInnerHTML={{__html: removeSavedItemsInstructionsMsg}} />
                    </p>
                </Modal.Body>
                <Modal.Footer className="SaveRemoveAllItemsButton-modalFooter">
                    <Button className='cdm-btn' bsStyle='primary'
                            onClick={handleClose}>{he.decode(intl.formatMessage({
                        id: 'SITE_KEY_OK',
                        defaultMessage: ' '
                    }))}</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

SaveRemoveAllItemsButton.propTypes = {
    results: PropTypes.object.isRequired
}

export default SaveRemoveAllItemsButton
