import {DEFAULT_OPTS, getDefaultHeaders} from './ServiceDefaults'

export const getSiteTranslations = async (preview) => {
    let url = preview && preview.enabled ? '/digital/tmx.json?_preview=true' : '/digital/tmx.json'
    if (process.env.NODE_ENV !== 'production') {
        url = `https://${process.env.REACT_APP_STATIC_HOST}${url}`
    }
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                ...getDefaultHeaders(),
            },
            ...DEFAULT_OPTS
        })
        return await response.json()

    } catch (error) {
        return error
    }
};

export const getCollectionTranslations = async(collectionAlias) => {
    let url = `/digital/tmx/${collectionAlias}.json`
    if (process.env.NODE_ENV !== 'production') {
        url = `https://${process.env.REACT_APP_STATIC_HOST}${url}`
    }
    try {
        const response = await fetch(url, {
            method: 'GET',
                headers: {
            ...getDefaultHeaders(),
            },
        ...DEFAULT_OPTS
        })
        return await response.json()

    } catch (error) {
        return error
    }
}
