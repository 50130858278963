import React, {useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'
import he from "he"
import {useViewport} from 'hooks/useViewport'
import {buildSearchUrl} from 'components/Search/SearchUrl'
import {parseUrlForCollectionIds, parsePathForCollectionIds} from 'utils/UrlParameters'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import SearchCollectionFilter from 'components/SearchCollectionFilter'
import Panel from 'components/Panel'
import Facets from 'components/Facets'
import loadable from '@loadable/component'
import './SearchFilters.scss'

const SearchFilters = () => {

    const intl = useIntl()
    const [searchRequest] = useContext(SearchRequestContext)
    const searchResults = useContext(SearchResultsContext)
    const authData = useContext(AuthenticationContext)
    const { isMobile } = useViewport();
    const history = useHistory()

    const MapGenerator = loadable(() => import('components/MapGenerator'))

    const afterCollectionFilterSave = useCallback((searchRequest) => {
        history.push(buildSearchUrl(searchRequest))
    }, [history])

    const initializeSelectedCollections = useCallback(() => {

        let initiallySelectedCollections = []
        if (history.location && history.location.state && history.location.state.collectionScope) {
            initiallySelectedCollections = parsePathForCollectionIds(history.location.state.collectionScope)
            if (initiallySelectedCollections && initiallySelectedCollections.length > 0) {
                initiallySelectedCollections = searchResults.filters.collections.map(filter => {
                    if (initiallySelectedCollections && initiallySelectedCollections.includes(filter.alias)) {
                        filter.selected = true
                        return filter
                    } else {
                        filter.selected = false
                        return filter
                    }
                })
            }
        }

        if (!initiallySelectedCollections || initiallySelectedCollections.length === 0) {
            initiallySelectedCollections = parseUrlForCollectionIds(history.location) || []
            if (!initiallySelectedCollections || initiallySelectedCollections.length === 0) {
                // All were initially selected
                initiallySelectedCollections = searchResults.filters.collections.map(filter => {
                    filter.selected = true
                    return filter
                })
            } else {
                initiallySelectedCollections = searchResults.filters.collections.map(filter => {
                    if (initiallySelectedCollections && initiallySelectedCollections.includes(filter.alias)) {
                        filter.selected = true
                        return filter
                    } else {
                        filter.selected = false
                        return filter
                    }
                })
            }
        }
        return initiallySelectedCollections
    }, [searchResults.filters.collections, history.location])

    const generateMap = () => (

        authData.isWCTAdmin && searchRequest.collection && !searchRequest.collection.includes('!')
            ? <div key={0} className="SearchFilter-collapsible SearchFilter-mapBox">
                <Panel
                    headerTitle={he.decode(intl.formatMessage({
                        id: 'SITE_cdm_search_KEY_header_collections_filter',
                        defaultMessage: ' '
                    }))}
                    expanded={true}
                    collapsible={false}>

                    <MapGenerator key={1} collectionAlias={searchRequest.collection}/>
                </Panel>
            </div>
            : <></>
    )

    const renderCollectionFilter = () => (
        <div className="SearchFilter-collapsible">
            <Panel
                headerTitle={he.decode(intl.formatMessage({
                    id: 'SITE_cdm_search_KEY_header_collections_filter',
                    defaultMessage: ' '
                }))}
                headerText={he.decode(intl.formatMessage({id: 'SITE_KEY_collections', defaultMessage: ' '}))}
                expanded={true}
                collapsible={true}>

                <SearchCollectionFilter getSelectedCollections={initializeSelectedCollections}
                                        afterSave={afterCollectionFilterSave}/>
            </Panel>
        </div>
    )

    // Recall those following cases when considering facet issues
    // Edge case 1: two facets belonging to different keys are same
    // Edge case 2: there is ! in search term as a search term
    const getDisplayableFacets = (facetHeader) => {
        const queryArray = searchRequest.query.split('!');
        return facetHeader.filter((facet) => {
            return !queryArray.includes(encodeURIComponent(facet.title).replace(/%2F/gi, '%252F'));
        });
    };

    const hasFacetsToDisplay = (facetHeader) => {
        return getDisplayableFacets(facetHeader).length > 0;
    };

    const renderFacets = () => {
        const facets = []
        for (const key in searchResults.facets) {
            const facetPanel = hasFacetsToDisplay(searchResults.facets[key])
                ? <div key={key} className="SearchFilter-collapsible">
                    <Panel
                        headerTitle={`${searchResults.facetFields[key]} ${he.decode(intl.formatMessage({
                            id: 'SITE_cdm_search_KEY_header_facet',
                            defaultMessage: ' '
                        }))}`}
                        headerText={searchResults.facetFields[key]}
                        expanded={true}
                        collapsible={true}>

                        <Facets field={key} facetList={getDisplayableFacets(searchResults.facets[key])} />
                    </Panel>
                </div>
                : null
            if (facetPanel) {
                facets.push(facetPanel)
            }
        }
        return facets
    }

    return (
        <div>
            {renderCollectionFilter()}
            {isMobile ? null : generateMap()}
            {renderFacets()}
        </div>
    )
}
export default SearchFilters
