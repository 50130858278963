import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import './SavedItemsRemoveModal.scss'
import he from "he";
import * as ReactDOM from "react-dom/server";

const SavedItemsRemoveModal = (props) => {

    const intl = useIntl()

    const {
        show,
        numberOfSelectedSavedItems,
        onCancel,
        onRemove
    } = props

    const SAVED_ITEMS_TMX = ReactDOM.renderToString(
        <b>{he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsPageTitle', defaultMessage: ' '}))}</b>)
    const removeDialogTxt = he.decode(intl.formatMessage({
        defaultMessage: ' ',
        id: 'SITE_KEY_SavedItemsRemoveWarning'
    }, {
        num_of_selected_items: numberOfSelectedSavedItems,
        SAVED_ITEMS_TMX
    }))
    const removeTxtNotice = he.decode(intl.formatMessage({
        id: 'SITE_KEY_SavedItemsRemoveNotice',
        defaultMessage: ' '
    }, {SAVED_ITEMS_TMX}))

    return (
        <Modal show={show} className="RemoveSavedItemsModal-removeItemsModal" size='lg'
               aria-labelledby={he.decode(intl.formatMessage({
                   id: 'SITE_KEY_SavedItemsRemoveDialogTitle',
                   defaultMessage: ' '
               }))}
               onHide={onCancel} autoFocus>
            <Modal.Header closeButton className="RemoveSavedItemsModal-removeHeader">
                <Modal.Title componentClass='h1'>{he.decode(intl.formatMessage({
                    id: 'SITE_KEY_SavedItemsRemoveDialogTitle',
                    defaultMessage: ' '
                }))}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="RemoveSavedItemsModal-removeDialogText">
                    <span dangerouslySetInnerHTML={{__html: removeDialogTxt}}/>
                    <br/><br/>
                    <span dangerouslySetInnerHTML={{__html: removeTxtNotice}}/>
                </p>
            </Modal.Body>
            <Modal.Footer className="RemoveSavedItemsModal-removeDialogFooter">
                <Button className='cdm-btn' bsStyle='default'
                        onClick={onCancel}>
                    {he.decode(intl.formatMessage({id: 'SITE_KEY_cancel', defaultMessage: ' '}))}</Button>
                <Button className="RemoveSavedItemsModal-modalRemoveButton cdm-btn" bsStyle='primary'
                        onClick={onRemove}>
                    {he.decode(intl.formatMessage({id: 'SITE_KEY_RemoveButton', defaultMessage: ' '}))}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SavedItemsRemoveModal
