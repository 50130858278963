import React from 'react'
import {useIntl} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import {useResultsPerPage} from 'hooks/useResultsPerPage'
import FontAwesome from 'react-fontawesome'
import './BackToResults.scss'
import he from "he";

const BackToResults = () => {

    const intl = useIntl()
    const history = useHistory()
    const {searchResults} = history.location.state
    let {url} = searchResults
    const [resultsPerPage] = useResultsPerPage()
    const recNumber = history.location.pathname.split('/rec/')[1]
    const pageNumber = Math.ceil(recNumber/resultsPerPage)
    url = url.replace(/page(.*)/, `page/${pageNumber}`)

    const messages = {
        backToResults: {
            id: 'SITE_cdm_search_KEY_back_to_results', defaultMessage: ' '
        }
    }

    return <div className="BackToResults-backLink">
        <Link to={url}>
            <FontAwesome name="long-arrow-left fa-lg" className="BackToResults-arrowPadding" />
            {he.decode(intl.formatMessage(messages.backToResults))}
        </Link>
    </div>
}

export default BackToResults
