import {HIT_TYPES} from "./Constants";

export const collectEventAnalytics = (tracking, category, action, label, name, mapOfParams) => {

    const getAnalyticsData = (hitType, category, action, label, name, mapOfParams) => (
        {
            hitType: hitType,
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventName: name,
            params: mapOfParams
        }
    )

    tracking.trackEvent(getAnalyticsData(HIT_TYPES.EVENT, category, action, label, name, mapOfParams))
}

/**
 * helper tag for make url
 *
 * @private
 * @constant
 */
const A_URL_HELPER = document.createElement('a');

/**
 * return to absolute URL by link/pathname based on current domain
 *
 * @example
 * toAbsoluteUrl('test') -> http://example/test
 * toAbsoluteUrl('http://google.com') -> http://google.com
 *
 * @export
 * @param {String} link
 * @returns {String}
 */
export const toAbsoluteUrl = (link) => {
    A_URL_HELPER.href = link;
    return A_URL_HELPER.href;
}

/**
 * utility function
 * more https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits#hitcallback
 *
 * @param {any} callback
 * @param {number} [timeout=1000]
 * @returns {function}
 */
export function createFunctionWithTimeout(callback, timeout = 500) {
    let called = false;
    let id = 0;
    function fn() {
        if (!called) {
            called = true;
            callback();
            id && clearTimeout(id);
        }
    }
    id = setTimeout(fn, timeout);
    return fn;
}
