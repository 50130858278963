import React from 'react'

/**
 * Site layout context that provides site configuration for the entire site
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} siteConfig - Defined by the org.oclc.cdm.model.configuration.SiteConfiguration server domain object
 */
export const SiteLayoutContext = React.createContext(/* siteConfig */)
