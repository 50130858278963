import React, {useContext, useEffect, useState} from 'react'
import {getCollectionCards} from "service/CollectionCards"
import Card from 'components/Card'
import {useHistory, useLocation} from "react-router-dom"
import Paginator from 'components/Paginator'
import FetchingNotification from 'components/FetchingNotification'
import CollectionCardErrorNotification from 'components/CollectionCardErrorNotification'
import ContentHeader from "components/ContentHeader"
import {SiteLayoutContext} from "contexts/SiteLayoutContext"
import {useCdmEventsForCollection} from 'events/useCdmEventsForCollection'
import PageNumberContext from 'hooks/usePageNumber'
import cdmClasses from 'utils/CdmClasses'
import {doubleDecode} from 'utils/doubleDecode'

const CardWrapper = () => {

    const history = useHistory()
    const location = useLocation()
    const siteConfig = useContext(SiteLayoutContext)
    const queryString = require('query-string')
    const parsedQuery = queryString.parse(location.search)
    const homeCardsEnabled = siteConfig.homepage.homepageCardsEnabled

    // Grabs pageNumber from the route
    let pageNumber = history.location.state
        ? history.location.state.page
        : (parseInt(parsedQuery.page) ? parseInt(parsedQuery.page) : 1)

    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingError, setIsLoadingError] = useState(false)

    const [collectionCards, setCollectionCards] = useState()

    useEffect(() => {
        const loadCards = async () => {
            const cardsAndInfo = await getCollectionCards(pageNumber, siteConfig.homepage.homepageCardsPerPage)
            if (cardsAndInfo !== undefined) {
                setCollectionCards(cardsAndInfo)
                setIsLoading(false)
                setIsLoadingError(false)
            } else {
                setIsLoading(false)
                setIsLoadingError(true)
            }
        }
        loadCards()

    }, [pageNumber, siteConfig.homepage.homepageCardsPerPage]);

    const [prevCollectionCards, setPrevCollectionCards] = useState(collectionCards)
    const [updateEventParams, setUpdateEventParams] = useState({ ...collectionCards })
    useEffect(() => {
        if (prevCollectionCards) {
            if (prevCollectionCards !== collectionCards) {
                setUpdateEventParams({ ...collectionCards })
                setPrevCollectionCards(collectionCards)
            }
        } else {
            setPrevCollectionCards(collectionCards)
        }
    }, [collectionCards, prevCollectionCards])
    useCdmEventsForCollection(cdmClasses.HOME_PAGE, updateEventParams, !isLoading)

    useEffect(() => {
        setIsLoading(true)
    }, [pageNumber])

    return (
        isLoading
            ? <><FetchingNotification/><ContentHeader/></>
            : <> <CollectionCardErrorNotification error={isLoadingError}/><ContentHeader/>
                <div className={'CardWrapper-cardscontainer row'} data-testid={"cardsContainer"}>
                    <div className={'CardWrapper-div'}>
                        {homeCardsEnabled && collectionCards ? <PageNumberContext.Provider value={''}>
                            <Paginator pageInfo={{
                                ...collectionCards, pageNumber: pageNumber,
                                itemName: 'SITE_KEY_collectionPaginator', cardsPerPage: siteConfig.homepage.homepageCardsPerPage,
                                className: 'topCollectionCardPaginator', trackPage: 'home page'
                            }}/>

                            {collectionCards.cards.map((card, i) => (
                                    <Card
                                        key={i}
                                        description={card.description}
                                        title={doubleDecode(card.title)}
                                        alias={card.alias}
                                        preview={siteConfig.preview.enabled}
                                        customURL={card.customURL}/>
                                )
                            )}

                            <Paginator pageInfo={{
                                ...collectionCards, pageNumber: pageNumber,
                                itemName: 'SITE_KEY_collectionPaginator', cardsPerPage: siteConfig.homepage.homepageCardsPerPage,
                                className: 'bottomCollectionCardPaginator', trackPage: 'home page'
                            }}/>
                        </PageNumberContext.Provider> : null}
                    </div>
                </div>
            </>
    )
}

export default CardWrapper
