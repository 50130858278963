import React, {useState, useContext, useCallback, useEffect} from 'react'
import {Button, ButtonGroup} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {Action} from 'hooks/useCardView'
import {GRID_VIEW, LIST_VIEW} from 'constants/GridListView'
import CardViewContext  from 'hooks/useCardView'
import useAnalytics from 'hooks/useAnalytics'
import he from "he";

const SearchResultsDisplaySelector = () => {

    const intl = useIntl()
    const sendAnalytics = useAnalytics()

    const [cardView, cardViewDispatch] = useContext(CardViewContext)
    const listViewValue = 'List View'
    const gridViewValue = 'Grid View'

    const listViewButtonLabel = he.decode(intl.formatMessage({id: 'SITE_cdm_search_listView', defaultMessage: ' '}))
    const gridViewButtonLabel = he.decode(intl.formatMessage({id: 'SITE_cdm_search_gridView', defaultMessage: ' '}))

    const [gridViewActiveClass, setGridViewActiveClass] = useState(cardView === GRID_VIEW ? 'btn-primary active' : '')
    const [listViewActiveClass, setListViewActiveClass] = useState(cardView === LIST_VIEW ? 'btn-primary active' : '')

    const onClickListViewButton = useCallback(() => {
        cardViewDispatch({ type: Action.SWITCH_TO_LIST_VIEW })
        setGridViewActiveClass('')
        setListViewActiveClass('btn-primary active')
        sendAnalytics('search results', 'click', `display mode switched to: ${LIST_VIEW}`, 'search result display mode changed', { type: 'list'})
    }, [sendAnalytics, cardViewDispatch])

    const onClickGridViewButton = useCallback(() => {
        cardViewDispatch({ type: Action.SWITCH_TO_GRID_VIEW })
        setGridViewActiveClass('btn-primary active')
        setListViewActiveClass('')
        sendAnalytics('search results', 'click', `display mode switched to: ${GRID_VIEW}`, 'search result display mode changed', { type: 'grid'})
    }, [sendAnalytics, cardViewDispatch])

    useEffect(() => {
        setGridViewActiveClass(cardView === GRID_VIEW ? 'btn-primary active' : '')
        setListViewActiveClass(cardView === LIST_VIEW ? 'btn-primary active' : '')
    }, [cardView])

    return(
        <ButtonGroup name="display-options" className="ResultsDisplaySelector-displayOptionContainer">
            <Button aria-label={listViewButtonLabel}
                    title={listViewButtonLabel}
                    className={`ResultsDisplaySelector-searchHeaderBtnInactive cdm-toggle-btn btn btn-default ${listViewActiveClass}`}
                    value={listViewValue}
                    onClick={onClickListViewButton}>
                <span className="fa fa-th-list" />
            </Button>
            <Button aria-label={gridViewButtonLabel}
                    title={gridViewButtonLabel}
                    className={`ResultsDisplaySelector-searchHeaderBtnInactive cdm-toggle-btn btn btn-default ${gridViewActiveClass}`}
                    value={gridViewValue}
                    onClick={onClickGridViewButton}>
                <span className="fa fa-th" />
            </Button>
        </ButtonGroup>
    )
}

export default SearchResultsDisplaySelector
