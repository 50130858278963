import React, {useContext, useEffect, useState} from 'react'
import CollectionAwareRenderer from 'components/CollectionAwareRenderer'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {CollectionContext} from 'contexts/CollectionContext'
import {FooterContext} from 'contexts/FooterContext'
import {HeaderContext} from 'contexts/HeaderContext'
import {ItemPagingContext} from 'contexts/ItemPagingContext'
import {SavedItemsContext} from 'contexts/SavedItemsContext'
import {SimpleSearchContext} from 'contexts/SimpleSearchContext'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import {SAVED_ITEMS} from 'constants/SavedItems'
import {useAdvancedSearch} from 'hooks/useAdvancedSearch'
import {useHeader, useFooter} from 'hooks/useHeaderAndFooter'
import {useItemPaging} from 'hooks/useItemPaging'
import {useSavedItems, SavedItemsAction} from 'hooks/useSavedItems'
import {getCollectionConfiguration} from 'service/CollectionConfiguration'
import {fetchSavedItems, persistSavedItems} from 'service/SavedItemsService'
import {getCollectionTranslations} from 'service/Translation'
import {getSearchControlData} from 'service/SearchService'

const SiteLayoutProvider = ({render, collection, siteConfig}) => {

    const [header, headerDispatch] = useHeader()
    const [footer, footerDispatch] = useFooter()

    const [collData, setCollData] = useState(null)
    const [simpleSearch, setSimpleSearch] = useState(null)
    const [advancedSearch, dispatch] = useAdvancedSearch()
    const [savedItemsData, savedItemsDispatch] = useSavedItems()
    const [itemPagingState, itemPagingDispatch] = useItemPaging()
    const authentication = useContext(AuthenticationContext)
    const {enableSavedItems} = siteConfig

    useEffect(() => {
        const getCollectionData = async () => {
            const singleCollection = collection && collection.split('!').length === 1 ? collection : null
            let collectionData
            try {
                collectionData = singleCollection ? await getCollectionConfiguration(singleCollection) : null
            } catch (e) {
                console.error(e)
            }
            let collMessages
            try {
                collMessages = singleCollection ? await getCollectionTranslations(singleCollection) : null
            } catch (e) {
                console.error(e)
            }
            let collSearchInfo
            try {
                collSearchInfo = singleCollection ? await getSearchControlData(singleCollection) : null
            } catch (e) {
                console.error(e)
            }
            collectionData = {...collectionData, messages: collMessages, collSearchInfo: collSearchInfo}
            setCollData(collectionData)
        }
        getCollectionData()
    }, [collection])

    useEffect(() => {
        // Sync items from localstorage when the app loads or a user has logged in and saved items is enabled
        if (enableSavedItems && authentication.name) {
            const syncSavedItems = async () => {
                savedItemsDispatch({type: SavedItemsAction.SET_LOADING, payload: true})
                const savedItemIds = JSON.parse(localStorage.getItem(SAVED_ITEMS)) || []
                const response = await persistSavedItems(savedItemIds, 'SAVE_PERSONAL_LIST', authentication.name)
                savedItemsDispatch({type: SavedItemsAction.SET_INITIAL_SAVED_ITEMS,
                    payload: response.ids
                })
                savedItemsDispatch({
                    type: SavedItemsAction.SET_SHOW_MAX_ITEMS_EXCEEDED_DIALOG,
                    payload: response.status === 'warning' && response.message === 'maximum items reached'
                })
                const items = await fetchSavedItems(response.ids)
                savedItemsDispatch({type: SavedItemsAction.SET_SAVED_ITEMS, payload: {
                    items: items.items.reverse(),
                    authname: authentication.name
                }})
            }
            syncSavedItems()
        }
    }, [enableSavedItems, authentication.name, savedItemsDispatch])

    useEffect(() => {
        const refreshSavedItems = async () => {
            if (savedItemsData.isRefreshing === true && savedItemsData.savedItemIds) {
                const items = await fetchSavedItems(savedItemsData.savedItemIds)
                savedItemsDispatch({ type: SavedItemsAction.SET_SAVED_ITEMS, payload: {
                    items: items.items.reverse(),
                    authname: authentication.name
                }})
                savedItemsDispatch({ type: SavedItemsAction.SET_SAVED_ITEM_IDS, payload: {
                    savedItemIds: savedItemsData.savedItemIds,
                    authname: authentication.name,
                    skipPersist: true
                }})
                savedItemsDispatch({ type: SavedItemsAction.SET_REFRESHING, payload: false })
            }
        }

        refreshSavedItems()
    }, [authentication.name, savedItemsData.savedItemIds, savedItemsDispatch, savedItemsData.isRefreshing])

    /*
     * https://www.youtube.com/watch?v=veVWNkPwjuU
     */
    return (
        <SiteLayoutContext.Provider value={siteConfig}>
            <CollectionContext.Provider value={collData}>
                <SimpleSearchContext.Provider value={[simpleSearch, setSimpleSearch]}>
                    <ItemPagingContext.Provider value={[itemPagingState, itemPagingDispatch]}>
                        <SearchRequestContext.Provider value={[advancedSearch, dispatch]}>
                            <SearchResultsContext.Provider value={{filters: advancedSearch.filters }}>
                                <SavedItemsContext.Provider value={[savedItemsData, savedItemsDispatch]}>
                                    <HeaderContext.Provider value={[header, headerDispatch]}>
                                        <FooterContext.Provider value={[footer, footerDispatch]}>
                                            <CollectionAwareRenderer renderFn={render}
                                                                     advancedSearch={advancedSearch}
                                                                     collData={collData}
                                                                     simpleSearch={simpleSearch}
                                                                     savedItemsDispatch={savedItemsDispatch}
                                                                     header={header}
                                                                     footer={footer} />
                                        </FooterContext.Provider>
                                    </HeaderContext.Provider>
                                </SavedItemsContext.Provider>
                            </SearchResultsContext.Provider>
                        </SearchRequestContext.Provider>
                    </ItemPagingContext.Provider>
                </SimpleSearchContext.Provider>
            </CollectionContext.Provider>
        </SiteLayoutContext.Provider>
    )
}

export default SiteLayoutProvider
