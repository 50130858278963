import React from 'react'
import { useIntl } from 'react-intl'
import './GoTo.scss'
import browser from 'utils/browser'
import {Button} from "react-bootstrap";
import he from "he";

const GoTo = (props) =>  {

    const KEYS = {
        ENTER: 13,
        TAB: 9
    }
    const intl = useIntl()
    const goToPageMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_go_to_page', defaultMessage: ' '}))
    const goMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_go', defaultMessage: ' '}))
    const inputMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_input_page_number', defaultMessage: ' '}))

    let inputId = `input-${Math.random()}`
    let input

    const captureInput = (e) => {
        input = e
    }

    /**
     * If page entered is greater than the number of results, default to the last item index.
     * If page entered is less than 1, default to the first index
     * if page entered is invalid, an alert will be displayed
     * @param pageInput the number entered
     * @returns {number}
     */
    const validatePageInput = (pageInput) => {
        const val = pageInput > props.max ? props.max : (pageInput < 1 ? 1 : pageInput)
        return val
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const value = validatePageInput(input.value >> 0) // converts to int
        props.onSelect(value, e)
    }

    const handlePageInput = (e) => {
        // enter is pressed or 'next' key in android
        if (e.keyCode === KEYS.ENTER || (browser.IS_ANDROID && e.keyCode === KEYS.TAB)) {
            onSubmit(e)
        }
    }

    const handleKeyPress = (e) => {
        const key = parseInt(e.key, 10)
        if (Number.isNaN(key)) {
            e.preventDefault()
            return false
        }
    }


    const { max } = props
    const css = {
        width: `${max.toString().length * 0.6 + 1.2}rem`
    }


    return (
        <div className={'Goto-form'}>
            <h3>
                <label htmlFor={inputId}>
                    {goToPageMessage}
                </label>
            </h3>
            <input type="number" id={inputId}
                min="1"
                max={max}
                className={'pageInput'}
                onKeyDown={handlePageInput}
                onChange={handlePageInput}
                onKeyPress={handleKeyPress}
                aria-label={inputMessage}
                ref={captureInput}
                style={css} />
            <Button className="cdm-btn"
                onClick={onSubmit}
                aria-label={goMessage}
                title={goToPageMessage}>
                <span className="fa fa-chevron-right" aria-hidden></span>
            </Button>
        </div>
    )
}

export default GoTo
