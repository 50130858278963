import {useState, createContext} from 'react'
import {useLocation} from 'react-router-dom'
import {parseUrlForPage} from 'utils/UrlParameters'

/**
 * Defines a context for the paginator's page number
 *
 * @type {React.Context<Number>}
 */
const PageNumberContext = createContext()
export default PageNumberContext

/**
 * Provides access to the current page number as well as a function for updating the page number
 *
 * @returns {[number, React.Dispatch<React.SetStateAction<number>>]}
 */
export const usePageNumber = () => {
    const location = useLocation()
    const pageNumber = parseUrlForPage(location)
    return useState(pageNumber ? parseInt(pageNumber) : null)
}
