import React from 'react';
import {IntlProvider} from 'react-intl';
import {LocaleContext} from 'contexts/LocaleContext'

export const LocaleProvider = (props) => {

    const localeData = {
        isLoading: props.isLoading,
        messages: props.messages,
        changeLanguage: props.changeLanguage
    }

    const customIgnoreErrorFunction = () => {
        //ignores missing translations console errors
    }

    return (
        <IntlProvider { ...props } onError={customIgnoreErrorFunction}>
            <LocaleContext.Provider value={localeData}>
                { props.children }
            </LocaleContext.Provider>
        </IntlProvider>
    );
};
