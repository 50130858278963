import React from 'react'
import { makeHyperlinks } from './_module'
import useAnalytics from 'hooks/useAnalytics'

/**
 * helper component for render metadata items.
 * this component will prevent re-render when store dispatches changes
 *
 * @export
 * @class ItemMetadataHyperlink
 * @extends {React.PureComponent}
 */
const ItemMetadataHyperlink = (props) => {

    // shouldComponentUpdate(props) {
    //     const { collectionId, enableMetadataHyperlinking, field, searchRegex } = this.props
    //     let shouldUpdate = false
    //     shouldUpdate = collectionId !== props.collectionId || shouldUpdate
    //     shouldUpdate = enableMetadataHyperlinking !== props.enableMetadataHyperlinking || shouldUpdate
    //     shouldUpdate = String(searchRegex) !== String(props.searchRegex) || shouldUpdate
    //     shouldUpdate = Object.keys(props.field).some(key => props.field[key] !== field[key]) || shouldUpdate
    //     return shouldUpdate
    // }

    const sendAnalytics = useAnalytics()

    const { collectionId, enableMetadataHyperlinking, field, searchRegex } = props
    let value = null
    // controlled vocabulary fields need to be split into multiple lines, using the ';' as a delimiter. NOTE: we
    // can only do that if the value is a string.
    if (field.controlledVocab && (typeof field.value === "string")) {
        // we split, remove empty text, then map
        value = field.value
            .split(";")
            .filter(text => text)
            .map((value, idx) => {
                const subField = Object.assign({}, field, { value })
                const child = makeHyperlinks(collectionId, enableMetadataHyperlinking, subField, searchRegex,
                    sendAnalytics)

                return (
                    <span key={idx}>
                        {child}
                        <br />
                    </span>
                )
            })
    } else {
        value = makeHyperlinks(collectionId, enableMetadataHyperlinking, field, searchRegex, sendAnalytics)
    }
    // as react cannot handle plain text or array, we have to wrap it
    if (typeof value === 'string' || Array.isArray(value)) {
        return (<span key={field.key}>{value}</span>)
    }
    return value || <></>

}

export default ItemMetadataHyperlink
