import React, {useContext, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button} from 'react-bootstrap'
import {loadMoreFacets} from 'service/FacetService'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import he from "he";

const ShowMoreLess = (props) => {

    const intl = useIntl()

    const [showMoreButton, setShowMoreButton] = useState(true)
    const [searchRequest] = useContext(SearchRequestContext)

    let container = useRef(null)

    const {entities, actions, showMoreMessageKey, render, buttonClass, facetField} = props
    const {initialShowCount} = props
    const [entitiesState, setEntitiesState] = useState({
        [facetField]: entities,
        additionalFacetsLoaded: false
    })

    let shownEntities = entitiesState[facetField]
    const showMoreLessButtons = shownEntities.length > initialShowCount

    if (showMoreButton) {
        shownEntities = entities.slice(0, initialShowCount)
    }

    useEffect(() => {
        const loadFacets = async () => {
            if(showMoreButton === false && !entitiesState.additionalFacetsLoaded && facetField !== 'collection_filter'){
                let newFacets = await loadMoreFacets(facetField, searchRequest)
                setEntitiesState({
                    [facetField]: newFacets.facets[facetField],
                    additionalFacetsLoaded: true
                })
            }
        }
        loadFacets()
    },[showMoreButton, entitiesState.additionalFacetsLoaded, facetField, searchRequest])

    const tryToFocusOnFirstItem = () => {
        const firstChild = container.children[0]
        const input = firstChild.querySelector('input')
        if (input) {
            input.focus()
        }
    }

    const toggleButtons = async() => {
        setShowMoreButton(!showMoreButton)
        tryToFocusOnFirstItem()
    }

    const handleShowMoreClick = () => {
        toggleButtons()
    }

    const handleShowLessClick = () => {
        toggleButtons()
    }

    const renderShowMoreLessButtons = () => {

        const button = showMoreButton
            ? {
                text: he.decode(intl.formatMessage({id: showMoreMessageKey ? showMoreMessageKey : 'SITE_cdm_search_KEY_show_more', defaultMessage: ' '})),
                onClickHandler: handleShowMoreClick
            }
            : {
                text: he.decode(intl.formatMessage({id: 'SITE_cdm_search_KEY_show_less', defaultMessage: ' '})),
                onClickHandler: handleShowLessClick
            };

        return (
            <div className={buttonClass}>
                <Button
                    className="cdm-btn btn-see-more-less"
                    block
                    onClick={button.onClickHandler}>

                    {button.text}
                </Button>
            </div>
        );
    }

    return (
        <>
            <div className="ShowMoreLess-container" ref={node => container = node}>
                <div>
                    {shownEntities.map((it, i) => {
                        return render(i, it, actions)
                    })}
                </div>
                {showMoreLessButtons
                    ? renderShowMoreLessButtons()
                    : null
                }
            </div>
        </>
    )
}

export default ShowMoreLess
