import {DEFAULT_OPTS, getDefaultHeaders} from "./ServiceDefaults"

export const fetchSimpleCollections = async () => {

    const response = await fetch('/digital/api/collections/all/simple', {
        method: 'GET',
        headers: {
            ...getDefaultHeaders(),
        },
        ...DEFAULT_OPTS
    })
    return await response.json()
}

export const fetchSettings = async () => {

    const response = await fetch('/digital/api/collections/all/search/advanced/settings', {
        method: 'GET',
        headers: {
            ...getDefaultHeaders(),
        },
        ...DEFAULT_OPTS
    })
    return await response.json()
}

export const fetchSingleCollectionSettings = async (collectionId) => {

    const response = await fetch(`/digital/api/collections/${collectionId}/search/advanced/settings`, {
        method: 'GET',
        headers: {
            ...getDefaultHeaders(),
        },
        ...DEFAULT_OPTS
    })
    return await response.json()
}
