import React from 'react'

/**
 * Context that describes the current search request
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} searchRequestContext
 *  @property {object} searchRequest
 *    @property {string} collection
 *    @property {string} query
 *    @property {string} field
 *    @property {string} mode
 *    @property {string} connector
 *    @property {string} order
 *    @property {string} orderDirection
 *    @property {string} page
 *    @property {string} facetField
 *    @property {string} parentId
 *    @property {string} includeCompound
 *    @property {string} numResults
 *  @property {function} updateSearchRequest
 */
export const SearchRequestContext = React.createContext({
    searchRequest: null,
    updateSearchRequest: () => {}
})
