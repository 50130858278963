import React, {useEffect} from 'react'
import CardWrapper from 'components/CardWrapper'

const Home = ({setMainClass}) => {

    useEffect(() => setMainClass('cdm-home-page'))

    return (
        <div>
            <CardWrapper/>
        </div>
    )
}

export default Home
