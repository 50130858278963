export const buildSearchUrl = (searchRequest) => {

    let url = '/digital/search'

    const getSearchFieldValue = (field) => {
        return searchRequest[field]
            ? `/${field}/${searchRequest[field]}`
            : ''
    }

    ['collection', 'query', 'field', 'mode', 'connector', 'order', 'orderDirection', 'page', 'facetField',
        'parentId', 'includeCompound'].forEach((field) => {
            if (field === 'collection' && searchRequest[field]) {
                if (searchRequest['collection'].split('!').length > 1) {
                    url += `${getSearchFieldValue(field)}`
                } else {
                    url = url.replace('/search', '')
                    url += `${getSearchFieldValue(field)}/search`
                }
            } else if (field === 'orderDirection' && searchRequest[field]) {
                url += `/ad/${searchRequest[field]}`
            } else if ((field === 'connector' || field === 'query') && searchRequest[field]) {
                if (field === 'connector') {
                    url += `/conn/${searchRequest[field]}`
                } else {
                    url += `/searchterm/${searchRequest[field]}`
                    url = url.replace(/%2F/gi, '%25252F').replace(/%252F/gi, '%25252F')
                }
            } else {
                url += getSearchFieldValue(field)
            }
    })

    if (url === '/digital') {
       url += '/search'
    }

    if (url.includes('/digital/searchterm/')) {
        url = url.replace('/searchterm', '/search/searchterm')
    }
    return url
}
