import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import Alert from 'react-bootstrap/lib/Alert'
import FontAwesome from 'react-fontawesome'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import SearchFilters from 'components/SearchFilters'
import MobileModal from 'components/MobileModal'
import he from 'he'

const SearchRefineModalMobile = () => {

    const intl = useIntl()
    const [searchRequest] = useContext(SearchRequestContext)

    return (
        <MobileModal>
            <div>
                {searchRequest.collections === ''
                    ? <Alert bsStyle="danger">
                        <FontAwesome name="ban"/>
                        {he.decode(intl.formatMessage({
                            defaultMessage: ' ',
                            id: 'SITE_cdm_search_KEY_youmustselectonecollection'
                        }))}
                    </Alert>
                    : null
                }
                <SearchFilters/>
            </div>
        </MobileModal>
    )
}

export default SearchRefineModalMobile
