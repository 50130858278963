import React, {useContext, useRef, useCallback} from 'react'
import useAnalytics from 'hooks/useAnalytics'
import useSavedItemsCreateLink, {SavedItemsLinkAction} from 'hooks/useSavedItemsCreateLink'
import {useIntl} from 'react-intl'
import {persistSavedItems} from 'service/SavedItemsService'
import FontAwesome from 'react-fontawesome'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Button, Modal} from 'react-bootstrap'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {SavedItemsContext} from 'contexts/SavedItemsContext'
import './SavedItemsCreateLinkButton.scss'
import he from "he";

const SavedItemsCreateLinkButton = (props) => {

    const [createLinkState, dispatch] = useSavedItemsCreateLink()

    const intl = useIntl()
    const savedItems = useContext(SavedItemsContext)
    const authData = useContext(AuthenticationContext)
    const sendAnalytics = useAnalytics()
    const createdLinkRef = useRef()
    const {disabled, renderCreateLink} = props

    const numberOfSelectedSavedItems = savedItems.items.filter((item) => {
        return item.selected === true
    }).length

    const onCreateLinkCancel = useCallback(() => {
        sendAnalytics('saved items', 'click', 'create link dialog cancel')
        dispatch({ type: SavedItemsLinkAction.HIDE_CREATE_LINK_MODAL})
    }, [dispatch, sendAnalytics])

    const confirmLinkCreated = useCallback(() => {
        sendAnalytics('saved items', 'click', 'link created dialog confirm')
        dispatch({ type: SavedItemsLinkAction.HIDE_LINK_CREATED_MODAL })
    }, [dispatch, sendAnalytics])

    const copyLinkToClipboard = useCallback(() => {
        sendAnalytics('saved items', 'click', 'shared items link copied')
        dispatch({ type: SavedItemsLinkAction.COPY_LINK_TO_CLIPBOARD })
    }, [dispatch, sendAnalytics])

    const onLinkCreatedCancel = useCallback(() => {
        sendAnalytics('saved items', 'click', 'link created dialog cancel')
        dispatch({ type: SavedItemsLinkAction.HIDE_LINK_CREATED_MODAL })
    }, [dispatch, sendAnalytics])

    const onCreateLinkButtonClick = useCallback(() => {
        sendAnalytics('saved items', 'click', 'open create link dialog')
        dispatch({ type: SavedItemsLinkAction.SHOW_CREATE_LINK_MODAL })
    }, [dispatch, sendAnalytics])

    const createSelectedItemsList = useCallback(() => {
        let selectedItems = savedItems.items.filter(item => item.selected === true)
        let selectedItemsForCreateLink = []
        selectedItems.forEach(item => selectedItemsForCreateLink.push({alias: item.alias, id: item.id.toString()}))
        return selectedItemsForCreateLink
    }, [savedItems])

    const onCreateLinkOk = useCallback((customLinkText) => {
        sendAnalytics('saved items',
            'click',
            'create link dialog confirm',
            'saved items: create link',
            {
                custom_link_text: customLinkText
            })
        dispatch({ type: SavedItemsLinkAction.START_LINK_CREATION })

        persistSavedItems(createSelectedItemsList(), 'SAVE_SHARED_LIST', authData.name).then(response => {
            const loc = window.location
            const port = loc.port !== null && loc.port !== undefined && loc.port !== '' ? `:${loc.port}` : ''
            dispatch({ type: SavedItemsLinkAction.END_LINK_CREATION, payload: {
                loc,
                port,
                customLinkText,
                id: response.id
            }})
        })
    }, [authData.name, dispatch, createSelectedItemsList, sendAnalytics])

    const onRecaptchaSuccess = useCallback(() => {
        dispatch({ type: SavedItemsLinkAction.SET_RECAPTCHA_SUCCESS, payload: true })
    }, [dispatch])

    const renderLinkCreatedModal = () => (
        <Modal show={createLinkState.showLinkCreatedModal} className="SavedItemsCreateLinkButton-linkCreatedModal"
               aria-labelledby={he.decode(intl.formatMessage({ id: 'SITE_KEY_LinkCreated', defaultMessage: ' ' }))}
               onHide={onLinkCreatedCancel} key={2} autoFocus>
            <Modal.Header closeButton className="SavedItemsCreateLinkButton-linkCreatedHeader">
                <Modal.Title componentClass='h1'>{he.decode(intl.formatMessage({ id: 'SITE_KEY_LinkCreated', defaultMessage: ' ' }))}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="SavedItemsCreateLinkButton-linkCreatedDialogText">
                    {he.decode(intl.formatMessage({ id: 'SITE_KEY_SaveLinkMessage', defaultMessage: ' ' }))}
                    <br /><br />

                    {/* link returned by service */}
                    <span ref={createdLinkRef}>{createLinkState.createdLinkUrl}</span>
                    <br /><br />

                    <CopyToClipboard text={createLinkState.createdLinkUrl} onCopy={copyLinkToClipboard}>
                        <Button className='cdm-btn' bsStyle='default' >
                            {he.decode(intl.formatMessage({ id: 'SITE_KEY_CopyLink', defaultMessage: ' ' }))}
                        </Button>
                    </CopyToClipboard>
                    {createLinkState.urlCopied
                        ? <div className="SavedItemsCreateLinkButton-createLinkSuccess bg-success">
                            <span className="fa fa-check-circle" aria-hidden />
                            <span className="SavedItemsCreateLinkButton-copyHtmlSuccessMessage"
                                  data-id="createLinkUrlCopiedToClipboardAlert">
                                    {he.decode(intl.formatMessage({ id: 'SITE_KEY_generate_map_content_copied', defaultMessage: ' ' }))}
                                </span>
                        </div>
                        : undefined
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='cdm-btn' bsStyle='primary'
                        onClick={confirmLinkCreated}>
                    {he.decode(intl.formatMessage({ id: 'SITE_KEY_OK', defaultMessage: ' ' }))}
                </Button>
            </Modal.Footer>
        </Modal>
    )

    return [
        renderCreateLink(
            createLinkState,
            numberOfSelectedSavedItems,
            onCreateLinkCancel,
            onCreateLinkOk,
            onRecaptchaSuccess,
            dispatch),
        renderLinkCreatedModal(),
        <Button bsStyle="primary" className="cdm-btn" onClick={onCreateLinkButtonClick}
                data-id="createLinkButton"
                aria-label={he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateLink', defaultMessage: ' ' }))}
                disabled={disabled} key={3}>
            <FontAwesome className="SavedItemsCreateLinkButton-link SavedItemsCreateLinkButton-desktopIcon fa-lg" name="link" rotate={270} />
            <FontAwesome className="SavedItemsCreateLinkButton-link SavedItemsCreateLinkButton-mobileIcon fa-2x" name="link" rotate={90} />
            <span className="SavedItemsCreateLinkButton-buttonText">
                {he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateLink', defaultMessage: ' ' }))}
            </span>
       </Button>
   ]
}

export default SavedItemsCreateLinkButton
