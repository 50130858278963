import React, {useEffect} from 'react'
import doubleDecode from 'utils/doubleDecode'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import SearchResultsDisplaySelector from 'components/SearchResultsDisplaySelector'
import SelectableItemGridCard from 'components/SelectableItemGridCard'
import SelectableItemListCard from 'components/SelectableItemListCard'
import {GRID_VIEW} from 'constants/GridListView'
import {useCdmEvents} from 'events/useCdmEvents'
import CardViewContext, {useCardView} from 'hooks/useCardView'
import {useSharedItems} from 'hooks/useSharedItems'
import CdmClasses from 'utils/CdmClasses'
import './SharedItems.scss'
import he from "he";

const SharedItems = (props) => {

    const intl = useIntl()
    const sharedItemsUUID = useParams().id
    const [cardView, cardViewDispatch] = useCardView()
    const [sharedItems] = useSharedItems(sharedItemsUUID)

    useCdmEvents(CdmClasses.SHARED_ITEMS_PAGE, sharedItems && !sharedItems.loading)
    useEffect(() => {
        props.setMainClass('cdm-shared-items-page')
    })

    const renderResults = () => {
        if (sharedItems.shared.items != null) {
            return sharedItems.shared.items.map((item, index) => {
                return cardView === GRID_VIEW
                    ? <SelectableItemGridCard
                        key={index}
                        index={index}
                        selectEnabled={false}
                        selected={item.selected}
                        thumbnailUri={item.thumbnailUri}
                        thumbnailEnabled={item.thumbnailEnabled}
                        title={item.title}
                        metadataFields={item.metadata}
                        collectionAlias={item.alias}
                        itemId={item.id}/>
                    : <SelectableItemListCard
                        key={index}
                        index={index}
                        selectEnabled={false}
                        selected={item.selected}
                        thumbnailUri={item.thumbnailUri}
                        thumbnailEnabled={item.thumbnailEnabled}
                        title={item.title}
                        metadataFields={[item.title, ...item.metadata]}
                        collectionAlias={item.alias}
                        itemId={item.id}/>
            });
        }
    }

    const renderViewSelectorAndItemCount = (className) => {
        return <div className={className}>
            <SearchResultsDisplaySelector/>
            <span className="SharedItems-sharedItems">
                <h3 className="SharedItems-sharedItemsCount">
                    {sharedItems.shared.items.length > 0
                        ? he.decode(intl.formatMessage({
                            defaultMessage: ' ',
                            id: 'SITE_KEY_NumItemsLabel'
                        }, {num_of_items: sharedItems.shared.items.length}))
                        : <></>
                    }
                </h3>
            </span>
        </div>
    }

    const renderNoItems = () => {
        return <div className="SharedItems-noSavedItems">
            {doubleDecode(intl.formatMessage({id: 'SITE_KEY_NoSavedItems', defaultMessage: ' '}))}
        </div>
    }

    const renderBody = () => {
        return <div className="SharedItems-sharedItemContainer">
            {sharedItems.loading
                ? <></>
                : [<div key='1' className="SharedItems-sharedTitle">
                    {he.decode(intl.formatMessage({id: 'SITE_KEY_SharedItemsPageDesc', defaultMessage: ' '}))}
                </div>,
                    <div key='2' className="SharedItems-buttonContainer row">
                        {renderViewSelectorAndItemCount('SharedItems-displaySelectorAndResultsDesktopTop')}
                        {renderViewSelectorAndItemCount('SharedItems-displaySelectorAndResultsMobileTop')}
                    </div>]
            }
            {renderResults()}
        </div>
    }

    return <CardViewContext.Provider value={[cardView, cardViewDispatch]}>
        <div className="SharedItems-container shared-box">
            <h1 className="SharedItems-pageTitle" tabIndex={0}
                title={he.decode(intl.formatMessage({id: 'SITE_KEY_SharedItemsPageTitle', defaultMessage: ' '}))}>
                <FontAwesome className="SharedItems-faTitle star" name='star'/>
                {he.decode(intl.formatMessage({id: 'SITE_KEY_SharedItemsPageTitle', defaultMessage: ' '}))}
            </h1>
            <div className="grayDividerLine SharedItems-divider"/>

            {sharedItems.loading
                ? <Alert className="SharedItems-alert" bsStyle="info">
                    <FontAwesome name="info-circle"/>
                    <span
                        data-id="FetchingNotification"
                        tabIndex={0}
                        dangerouslySetInnerHTML={{
                            __html: he.decode(intl.formatMessage({
                                id: 'SITE_KEY_loadingContent',
                                defaultMessage: ' '
                            }))
                        }}
                    />
                    <FontAwesome className="SharedItems-spinner" name="refresh" aria-hidden="true"/>
                </Alert>
                : <></>
            }

            {!sharedItems.loading && (sharedItems.shared.items == null || sharedItems.shared.items.length === 0)
                ? renderNoItems()
                : renderBody()
            }
        </div>
    </CardViewContext.Provider>
}

export default SharedItems
