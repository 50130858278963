import React, {useState, useContext} from 'react'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {SimpleSearchContext} from 'contexts/SimpleSearchContext'

const ResultsPerPageContext = React.createContext()
export default ResultsPerPageContext

const DEFAULT_RESULTS = 10

export const useResultsPerPage = () => {
    const [simpleSearch] = useContext(SimpleSearchContext)
    const simpleRPP = simpleSearch && simpleSearch.resultsPerPage
    const {search} = useContext(SiteLayoutContext)
    return useState(simpleRPP ? simpleRPP : search && search.resultsPerPage ? search.resultsPerPage : DEFAULT_RESULTS)
}

