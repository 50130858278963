import React, {useContext} from 'react'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {MobileModalContext} from 'contexts/MobileModalContext'
import './MobileModal.scss'
import he from 'he'

const MobileModal = (props) => {

    const { children } = props
    const closeModal = useContext(MobileModalContext)
    const intl = useIntl()

    return (
        <div className="MobileModal-container">
            <div className="MobileModal-buttonContainer pull-right">
                <Button bsStyle="default" onClick={closeModal}>
                    <span className="fa fa-times">&nbsp;</span>
                    <b>{he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_KEY_close'}))}</b>
                </Button>
            </div>

            <div className="MobileModal-content">
                {children}
            </div>
        </div>
    );
}

export default MobileModal
