import {DEFAULT_OPTS, getDefaultHeaders} from "./ServiceDefaults"
import {getQueryStringParams} from "../utils/UrlParameters";

export const getSearchControlData = async (collectionId) => {
    const collectionIdParam = collectionId ? `/${collectionId}` : ''

    const response = await fetch(`/digital/api/searchcontrols${collectionIdParam}`, {
        method: 'GET',
        headers: {
            ...getDefaultHeaders(),
        },
        ...DEFAULT_OPTS
    })
    return await response.json()
}

export const search = async (searchRequest, resultsPerPage, pageNumber, signal) => {

    const {
        collectionPath,
        searchterm,
        field,
        mode,
        conn,
        sortOrderAndDirection,
        page,
        maxRecordsPath
    } = getQueryStringParams(searchRequest, resultsPerPage, pageNumber)

    try {
        const response = await fetch(
            `/digital/api/search${collectionPath}${searchterm}${field}${mode}${conn}${sortOrderAndDirection}${page}${maxRecordsPath}`, {
                method: 'GET',
                headers: {
                    ...getDefaultHeaders(),
                    'Content-Type': 'application/json'
                },
                signal: signal,
                ...DEFAULT_OPTS
            })
        return await response.json()
    } catch(e) {
        return { error: e }
    }
}
