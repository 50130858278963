import React from 'react'

/**
 * Site layout context that provides site configuration for the entire site
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} collData - Collection Specific Data and Messages
 */
export const CollectionContext = React.createContext(/* collData */)
