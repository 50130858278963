import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {Action} from 'hooks/useAdvancedSearch'
import './FieldSearch.scss'
import he from "he"

const FieldSearch = (props) => {

    const intl = useIntl()
    const { showBooleanFields, disabled, connector, query, fields, index, dispatch } = props

    const modes = {
        all: he.decode(intl.formatMessage({id: 'SITE_KEY_allofthewords', defaultMessage: ' '})),
        any: he.decode(intl.formatMessage({id: 'SITE_KEY_anyofthewords', defaultMessage: ' '})),
        exact: he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_exact_phrase', defaultMessage: ' '})),
        none: he.decode(intl.formatMessage({id: 'SITE_KEY_noneofthewords', defaultMessage: ' '}))
    }

    const booleanFields = {
        and: he.decode(intl.formatMessage({id: 'SITE_KEY_and', defaultMessage: ' '})),
        or: he.decode(intl.formatMessage({id: 'SITE_KEY_or', defaultMessage: ' '}))
    }

    const handleConnectorChange = useCallback((e) => {
        dispatch({ type: Action.UPDATE_QUERY, payload: {
            index,
            fieldName: 'connector',
            value: e.target.value
        }})
    }, [dispatch, index])

    const handleFieldChange = useCallback((e) => {
        dispatch({ type: Action.UPDATE_QUERY, payload: {
            index,
            fieldName: 'field',
            value: e.target.value
        }})
    }, [dispatch, index])

    const handleSearchTermChange = useCallback((e) => {
        dispatch({ type: Action.UPDATE_QUERY, payload: {
            index,
            fieldName: 'searchTerm',
            value: e.target.value
        }})
    }, [dispatch, index])

    const handleModeChange = useCallback((e) => {
        dispatch({ type: Action.UPDATE_QUERY, payload: {
            index,
            fieldName: 'mode',
            value: e.target.value
        }})
    }, [dispatch, index])

    const renderBooleanFields = () => (
        <div className="col-md-2 col-xs-4">
            <select
                aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_search_connector', defaultMessage: ' '}))}
                className="form-control"
                disabled={disabled}
                onChange={handleConnectorChange}
                value={connector}>

                <option value="and">{booleanFields.and}</option>
                <option value="or">{booleanFields.or}</option>
            </select>
        </div>
    )

    const fieldsClasses = `${showBooleanFields ? 'col-xs-8' : ''} col-md-4`
    const adjustGutter = `row form-group ${showBooleanFields ? 'FieldSearch-adjustGutter' : ''}`

    return (
        <div className="well well-sm FieldSearch-container">
            {!showBooleanFields
                ?  <h2>{he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_advanced_search_KEY_enter_search_term'}))}:</h2>
                : null
            }

            <div className={adjustGutter}>
                {showBooleanFields ? renderBooleanFields() : null}

                <div className={fieldsClasses}>
                    <select
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_field_name', defaultMessage: ' '}))}
                        className="form-control"
                        disabled={disabled}
                        onChange={handleFieldChange}
                        value={query.field}>

                        {Object.keys(fields).map((m, i) => {
                            return <option key={i} value={m}>{fields[m]}</option>;
                        })}
                    </select>
                </div>
            </div>

            <div className="row form-group FieldSearch-adjustGutter2">
                <div className="col-md-8">
                    <input
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_please_enter_another_search_term', defaultMessage: ' '}))}
                        type="text"
                        disabled={disabled}
                        onChange={handleSearchTermChange}
                        className="form-control"
                        placeholder={he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_please_enter_another_search_term', defaultMessage: ' '}))}
                        value={query.searchTerm} />
                </div>

                <div className="col-md-4">
                    <select
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_search_modifier', defaultMessage: ' '}))}
                        className="form-control"
                        disabled={disabled}
                        onChange={handleModeChange}
                        value={query.mode}>

                        {Object.keys(modes).map((m, i) => {
                            return <option key={i} value={m}>{modes[m]}</option>;
                        })}
                    </select>
                </div>
            </div>
        </div>
    )
}

export default FieldSearch
