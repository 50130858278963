import React, {useEffect} from 'react'
import SiteLayout from "../SiteLayout";
import {useLocation} from 'react-router-dom'
import {parseUrlForCollectionIds} from 'utils/UrlParameters'
import {useTracking} from 'react-tracking'
import {HIT_TYPES} from 'metrics/Constants'

/**
 * This component is used as a middle layer between our BrowserRouter, which manages location as a state,
 *      and our SiteLayout, which uses that location to load our CollectionContext
 *
 * We use this middle layer because:
 *      1) We want SiteLayout to update ONLY when the collId portion of location is changed
 *          a) a.k.a. memoizing SiteLayout on collId
 *      2) We cannot call useLocation, outside of BrowserRouter
 *          a) So we need a middle layer between ResponsiveWeb.js and SiteLayout to define the prop we memoize by
 */
const LocationPartition = (props) => {

    const location = useLocation()
    const collectionIds = parseUrlForCollectionIds(location)
    const collId = collectionIds ? collectionIds.join('!') : null

    const tracking = useTracking()
    useEffect(() => {
        tracking.trackEvent({ hitType: HIT_TYPES.PAGEVIEW, page: location.pathname })
    }, [tracking, location])

    return (
        <SiteLayout siteConfig={props.siteConfig} collection={collId} />
    )
}

export default LocationPartition
