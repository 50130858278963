import React, {useCallback, useContext} from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import {CurrentItemContext} from 'contexts/CurrentItemContext'
import {turnOnLoadingNextPrevItem} from 'hooks/useItem'

import useAnalytics from 'hooks/useAnalytics'
import './CompoundItemView.scss'

export const CompoundItemView = (props) => {

    const history = useHistory()
    const sendAnalytics = useAnalytics()
    const [,itemDispatch] = useContext(CurrentItemContext)

    const { collectionAlias, currentItemId, item, searchResult, parentId } = props
    let recNumber = history.location.pathname.split('/rec/')[1]

    const handleOnClick = useCallback(() => {
        if (currentItemId !== item.id) {
            sendAnalytics(
                'compound object',
                `navigation: ${window.location.origin}/digital/collection/${collectionAlias}/id/${parentId}`,
                `${window.location.origin}/digital/collection/${collectionAlias}/id/${item.id}`,
                'select compound item page',
                {
                    collection_alias: collectionAlias,
                    parent_id: `${collectionAlias}/id/${parentId}`,
                    item_id: `${collectionAlias}/id/${item.id}`,
                    source: 'clicked on page'
                }
            );
            itemDispatch(turnOnLoadingNextPrevItem());
            history.push({
                pathname: `/digital/collection/${collectionAlias}/id/${item.id}${recNumber ? `/rec/${recNumber}` : ''}`,
                state: { ...history.location.state, shouldScroll: false }
            });
        }
    }, [item.id, parentId, recNumber, collectionAlias, currentItemId, history, sendAnalytics, itemDispatch])

    const handleOnEnterKey = (e) => {
        if(e.key === 'Enter') {
            handleOnClick()
        }
    }

    return (
        <div className={['CompoundItemView-overlay']}>
            <div role="link"
                className={`row CompoundItemView-container ${currentItemId === item.id
                    ? 'CompoundItemView-selectedCompoundItem' : ''} ${searchResult
                    ? 'CompoundItemView-searchResult' : ''}`}
                onClick={handleOnClick}
                onKeyDown={handleOnEnterKey}
                tabIndex={0}>

                <div className={'CompoundItemView-thumbnailHolder'}>
                    <img src={'/digital' + item.thumbnailUri} className={'CompoundItemView-thumbnail'} alt={item.title} />
                </div>

                <h2 className={'CompoundItemView-header'}>
                    {item.title}
                </h2>
            </div>
        </div>
    )
}

CompoundItemView.propTypes = {
    collectionAlias: PropTypes.string,
    currentItemId: PropTypes.number,
    item: PropTypes.object,
    searchResult: PropTypes.bool
}

export default CompoundItemView
