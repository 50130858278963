/**
 * Hook that encompasses/enforces the standard CDM :enter and :ready event parameters while forcing itemId to a string.
 * This is not technically a standard react hook in that it returns a plain javascript object with predefined fields.
 *
 * The object produced by this hook managed by the {useCdmEvents} hook that is imported from events/CdmEvents
 *
 * @param {string} collectionId Collection identifier
 * @param {string|Number} itemId Item identifier
 * @param {string} filename the name of the html file without the extension of the custom page
 * @returns {{itemId, collectionId, filename}}
 */
export const useCdmEnterAndReadyEventParams = (collectionId = null, itemId = null, filename = null) => {
    const normalizedItemId = itemId ? itemId.toString() : null
    return {
        collectionId,
        itemId: normalizedItemId,
        filename
    }
}
