import React, {Fragment, useContext, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {CurrentItemContext} from 'contexts/CurrentItemContext'
import {Button} from 'react-bootstrap'
import {useIntl} from "react-intl"
import ItemImage from './ItemImage'
import ItemAudio from './ItemAudio'
import ItemVideo from './ItemVideo'
import ItemUrl from './ItemUrl'
import ItemNoFile from './ItemNoFile'
import ItemRestricted from './ItemRestricted'
import ItemFile from './ItemFile'
import ItemPDF from './ItemPDF'
import './ItemPreview.scss'
import {useTracking} from "react-tracking"
import {collectEventAnalytics} from "metrics/Analytics"
import BrowserUtils from 'utils/browser'
import ItemModalViewer from "./ItemModalViewer"
import CONTENT_TYPES from 'utils/contentTypes'
import he from "he";

const ItemPreview = () => {

    /**
     * Regular expressions for matching content types.
     */
    const APPLICATION_URL = 'application/url'
    const APPLICATION_META = 'application/meta'
    const APPLICATION_PDF = 'application/pdf'
    const IMAGE_REGEX = /^image\/(gif|jpeg|jp2|tiff|png)$/
    const AUDIO_REGEX = /^audio\//
    const VIDEO_REGEX = /^video\//
    const CPD_REGEX = /\.cpd$\b/i
    const RESTRICTED = 'restricted'

    const tracking = useTracking()
    const intl = useIntl()
    const [itemState] = useContext(CurrentItemContext)
    const {item} = itemState
    const history = useHistory()
    const [showLightbox, setShowLightbox] = useState(false)

    const prevMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_previous', defaultMessage: ' '}))
    const nextMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_next', defaultMessage: ' '}))

    const compoundObject = !!item.parent,
        currentItemId = item.id,
        collectionAlias = item.collectionAlias,
        contentType = item.contentType,
        title = compoundObject
            ? item.parent.fields.find(field => field.key === 'title').value
            : item.fields.find(field => field.key === 'title')
                ? item.fields.find(field => field.key === 'title').value
                : item.text,
        iiifInfoUri = item.iiifInfoUri,
        streamUri = item.streamUri,
        filename = item.filename,
        thumbnailUri = item.thumbnailUri,
        url = item.url,
        downloadUri = item.downloadUri,
        parentId = item.parentId

    const eventCategory = compoundObject ? 'compound object' : 'single item'

    const isChildOrHasFile = (parentId, filename) => {
        return parentId !== -1 || CPD_REGEX.test(filename)
    }

    let children, isLastItem, isFirstItem, prevItemId, nextItemId
    if (compoundObject) {
        children = item.parent.children.map(field => field.id)
        isLastItem = currentItemId === children[children.length - 1]
        isFirstItem = currentItemId === children[0]
        prevItemId = children[children.indexOf(currentItemId) - 1]
        nextItemId = children[children.indexOf(currentItemId) + 1]
    }
    const acceptPrevious = (e) => {
        return isFirstItem ? e.preventDefault() : true
    }
    const acceptNext = (e) => {
        return isLastItem ? e.preventDefault() : true
    }


    const renderItemPdf = (title) => {

        if (isChildOrHasFile(parentId, filename)) {
            if (filename.endsWith('.pdfpage')) {
                let index = item.parent.children.findIndex(child => child.id === currentItemId)

                // /page/0 is the full PDF which we don't want per CDMRUI-1866
                // since we didn't find the correct index this means we are a parent and need to set it to page/1
                if (index === -1) {
                    index = 0
                }
                // conversion from index to page
                const page = index + 1

                const title = `${collectionAlias}_${parentId}_${page}`

                return <ItemPDF title={title} setShowLightbox={setShowLightbox} collectionAlias={collectionAlias}/>
            } else {
                return <ItemPDF title={title} setShowLightbox={setShowLightbox} collectionAlias={collectionAlias}/>
            }
        } else {
            return <ItemPDF title={title} setShowLightbox={setShowLightbox} collectionAlias={collectionAlias}/>
        }
    }

    const actualItemPreview = () => {

        if (contentType === APPLICATION_URL) {
            return <ItemUrl thumbnailUri={thumbnailUri} url={url} eventCategory={eventCategory} collectionAlias={collectionAlias} itemId={currentItemId} />

        } else if (contentType === APPLICATION_PDF) {
            return (
                <Fragment>
                    <ItemModalViewer itemType={CONTENT_TYPES.APPLICATION_PDF} showLightbox={showLightbox}
                                     onClose={() => {
                                         setShowLightbox(false)
                                     }}/>
                    {renderItemPdf(title)}
                </Fragment>
            )

        } else if (IMAGE_REGEX.test(contentType)) {
            return (
                <Fragment>
                    <ItemModalViewer itemType={CONTENT_TYPES.APPLICATION_IMAGE} showLightbox={showLightbox}
                                     onClose={() => {
                                         setShowLightbox(false)
                                     }}/>
                    <ItemImage title={title} iiifInfoUri={iiifInfoUri} setShowLightbox={setShowLightbox}
                    />
                </Fragment>
            )

        } else if (AUDIO_REGEX.test(contentType) && streamUri && BrowserUtils.canPlayAudio(contentType)) {
            return <ItemAudio streamUri={streamUri}
                              itemInfo={{collectionAlias: collectionAlias, itemId: currentItemId}}/>

        } else if (VIDEO_REGEX.test(contentType) && streamUri && BrowserUtils.canPlayVideo(contentType)) {
            return <ItemVideo streamUri={streamUri}
                              itemInfo={{collectionAlias: collectionAlias, itemId: currentItemId}}/>

        } else if (contentType === APPLICATION_META || contentType === null) {
            return <ItemNoFile/>

        } else if (contentType === RESTRICTED) {
            return <ItemRestricted/>

        } else if (CPD_REGEX.test(filename)) {
            return null

        } else {
            return <ItemFile thumbnailUri={thumbnailUri} downloadUri={downloadUri} filename={filename}
                             itemInfo={{collectionAlias: collectionAlias, itemId: currentItemId}}/>
        }
    }

    const compoundPreview = () => {
        const recNumber = history.location.pathname.split('/rec/')[1]
        const prevPathLink = `/digital/collection/${collectionAlias}/id/${prevItemId}${recNumber
            ? `/rec/${recNumber}` : ''}`
        const nextPathLink = `/digital/collection/${collectionAlias}/id/${nextItemId}${recNumber
            ? `/rec/${recNumber}` : ''}`
        return (
            <div className={'ItemPreview-container'}>
                <Button disabled={isFirstItem} className={'cdm-btn'} title={prevMessage}
                    onClick={(e) => {
                        acceptPrevious(e);
                        collectEventAnalytics(
                            tracking,
                            'compound object',
                            'click',
                            `previous item: ${prevPathLink}`,
                            'navigation buttons',
                            {
                                collection_alias: collectionAlias,
                                parent_id: `${collectionAlias}/id/${parentId}`,
                                parent_title: title,
                                item_id: `${collectionAlias}/id/${prevItemId}`,
                                type: 'previous'
                            }
                        );
                        history.push({
                            pathname: prevPathLink,
                            state: { ...history.location.state, shouldScroll: false }
                        });
                    }}>
                    <span className="fa fa-chevron-left" aria-hidden />
                    <span className="text">{prevMessage}</span>
                </Button>

                <div className={'preview'}>
                    {actualItemPreview()}
                </div>

                <Button disabled={isLastItem} className={'cdm-btn'} title={nextMessage}
                    onClick={(e) => {
                        acceptNext(e);
                        collectEventAnalytics(
                            tracking,
                            'compound object',
                            'click',
                            `next item: ${nextPathLink}`,
                            'navigation buttons',
                            {
                                collection_alias: collectionAlias,
                                parent_id: `${collectionAlias}/id/${parentId}`,
                                parent_title: title,
                                item_id: `${collectionAlias}/id/${nextItemId}`,
                                type: 'next'
                            }
                        );
                        history.push({
                            pathname: nextPathLink,
                            state: { ...history.location.state, shouldScroll: false }
                        });
                    }}>
                    <span className='text'>{nextMessage}</span>
                    <span className="fa fa-chevron-right" aria-hidden/>
                </Button>
            </div>
        )
    }

    const simplePreview = () => {
        return (
            <div className={'ItemPreview-container'}>
                <div className={'preview'}>
                    {actualItemPreview()}
                </div>
            </div>
        )
    }

    return (
        compoundObject ? compoundPreview() : simplePreview()
    )
}

export default ItemPreview
