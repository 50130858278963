import {useEffect, useRef, useState} from 'react'

/**
 * This hook accepts an updateParams object and monitors it's properties for changes between renders
 *
 * @param {object} updateParams An object representing anything that could be updated
 *  @property {string} collectionAlias|collectionId|alias The collection identifier
 *  @property {string|Number} id|itemId The item identifier
 * @param {string} collectionIdField The name of the collectionId field that the component may use (e.g. alias,
 * colectionAlias, collectionId)
 * @param {string} itemIdField The name of the itemId field that the component may use (e.g. id or itemId)
 * @returns {{itemId, collectionId}}
 */
export const useCdmUpdateEventParams = (updateParams = null, collectionIdField = null, itemIdField = null) => {

    const previousUpdateParams = useRef(updateParams)

    const collectionId = updateParams ? updateParams[collectionIdField] : null
    const itemId = updateParams ? updateParams[itemIdField] : null

    let normalizedItemId = null
    if (itemId) {
        normalizedItemId = itemId.toString()
    }

    const [cdmUpdateEventPayload, setCdmUpdateEventPayload] = useState({
        collectionId,
        itemId: normalizedItemId ? normalizedItemId : undefined,
        filename: updateParams ? updateParams.filename : undefined
    })

    useEffect(() => {

        const changes = updateParams && Object.keys(updateParams).find(updateParamField => {
            return updateParams[updateParamField] !== previousUpdateParams.current[updateParamField]
        })

        if (changes) {
            setCdmUpdateEventPayload({
                ...updateParams,
                collectionId,
                itemId: normalizedItemId ? normalizedItemId : undefined
            })
        }

        previousUpdateParams.current = updateParams
    }, [updateParams, collectionId, collectionIdField, normalizedItemId])

    return cdmUpdateEventPayload
}
