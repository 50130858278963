
/**
 * Provides the current viewpoint
 *
 * @returns {{isDesktop: boolean, isMobile: boolean}}
 */
export const useViewport = () => {
    const isMobile = window.matchMedia && window.matchMedia("only screen and (max-width: 767px)").matches
    // "Tablet" sizes are not currently supported in RUI.  This is a placeholder if we ever do support it
    // const isTablet = window.matchMedia("(min-width: 768px) and (max-width: 992px)").matches
    const isDesktop = !isMobile

    return {
        isMobile,
        isDesktop
    }
}
