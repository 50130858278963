import 'core-js/es/promise'
import 'core-js/es/object/entries'
import 'core-js/es/object/values'
import 'core-js/es/object/assign'
import 'core-js/es/map'
import 'core-js/es/set'
import 'core-js/es/array'
import 'core-js/es/string'
import 'core-js/es/math'
import 'core-js/es/number'
import 'core-js/es/object/from-entries'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import CONTENTdmResponsiveWeb from './CONTENTdmResponsiveWeb'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<CONTENTdmResponsiveWeb />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
