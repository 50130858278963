import React, {useCallback, useContext, useEffect, useState} from 'react'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import cdmClasses from 'utils/CdmClasses'
import {useCdmEvents} from 'events/useCdmEvents'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {Alert, Button} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import Helmet from 'react-helmet'
import DateSearch from 'components/DateSearch'
import FieldSearch from 'components/FieldSearch'
import FetchingNotification from 'components/FetchingNotification'
import SearchCollectionFilter from 'components/SearchCollectionFilter'
import {buildSearchUrl} from 'components/Search/SearchUrl'
import {Action, DEFAULT_QUERY} from 'hooks/useAdvancedSearch'
import {validateDateQuery} from 'utils/AdvancedSearchValidator'
import './AdvancedSearch.scss'
import {fetchSettings, fetchSingleCollectionSettings} from "../../service/AdvancedSearchService";
import he from "he"
import { MobileModalContext } from "contexts/MobileModalContext";
import { useViewport } from 'hooks/useViewport';
import {fetchSimpleCollections} from 'service/AdvancedSearchService'

export const DEFAULT_SEARCH_REQUEST = {
    collection: '',
    query: '',
    field: '',
    mode: '',
    connector: ''
};

/**
 * Advanced Search component that renders the various filters and search terms required for search refinement
 */
const AdvancedSearch = (props) => {

    useCdmEvents(cdmClasses.ADVANCED_SEARCH_PAGE)
    useEffect(() => { props.setMainClass('cdm-advanced-search-page') })

    const intl = useIntl()
    const history = useHistory()
    const [advancedSearch, dispatch] = useContext(SearchRequestContext)
    const { isMobile } = useViewport();
    const [simpleSearchResults, setSimpleSearchResults] = useState([]);
    const [collectionsLoaded, setCollectionsLoaded] = useState(false);

    useEffect(() => {
        if (!collectionsLoaded) {
            const loadSimpleCollections = async () => {
                const simpleCollections = await fetchSimpleCollections()
                const collections = simpleCollections.map(collection => ({
                    ...collection,
                    selected: true
                }))
                setSimpleSearchResults(collections);
                setCollectionsLoaded(true);
            }
            loadSimpleCollections();
        }
    }, [collectionsLoaded])

    const convertedFields = advancedSearch.searchTermFields.fields.reduce((accumulator, field) => {
        accumulator[field.nick] = field.name
        return accumulator
    }, {})

    const fields = {
        all: he.decode(intl.formatMessage({id: 'SITE_KEY_allfields', defaultMessage: ' '})),
        ...convertedFields
    }

    const validateDates = useCallback(() => {
        let errors
        if (advancedSearch.searchTermFields.dateSearchEnabled) {
            errors = validateDateQuery(advancedSearch.dateQuery, {
                tooEarly: he.decode(intl.formatMessage({ id: 'SITE_error_KEY_theseconddatecannotbeearlierthanthefirstdate', defaultMessage: ' ' })),
                wrongFormat: he.decode(intl.formatMessage({ id: 'SITE_cdm_advanced_search_KEY_wrong_format_validation_error', defaultMessage: ' ' })),
                enterSecondDate: he.decode(intl.formatMessage({ id: 'SITE_cdm_advanced_search_KEY_please_enter_a_second_date', defaultMessage: ' ' })),
                enterFirstDate: he.decode(intl.formatMessage({ id: 'SITE_cdm_advanced_search_KEY_please_enter_a_first_date', defaultMessage: ' ' }))
            })
        }

        // any errors should result in an alert and the error messages showing up
        if (errors) {
            const errorValues = Object.values(errors)
            // get unique values from an array: http://stackoverflow.com/a/23282057
            const uniqueErrorValues = errorValues.map(m => m.join()).filter(function(item, i, ar) {
                return ar.indexOf(item) === i
            })
            const messages = uniqueErrorValues.join('\n')

            alert(messages)
            return errors
        }
    }, [advancedSearch, intl]);

    const handleDoSearch = useCallback(() => {
        const errors = validateDates();

        if (!errors) {
            let searchRequest;
            if (advancedSearch.queries && advancedSearch.queries.length > 0) {
                const nonEmptySearchQueries = advancedSearch.queries.filter(
                    (query) => query.searchTerm.length > 0
                );
                const searchTerm = nonEmptySearchQueries
                    .map((query) => query.searchTerm)
                    .filter(Boolean)
                    .join('!');
                const escapedSearchTerm = encodeURIComponent(searchTerm.replace('%', '%25'));
                searchRequest = {
                    ...DEFAULT_SEARCH_REQUEST,
                    collection: advancedSearch.collection,
                    query: escapedSearchTerm,
                    mode: escapedSearchTerm
                        ? nonEmptySearchQueries
                              .map((query) => query.mode)
                              .filter(Boolean)
                              .join('!')
                        : '',
                    connector: escapedSearchTerm
                        ? nonEmptySearchQueries
                            .map((query) => query.connector)
                            .filter(Boolean)
                        : '',
                    field: escapedSearchTerm
                        ? nonEmptySearchQueries
                              .map((query) => query.field)
                              .filter(Boolean)
                              .join('!')
                        : ''
                };
                if (escapedSearchTerm) {
                    searchRequest.connector.push(searchRequest.connector.shift());
                    searchRequest.connector = searchRequest.connector.join('!');
                }
            }

            /*
            Date Search Examples
            "on" -      searchterm/20101010/field/date/mode/exact/conn/and
            "after" -   searchterm/20101011-99999999/field/date/mode/exact/conn/and
            "before" -  searchterm/00000000-20101009/field/date/mode/exact/conn/and
            "between" - searchterm/20101010-20111010/field/date/mode/exact/conn/and
            */

            const { mode, searchTerm, searchTerm2 } = advancedSearch.dateQuery;
            if (searchTerm || searchTerm2) {
                const date1 = searchTerm.split('-').join('');
                const date2 = searchTerm2.split('-').join('');
                const dateNick =
                    advancedSearch.searchTermFields && advancedSearch.searchTermFields.dateField
                        ? advancedSearch.searchTermFields.dateField
                        : 'date';

                let query = '';

                switch (mode) {
                    case 'after':
                        query = `${parseInt(date1, 10) + 1}-99999999`;
                        break;
                    case 'before':
                        query = `00000000-${parseInt(date1, 10) - 1}`;
                        break;
                    case 'between':
                        query = `${date1}-${date2}`;
                        break;
                    case 'on':
                    default:
                        query = date1;
                        break;
                }

                searchRequest = {
                    ...searchRequest,
                    query: searchRequest.query ? `${searchRequest.query}!${query}` : query,
                    field:
                        searchRequest.field && searchRequest.query
                            ? `${searchRequest.field}!${dateNick}`
                            : `${dateNick}`,
                    mode:
                        searchRequest.mode && searchRequest.query
                            ? `${searchRequest.mode}!exact`
                            : 'exact',
                    connector:
                        searchRequest.connector && searchRequest.query
                            ? `${searchRequest.connector}!and`
                            : 'and'
                };
            }
            searchTerm.includes('%')
                ? (window.location.href = buildSearchUrl(searchRequest))
                : history.push(buildSearchUrl(searchRequest));
        }
    }, [
        validateDates,
        history,
        advancedSearch.collection,
        advancedSearch.dateQuery,
        advancedSearch.queries,
        advancedSearch.searchTermFields
    ]);

    const handleAddRow = useCallback(() => {
       dispatch({ type: Action.ADD_QUERY, payload: {...DEFAULT_QUERY} })
    }, [dispatch])

    const handleClearSearch = useCallback(() => {
        dispatch({ type: Action.SET_QUERIES, payload: [{...DEFAULT_QUERY}]})
        dispatch({ type: Action.RESET_DATE_QUERY })
    }, [dispatch])

    const afterCollectionFilterSave = useCallback(async (searchRequest) => {

        const collection =
            searchRequest.collection === ''
            ? 'all'
            : searchRequest.collection.split('!').length > 1
                ? 'all'
                : searchRequest.collection

        const loadSettings = async () => {
            const settings = await fetchSettings()
            dispatch({ type: Action.SET_SETTINGS, payload: settings })
        }

        const loadSingleCollectionSettings = async () => {
            const settings = await fetchSingleCollectionSettings(collection)
            dispatch({ type: Action.SET_SINGLE_COLLECTION_SETTINGS, payload: settings })
        }

        if (collection === 'all') {
            await loadSettings()
        } else {
            await loadSingleCollectionSettings()
        }
    }, [dispatch])

    const renderCollectionNotification = () => {
        let notification = <></>
        if (collectionsLoaded && advancedSearch?.numberOfCollectionsSelected === 0) {
            notification = <Alert bsStyle="danger">
                <FontAwesome name="ban"/>
                {he.decode(intl.formatMessage({id: 'SITE_cdm_search_KEY_youmustselectonecollection', defaultMessage: ' '}))}
            </Alert>
        }
        return notification
    }

    const renderSearchCollectionFilter = () => {
        return (
            <SearchCollectionFilter
                getSelectedCollections={getSelectedCollections}
                updateButtonText={he.decode(
                    intl.formatMessage({
                        id: 'SITE_cdm_search_KEY_save',
                        defaultMessage: ' '
                    })
                )}
                afterSave={afterCollectionFilterSave}
            />
        );
    };

    const getSelectedCollections = useCallback(() => {
        if(advancedSearch?.filters?.collections?.length > 0){
            return advancedSearch.filters.collections;
        } else {
            return simpleSearchResults;
        }
    },[simpleSearchResults, advancedSearch])

    return (
        advancedSearch && advancedSearch.isLoading
            ? <FetchingNotification/>
            : <>
            {renderCollectionNotification()}
            <div className="AdvancedSearch-container shared-box" id="advancedSearchDiv">
            <Helmet title={he.decode(intl.formatMessage({id: 'SITE_KEY_advancedsearch', defaultMessage: ' '}))} />

            <div className="AdvancedSearch-sectionWrapper">

                <div className="col-md-10 AdvancedSearch-content">
                    <h1>{he.decode(intl.formatMessage({id: 'SITE_KEY_advancedsearch', defaultMessage: ' '}))}</h1>
                    <hr />
                    {collectionsLoaded && (
                        <>
                            <h1 tabIndex={0}>{he.decode(intl.formatMessage({id: 'SITE_KEY_collections', defaultMessage: ' '}))}</h1>
                                {isMobile ? (
                                    <MobileModalContext.Provider value={() => {}}>
                                        {renderSearchCollectionFilter()}
                                    </MobileModalContext.Provider>
                                ) : (
                                    renderSearchCollectionFilter()
                                )}
                        </>
                    )}
                </div>
            </div>

            <div className="AdvancedSearch-sectionWrapper">
                <div className="col-md-10 AdvancedSearch-content">
                    {advancedSearch.queries.map((m, i) => {
                        return <FieldSearch
                            key={i}
                            index={i}
                            showBooleanFields={i > 0}
                            query={m}
                            connector={advancedSearch.queries[i].connector}
                            fields={fields}
                            dispatch={dispatch}
                            disabled={simpleSearchResults?.length === 0 || advancedSearch.hasFilterChanges ? 'disabled' : ''}
                        />
                    })}

                    <div className="AdvancedSearch-addMoreButtonContainer row">
                        <div className="col-xs-12 col-sm-2 col-sm-offset-10">
                            <Button
                                aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_add_row', defaultMessage: ' '}))}
                                title={he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_add_row', defaultMessage: ' '}))}
                                bsStyle="default"
                                className="cdm-btn"
                                data-id="addMoreBtn"
                                block
                                disabled={simpleSearchResults?.length === 0 || advancedSearch.hasFilterChanges || advancedSearch.queries.length > 3}
                                onClick={handleAddRow}>

                                <span className="fa fa-plus-circle">&nbsp;</span>
                                {he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_add_row', defaultMessage: ' '}))}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="AdvancedSearch-sectionWrapper">
                <div className="col-md-10 AdvancedSearch-content">
                    {advancedSearch.searchTermFields.dateSearchEnabled
                        ? <DateSearch query={advancedSearch.dateQuery} dispatch={dispatch} disabled={simpleSearchResults?.length === 0 || advancedSearch.hasFilterChanges ? 'disabled' : ''} />
                        : null
                    }

                    <div className="AdvancedSearch-buttonsBox row">
                        <div className="col-xs-6 col-sm-2 col-sm-offset-8">
                            <Button
                                aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_clear', defaultMessage: ' '}))}
                                title={he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_clear', defaultMessage: ' '}))}
                                bsStyle="link"
                                block
                                className="cdm-btn"
                                onClick={handleClearSearch}>

                                {he.decode(intl.formatMessage({id: 'SITE_cdm_advance_search_KEY_clear', defaultMessage: ' '}))}
                            </Button>
                        </div>
                        <div className="col-xs-6 col-sm-2">
                            <Button
                                aria-label={he.decode(intl.formatMessage({id: 'SITE_KEY_search', defaultMessage: ' '}))}
                                title={he.decode(intl.formatMessage({id: 'SITE_KEY_search', defaultMessage: ' '}))}
                                bsStyle="primary"
                                block
                                className="cdm-btn"
                                // disabled= {hasChanges || noCollectionsSelected}
                                disabled={simpleSearchResults?.length === 0 || advancedSearch.hasFilterChanges}
                                onClick={handleDoSearch}
                                data-id="searchBtn">

                                {he.decode(intl.formatMessage({id: 'SITE_KEY_search', defaultMessage: ' '}))}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AdvancedSearch
