import {DEFAULT_OPTS, getDefaultHeaders} from "./ServiceDefaults"

export const loadMoreFacets = async (facetField, searchRequest) => {

    const searchterm = searchRequest.query ? `/searchterm/${(searchRequest.query)}` : ''
    const field = searchRequest.field ? `/field/${searchRequest.field}` : ''
    const mode = searchRequest.mode ? `/mode/${searchRequest.mode}` : ''
    const conn = searchRequest.connector ? `/conn/${searchRequest.connector}` : ''
    const sortOrderAndDirection = searchRequest.order ? `/order/${searchRequest.order}/ad/${searchRequest.orderDirection}` : ''
    const collection = searchRequest.collection ? `/collection/${searchRequest.collection}` : ''

    try {
        const response = await fetch(
            `/digital/api/facet/facetfield/${facetField}${collection}${searchterm}${field}${mode}${conn}${sortOrderAndDirection}`, {
                method: 'GET',
                headers: {
                    ...getDefaultHeaders(),
                    'Content-Type': 'application/json'
                },
                ...DEFAULT_OPTS
            })
        return await response.json()
    } catch (e) {
        return {error: e}
    }
}
