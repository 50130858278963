import React, {useContext} from 'react'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import SearchTerm from 'components/SearchTerm/SearchTerm'
import {useIntl} from 'react-intl'
import './SearchTerms.scss'
import he from "he";

const SearchTerms = () => {

    const intl = useIntl()
    const searchTerms = he.decode(intl.formatMessage({id: 'SITE_cdm_search_KEY_search_terms', defaultMessage: ' '}))
    const [searchRequest] = useContext(SearchRequestContext)

    // Treat the searchterm as a single term if there are no specified search fields or a single search field is specified
    // e.g. "Search!It" would result in a single search term if no fields (or one field) is supplied, otherwise
    // it will be split into two search terms ("Search" and "It")
    const singleField = searchRequest.field === '' || (searchRequest.field && searchRequest.field.split('!').length === 1)

    const renderSearchTerms = () => {
        if (singleField) {
            return searchRequest.query
                ? <SearchTerm query={searchRequest.query} position={0} key={0} />
                : null
        } else {
            return searchRequest.query
                ? searchRequest.query.split('!').map((query, i) => (
                    <SearchTerm query={query} position={i} key={i} />
                  ))
                : null
        }
    }

    return (
        <div>
            {searchRequest.query
                ? <div className="SearchTerms-container shared-search-box shared-box">
                    <div>
                        <h3 className="SearchTerms-searchTerms">{searchTerms}</h3>
                    </div>
                    <div>
                        {renderSearchTerms()}
                    </div>
                </div>
                : undefined
            }
        </div>
    )
}

export default SearchTerms
