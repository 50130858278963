import React, {useCallback, useEffect} from 'react'
import {Button} from 'react-bootstrap'
import './PreviewBanner.scss'

/**
 * Renders the 'preview mode' banner at the top of the application when running in preview mode.
 */
const PreviewBanner = () => {

    useEffect(() => {
        // add preview-mode class to body
        const classList = document.body.className.split(' ')
        classList.push('preview-mode')
        document.body.className = classList.join(' ')
    }, [])

    const onButtonClick = useCallback(() => {
        window.location.search = "?_preview=false"
    },[])

    return <div className={'PreviewBanner-previewBarContainer'}>
        <Button className='btn-lg pull-right'
            onClick={onButtonClick}>Exit Preview Mode</Button>
    </div>
}

export default PreviewBanner
