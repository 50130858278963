import React, {useContext, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {buildSearchUrl} from 'components/Search/SearchUrl'
import useAnalytics from 'hooks/useAnalytics'
import './SearchTerm.scss'

const SearchTerm = ({query, position}) => {

    const history = useHistory()
    const sendAnalytics = useAnalytics()
    let [searchRequest] = useContext(SearchRequestContext)

    const handleClick = useCallback(() => {
        const newSearchRequest = {
            ...searchRequest,
            query: searchRequest.query
                .split('!')
                .filter((term, i) => i !== position)
                .join('!'),
            field: searchRequest.field
                .split('!')
                .filter((field, i) => i !== position)
                .join('!'),
            mode: searchRequest.mode
                .split('!')
                .filter((mode, i) => i !== position)
                .join('!'),
            connector: searchRequest.connector
                .split('!')
                .filter((conn, i) => i !== position)
                .join('!')
        };

        sendAnalytics('search results', 'click', `remove filter: ${query}`, 'remove search filter', {query: `${query}`})

        history.push(buildSearchUrl(newSearchRequest))
    }, [sendAnalytics, history, position, query, searchRequest])

    const decodedQuery = query && query.trim() !== ''
        ? decodeURIComponent(query).replace(/%2F/g, '/') : ''

    return (
        <Button
            bsStyle="default"
            className="cdm-btn SearchTerm-searchTermBtn btn btn-default"
            title={decodedQuery}
            onClick={handleClick}
            >

            <div className="SearchTerm-term">{decodedQuery}</div>
            <FontAwesome className="SearchTerm-searchTermClosePadding" name="times"/>
        </Button>
    )
}

export default SearchTerm
