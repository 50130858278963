import React, {useCallback, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {MobileModalContext} from 'contexts/MobileModalContext'
import SearchInfo from 'components/SearchInfo'
import GenerateMapButton from 'components/GenerateMapButton'
import SearchRefineModalMobile from 'components/SearchRefineModalMobile'
import SearchSortModalMobile from 'components/SearchSortModalMobile'
import './SearchHeaderMobile.scss'
import he from 'he'

const SearchHeaderMobile = (props) => {

    const { params } = props
    const intl = useIntl()
    const [showMobileRefineModal, setShowMobileRefineModal] = useState(false)
    const [showMobileSortModal, setShowMobileSortModal] = useState(false)

    const showRefineModal = useCallback(() => {
        setShowMobileRefineModal(true)
    }, [])

    const hideRefineModal = useCallback(() => {
        setShowMobileRefineModal(false)
    }, [])

    const showSortModal = useCallback(() => {
        setShowMobileSortModal(true)
    }, [])

    const hideSortModal = useCallback(() => {
        setShowMobileSortModal(false)
    }, [])

    return (
        <div>
            {showMobileRefineModal
                ? <MobileModalContext.Provider value={hideRefineModal}>
                    <SearchRefineModalMobile />
                  </MobileModalContext.Provider>
                : null
            }
            {showMobileSortModal
                ? <MobileModalContext.Provider value={hideSortModal}>
                    <SearchSortModalMobile />
                  </MobileModalContext.Provider>
                : null
            }

            <div data-id="MobileSearchHeader" className="visible-xs">
                <SearchInfo params={params} />
                <div className="MobileSearchHeader-buttonGroup">
                    <Button bsStyle="default"
                            data-id="filterBtn"
                            onClick={showRefineModal}>
                        <span className="fa fa-filter">&nbsp;</span>
                        {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_refine'}))}
                    </Button>
                    <Button bsStyle="default" onClick={showSortModal}>
                        <span className="fa fa-sort">&nbsp;</span>
                        {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_sort'}))}
                    </Button>
                    <GenerateMapButton />
                </div>
            </div>
        </div>
    )
}

export default SearchHeaderMobile
