import {useEffect, useRef, useState} from 'react'
import {EVENT_TYPES} from 'events/EventTypes'

const PARAMETERS = ['collectionId', 'itemId', 'filename']

const eventFactory = (eventName, data) => {
    const cancelable = true
    const bubbles = true
    if (typeof (CustomEvent) === 'function') {
        return new CustomEvent(eventName, { detail: data, cancelable, bubbles })
    }
    // for IE https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent(eventName, bubbles, cancelable, data)
    return event
}

const getParameters = (parameterObj) => {
    const params = parameterObj || {}
    const newParams = PARAMETERS.reduce((obj, key) => {
        if (params.hasOwnProperty(key)) {
            obj[key] = params[key]
        }
        return obj
    }, {})
    return newParams
}

export const emit = (eventName, data) => {
    if (!eventName) {
        return
    }
    const event = eventFactory(eventName, data)
    document.dispatchEvent(event)
}

/**
 * Custom React hook for using CDM events
 *
 * @param {string} eventName The name of the contentdm event.  See utils/CdmClasses.js
 * @param {object} enterAndReadyParams An object of parameters that are intended to only be used with the :enter, :ready, and
 * :leave events
 * @param {object} updateParams An object of parameters that are intended to only be used with the :update event
 * @param {boolean} isComponentReady Set this to true when the component has finished loading its dependencies
 * @example
 * eventName: 'cdm-home-page',
 * enterAndReadyParams: {
 *   collectionId: 'oclcsample',
 *   itemId: '1',
 *   isLoading: false
 * },
 * updateParams: {
 *   collectionId: '10krecords',
 *   itemId: '1',
 *   isLoading: false
 * },
 * isComponentReady: true
 */
export const useCdmEventLifecycle = (eventName, enterAndReadyParams, updateParams, isComponentReady) => {

    const hasMounted = useRef(false)
    const hasEntered = useRef(false)
    const previousUpdateParams = useRef(updateParams)
    const readyFired = useRef(false)
    const componentWillUnmount = useRef(false)
    const [cdmEventName] = useState(eventName)
    const [cdmEnterAndReadyParams] = useState(enterAndReadyParams)

    useEffect(() => {
        if (!hasEntered.current) {
            emit(EVENT_TYPES.enter(cdmEventName), getParameters(cdmEnterAndReadyParams))
            hasEntered.current = true
        }
    })

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true
        }
    })

    /*
      ComponentDidUpdate
     */
    useEffect(() => {
        if (hasMounted) {
            if (isComponentReady && !readyFired.current) {
                emit(EVENT_TYPES.ready(cdmEventName), getParameters(cdmEnterAndReadyParams))
                readyFired.current = true
            } else if (isComponentReady && readyFired.current ) {
                    const foundUpdate = Object.keys(updateParams).find(field => {
                        return updateParams[field] !== previousUpdateParams.current[field]
                    })
                    if (foundUpdate) {
                        emit(EVENT_TYPES.update(cdmEventName), getParameters(updateParams))
                    }
                    previousUpdateParams.current = updateParams
            }
        }
    }, [cdmEventName, updateParams, cdmEnterAndReadyParams, isComponentReady])

    /*
      componentWillUnmount

      The clean-up (return) function runs before the component is removed from the UI to prevent memory
      leaks. Additionally, if a component renders multiple times (as they typically do), the previous effect is cleaned
      up before executing the next effect.

      The first useEffect does not have dependencies and the clean-up function is guaranteed to run when the component
      actually unmounts. When this happens we set the componentWillUnmount flag to true

      The second useEffect has 2 dependencies that when changed will fire the clean-up function to clean the previous
      effect. We only want the :leave event firing when the component is actually unmounted hence the
      check on componentWillUnmount.current.  Without this check the :leave event would fire every time the previous
      effect is cleaned up and that would be bad mmmkay
     */
    useEffect(() => {
        return () => {
            componentWillUnmount.current = true
        }
    }, [])
    useEffect(() => {
        return () => {
            if (componentWillUnmount.current) {
                emit(EVENT_TYPES.leave(cdmEventName), getParameters(updateParams))
            }
        }
    }, [cdmEventName, updateParams])
}
