import {useState, useReducer} from 'react'
import {useIntl} from 'react-intl'
import {SAVED_ITEMS} from 'constants/SavedItems'
import {saveItemsAsync} from 'service/SavedItemsService'
import he from "he";

export const SavedItemsAction = {
    'SET_SAVED_ITEM_IDS': 'setSavedItemIds',
    'SET_SAVED_ITEMS': 'setSavedItems',
    'SET_SELECTED_SAVED_ITEM': 'setSelectedSavedItem',
    'SET_SAVED_ITEMS_ALL_SELECTED': 'setSavedItemsAllSelected',
    'REFRESH_SAVED_ITEMS': 'refreshSavedItems',
    'REMOVE_SAVED_ITEMS': 'removeSavedItems',
    'SHOW_REMOVE_MODAL': 'showRemoveModal',
    'SET_SHOW_MAX_ITEMS_EXCEEDED_DIALOG': 'setShowMaxItemsExceededDialog',
    'SET_LOADING': 'setLoading',
    'SET_REFRESHING': 'setRefreshing',
    'SET_INITIAL_SAVED_ITEMS': 'setInitialSavedItems'
}

const actions = {
    setSavedItemIds: (state, action) => {
        const { savedItemIds, authname, skipPersist} = action.payload
        if (!skipPersist) {
            saveItemsAsync(savedItemIds, authname)
        }
        localStorage.setItem(SAVED_ITEMS, JSON.stringify(savedItemIds))
        return { ...state, savedItemIds }
    },
    setSavedItems: (state, action) => {
        const { items, authname } = action.payload
        return { ...state, items: items, authname: authname, loading: false }
    },
    setSelectedSavedItem: (state, action) => {
        const {alias, id, selected, intl} = action.payload

        // We should only have one item that matches the filter call above since alias and id are unique
        const clickedItem = state.items.filter(item => item.id === id && item.alias === alias)[0]
        clickedItem.selected = selected
        let index = state.items.findIndex(item => item.id === clickedItem.id && item.alias === alias)
        if (index > -1) {
            state.items[index] = clickedItem
        }

        // If all items have been manually checked (or unchecked), toggle the "select/deselect all" checkbox
        const selectedItems = state.items.filter(item => item.selected === true)
        let result = null
        if (selectedItems.length === state.items.length) {
            result = { ...state,
                allSelected: true,
                selectedText: he.decode(intl.formatMessage({ id: 'SITE_KEY_DeselectAllSavedItems', defaultMessage: ' ' })),
                buttonState: 'active',
                items: state.items
            }
        } else {
            result = { ...state,
                allSelected: false,
                selectedText: he.decode(intl.formatMessage({ id: 'SITE_KEY_SelectAllSavedItems', defaultMessage: ' ' })),
                buttonState: 'active',
                items: state.items
            }
        }
        return result
    },
    setSavedItemsAllSelected: (state, action) => {
        const {allSelected, selectedText} = action.payload
        const savedItems = state.items.map((item) => {
            item.selected = allSelected
            return item
        })
        return { ...state,
            allSelected,
            buttonState: action.payload.allSelected ? 'active' : 'disabled',
            items: savedItems, selectedText
        }
    },
    refreshSavedItems: (state, action) => {
        return {
            ...state,
            savedItemIds: action.payload.savedItemIds,
            isRefreshing: true
        }
    },
    removeSavedItems: (state, action) => {
        if (state.allSelected) {
            localStorage.removeItem(SAVED_ITEMS)
            saveItemsAsync([], action.payload.authname)
            return { ...state, allSelected: false, savedItemIds: [], showRemoveModal: false}
        } else {
            let { items } = { ...state }
            const selectedItems = items.filter(item => item.selected === true)
            const localStorageSavedItems = JSON.parse(localStorage.getItem(SAVED_ITEMS))
            selectedItems.forEach((selectedItem) => {
                const index = items.findIndex(item => item.alias === selectedItem.alias
                    && item.id === selectedItem.id)

                if (index > -1) {
                    items.splice(index, 1)
                }

                const indexToRemove = localStorageSavedItems.findIndex(item => {
                    return item.id === selectedItem.id.toString() && item.alias === selectedItem.alias
                })

                if (indexToRemove > -1) {
                    localStorageSavedItems.splice(indexToRemove, 1)
                }
            })
            localStorage.setItem(SAVED_ITEMS, JSON.stringify(localStorageSavedItems))
            saveItemsAsync(localStorageSavedItems, action.payload.authname)
            return { ...state, savedItemIds: localStorageSavedItems, items, showRemoveModal: false }
        }
    },
    showRemoveModal: (state, action) => {
        return { ...state, showRemoveModal: action.payload}
    },
    setShowMaxItemsExceededDialog: (state, action) => {
        return { ...state, showMaxItemsExceededDialog: action.payload }
    },
    setLoading: (state, action) => {
        return { ...state, loading: action.payload}
    },
    setRefreshing: (state, action) => {
        return { ...state, isRefreshing: action.payload }
    },
    setInitialSavedItems: (state, action) => {
        localStorage.setItem(SAVED_ITEMS, JSON.stringify(action.payload))
        return { ...state, savedItemIds: action.payload}
    }
}

const savedItemsReducer = (state, action) => {
    return actions[action.type](state, action)
}

/**
 * Manages the state for the saved items page
 *
 * @returns {Array} The first element contains the data required to render the saved items page.  The second element
 * is a function to dispatch actions that can occur with saved items
 */
export const useSavedItems = () => {

    const intl = useIntl()
    const parsedSavedItemIds = JSON.parse(localStorage.getItem(SAVED_ITEMS))
    const [savedItemIds] = useState(Array.isArray(parsedSavedItemIds) ? parsedSavedItemIds : [])

    const [savedItemsData, dispatch] = useReducer(savedItemsReducer, {
        savedItemIds: savedItemIds,
        items: [],
        allSelected: false,
        selectedText: he.decode(intl.formatMessage({ id: 'SITE_KEY_SelectAllSavedItems', defaultMessage: ' ' })),
        loading: savedItemIds && savedItemIds.length > 0 ? true : false,
        buttonState: 'disabled',
        showRemoveModal: false,
        showCreateLinkModal: false,
        showMaxItemsExceededDialog: false,
        isRefreshing: false,
        authname: ''
    })

    return [savedItemsData, dispatch]
}
