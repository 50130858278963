import {DEFAULT_OPTS, getDefaultHeaders} from './ServiceDefaults'

export const getFullTextTotals = async (collectionId, parentId, searchTerm) => {
    const endpoint = `/digital/api/fulltexttotals/collection/${collectionId}/id/${parentId}/searchterm/${searchTerm}`

    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                ...getDefaultHeaders()
            },
            ...DEFAULT_OPTS
        })
        return await response.json()

    } catch (error) {
        console.error(error)
    }
}

export const generateCompoundSearchResultsObjectFromArray = (records) => {
    return records.map(record => ({
        id: record[0],
        count: record[1],
        thumbnailUri: record[2],
        title: record[3]
    }))
};
