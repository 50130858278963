import {useHistory} from 'react-router-dom'

export const useRouteRefresh = () => {

    const history = useHistory()

    const refreshRoute = () => {
        history.go(0)
    }

    return refreshRoute
}
