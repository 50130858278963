import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import MetadataFields from 'components/MetadataFields'
import useAnalytics from 'hooks/useAnalytics'
import {SavedItemsAction} from 'hooks/useSavedItems'
import {Link} from 'react-router-dom'
import './SelectableItemListCard.scss'

const SelectableItemListCard = (props) => {

    const sendAnalytics = useAnalytics()
    const intl = useIntl()

    const {
        selectEnabled,
        selected,
        dispatch,
        title,
        metadataFields,
        thumbnailUri,
        thumbnailEnabled,
        collectionAlias,
        itemId
    } = props

    const url = `/digital/collection/${collectionAlias}/id/${itemId}`

    const metadataFieldObjs = metadataFields.map((mdField) => {
        return { value: mdField } })

    const onCardSelect = useCallback((e) => {
        const type = `${e.target.checked ? 'select' : 'deselect'}`
            sendAnalytics(
                'saved items list card',
                'click',
                `${type} collection: ${collectionAlias} item: ${itemId}`,
                'select saved item',
                {
                    type: type,
                    collection_alias: collectionAlias,
                    item_id: `${collectionAlias}/id/${itemId}`
                }
            );

        dispatch({ type: SavedItemsAction.SET_SELECTED_SAVED_ITEM, payload: {
            alias: collectionAlias,
            id: itemId,
            selected: e.target.checked,
            intl
        }})

    }, [dispatch, intl, collectionAlias, itemId, sendAnalytics])

    const handleLinkClick = useCallback(() => {
        sendAnalytics(
            'saved items list card',
            'click',
            `/digital/collection/${collectionAlias}/id/${itemId}`,
            'view saved item',
            {
                collection_alias: collectionAlias,
                item_id: `${collectionAlias}/id/${itemId}`
            }
        )
    },[sendAnalytics, collectionAlias, itemId])

    return (
        <div className="SelectableItemListCard-container">
            {selectEnabled ? <input className="SelectableItemListCard-selectItem"
                                    onChange={onCardSelect}
                                    type="checkbox" checked={selected ? 'checked' : ''}
                                    aria-label={`${title} ${selected ? 'selected' : 'not selected'}`} /> : null}

            <Link to={url} onClick={handleLinkClick} className="SelectableItemListCard-linkContainer shared-search-box shared-box shared-search-box shared-box row SelectableItemListCard cdm-item-card"
                  tabIndex={0}>

                {thumbnailEnabled
                    ? <div className="SelectableItemListCard-thumbnailHolder">
                        <img src={'/digital' + thumbnailUri} className="SelectableItemListCard-thumbnail" alt={title} />
                    </div>
                    : undefined
                }
                <div className="SelectableItemListCard-contentHolder">
                    <MetadataFields fields={metadataFieldObjs} />
                </div>
            </Link>
        </div>
    )
}

export default SelectableItemListCard
