import React from 'react'
import Facet from 'components/Facet'
import ShowMoreLess from 'components/ShowMoreLess'
import './Facets.scss'

const Facets = ({field, facetList}) => {

    return (
        <div className="Facets-container">
            <ShowMoreLess
                initialShowCount={10}
                entities={facetList}
                showMoreMessageKey="SITE_cdm_search_KEY_show_more"
                buttonClass="Facets-buttonDiv"
                facetField={field}
                render={(i, entity) => (
                    <Facet key={`${entity.title}-${i}`} field={field} {...entity} />
                )}
            />
        </div>
    )
}

export default Facets
