import { DEFAULT_OPTS, getDefaultHeaders } from './ServiceDefaults'

export const getCollectionCards = async (page = 1, homepageCardsPerPage = 10) => {

    const endpoint = '/digital/api/collections'

    try {
        const response = await fetch(`${endpoint}?startPage=${page}&count=${homepageCardsPerPage}`, {
            method: 'GET',
            headers: {
                ...getDefaultHeaders()
            },
            ...DEFAULT_OPTS
        })
        return await response.json()

    } catch (error) {

        console.error(error)
    }
}
