export const getCollectionStyleHref = (preview, collectionConfig, collectionAlias) => {
    let href
    const cacheBuster = preview.enabled ? `_working?v=${preview.version}` : collectionConfig && collectionConfig.body ? `?v=${collectionConfig.body.version}` : ''
    const origin = `/customizations/collection/${collectionAlias}/${collectionAlias}Styles.css`
    href = `${origin}${cacheBuster}`
    if (process.env.NODE_ENV !== 'production') {
        href = `https://${process.env.REACT_APP_STATIC_HOST}/customizations/collection/${collectionAlias}/${collectionAlias}Styles.css${cacheBuster}`
    }
    return [href, origin]
}

export const getCustomCollectionCss = (customCssFilePath) => {
    if (process.env.NODE_ENV !== 'production') {
        return `https://${process.env.REACT_APP_STATIC_HOST}/customizations/collection${customCssFilePath}`
    }
    return `/customizations/collection${customCssFilePath}`
}

export default getCustomCollectionCss
