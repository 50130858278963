import React from 'react'

/**
 * Provides the search result data after a search is executed
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} searchResults - provides the same fields as @{link org.oclc.cdm.search.SearchRequest}
 */
export const SearchResultsContext = React.createContext(/* searchResults */)
