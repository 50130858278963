import React from 'react'

/**
 * Site layout context that provides site configuration for the entire site
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} itemData - Item Specific Data
 */
export const CurrentItemContext = React.createContext(/* itemData */)
