/**
 * helper object for extend event types
 */
export const EVENT_TYPES = Object.freeze({
    /**
     * helper method to create app ready event name
     *
     * @returns 'cdm-app:ready'
     */
    appReady() {
        return EVENT_TYPES.ready('cdm-app');
    },

    /**
     * helper method to create eventName with ':enter'
     *
     * @param {String} eventName
     * @returns [eventName]:enter
     */
    enter(eventName = '') {
        return `${eventName}:enter`;
    },

    /**
     * helper method to create eventName with ':ready'
     *
     * @param {String} eventName
     * @returns [eventName]:ready
     */
    ready(eventName = '') {
        return `${eventName}:ready`;
    },

    /**
     * helper method to create eventName with ':update'
     *
     * @param {String} eventName
     * @returns [eventName]:update
     */
    update(eventName = '') {
        return `${eventName}:update`;
    },

    /**
     * helper method to create eventName with ':leave'
     *
     * @param {String} eventName
     * @returns [eventName]:leave
     */
    leave(eventName = '') {
        return `${eventName}:leave`;
    }
});
