import {useCallback} from 'react'
import {useTracking} from 'react-tracking'
import {HIT_TYPES} from 'metrics/Constants'

/**
 * Hook that returns a memoized callback for collection analytics
 *
 * @returns {function}
 */
const useAnalytics = () => {

    const tracking = useTracking()

    const sendAnalytics = useCallback(async (category, action, label, name, mapOfParams) => {
        const getAnalyticsData = (hitType, category, action, label) => (
            {
                hitType: hitType,
                eventCategory: category,
                eventAction: action,
                eventLabel: label,
                eventName: name,
                params: mapOfParams
            }
        )
        tracking.trackEvent(getAnalyticsData(HIT_TYPES.EVENT, category, action, label, name, mapOfParams))
    }, [tracking])

    return sendAnalytics
}

export default useAnalytics
