/**
 * Defines the shared useReducer actions for the collection search filter between Search and Advanced Search features.
 *
 * @type {Map<String, String>}
 * @example useAdvancedSearch.actions & SearchProvider.actions
 */
export const SearchCollectionFilterAction = {
    'SET_NUMBER_OF_COLLECTIONS_SELECTED': 'setNumberOfCollectionsSelected',
    'UPDATE_FILTER_CHANGES': 'updateFilterChanges',
    'SAVE_COLLECTION_FILTER_STRING': 'saveCollectionFilterString',
    'SET_HAS_FILTER_CHANGES': 'setHasFilterChanges'
}
