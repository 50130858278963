import {useState, useEffect} from 'react'
import {getCustomPage} from 'service/CustomPageService'

export const useCustomPage = (collectionAlias, filename) => {

    const [page, setPage] = useState({
        html: null,
        status: null,
        isLoading: true
    })

    useEffect(() => {
        const getPage = async (collectionAlias, filename) => {
            const response = await getCustomPage(collectionAlias, filename)
            setPage({
                html: response.body,
                status: response.status,
                isLoading: false
            })
        }

        if (filename) {
            getPage(collectionAlias, filename)
        }
    }, [collectionAlias, filename])

    return page
}
