import React, {memo} from 'react'
import FooterNavigationLinks from 'components/FooterNavigationLinks'
import FooterLanguageLinks from 'components/FooterLanguageLinks'
import {useIntl} from 'react-intl'
import {doubleDecodeMessageByKey} from 'utils/Decode'
import './Footer.scss'

const Footer = memo(function Footer() {

    const intl = useIntl()

    return (
        <div className="Footer-footerWrapper cdm-footer">
            <div className="Footer-footerContainer shared-box ">
                <FooterNavigationLinks />
                <FooterLanguageLinks />
                <div className="text-center" id="trademark">
                    {doubleDecodeMessageByKey(intl, 'SITE_KEY_poweredbycontentdmlinked')}
                </div>
            </div>
        </div>
    )
})

export default Footer
