import React, {useContext} from 'react'
import {CollectionContext} from 'contexts/CollectionContext'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {useParams} from 'react-router-dom'
import {getCollectionStyleHref} from "../utils/CollectionStyle";

export const useStyleCustomizations = () => {

    const {collectionAlias} = useParams()
    const collectionConfig = useContext(CollectionContext)
    const {preview} = useContext(SiteLayoutContext)
    const singleCollectionAlias = collectionAlias && collectionAlias.split('!').length === 1 ? collectionAlias : null

    let href, origin
    if (singleCollectionAlias) {
        [href, origin] = getCollectionStyleHref(preview, collectionConfig, collectionAlias)
    }
    let script = document.querySelector('#collectionCSS')
    let isAlreadyLoaded = script && script.getAttribute('href').includes(origin)

    return isAlreadyLoaded ? null : singleCollectionAlias ? <link rel="stylesheet" href={href} id='collectionCSS'/> : null
}
