import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import {parseUrlForCollectionIds} from 'utils/UrlParameters'
import './SearchInfo.scss'
import he from "he"

const SearchInfo = () => {

    const intl = useIntl()
    const location = useLocation()
    const searchResults = useContext(SearchResultsContext)
    const searchRequest = useContext(SearchRequestContext)
    const {query, collection} = searchRequest[0]

    const allCollectionsSelected = parseUrlForCollectionIds(location) === null

    const getModeString = () => {
        const term = query ? query : null
        const messageKey = term ? 'SITE_cdm_search_KEY_searchingItemsIn' : 'SITE_cdm_search_KEY_browsingItemsIn'
        return he.decode(intl.formatMessage({id: messageKey, defaultMessage: ' '}))
    }

    const getContextString = () => {
        if (allCollectionsSelected) {
            return he.decode(intl.formatMessage({id: 'SITE_KEY_allCollections', defaultMessage: ' '}))
        }

        const searchedCollectionsAliases = collection ? collection.split('!') : []
        const selectedCollections = searchResults.filters.collections.filter(it => it.selected).filter(it => searchedCollectionsAliases.includes(it.alias))

        if (searchedCollectionsAliases.length === searchResults.filters.collections.length) {
            return searchResults.filters.collections.length === 1
                ? selectedCollections[0].name
                : he.decode(intl.formatMessage({id: 'SITE_KEY_allCollections', defaultMessage: ' '}))
        } else if (searchedCollectionsAliases.length === 1 ) {
            if (selectedCollections[0]) {
                return selectedCollections[0].name
            } else {
                return intl.formatMessage({id: 'SITE_error_KEY_error_1', defaultMessage: ' '})
                    .replace("~CDMERRORCOLLECTION~", searchedCollectionsAliases[0])
            }
        } else {
            return he.decode(intl.formatMessage({id: 'SITE_KEY_multipleCollections', defaultMessage: ' '}))
        }
    }

    return (
        <div data-id="searchMode" className="SearchInfo-infoBox">
            <h1 className="SearchInfo-nonWctHeader hidden-xs">
                {getModeString()}
                <br />
                <strong>{getContextString()}</strong>
            </h1>
            <h1 className="SearchInfo-nonWctHeader visible-xs">
                {getModeString()} <strong>{getContextString()}</strong>
            </h1>
        </div>
    )
}

export default SearchInfo
