import React, {memo, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {useIntl} from 'react-intl'
import {Route, Switch, Redirect} from 'react-router-dom'
import About from 'components/About'
import AdvancedSearch from 'components/AdvancedSearch'
import CollectionLanding from 'components/CollectionLanding'
import CustomPage from 'components/CustomPage'
import CustomHtmlPage from 'components/CustomHtmlPage'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Home from 'components/Home'
import ItemView from 'components/ItemView'
import Login from 'components/Login'
import NotFound from 'components/NotFound'
import Search from 'components/Search'
import SavedItems from 'components/SavedItems'
import SavedItemsMaxExceededModal from 'components/SavedItemsMaxExceededModal'
import SharedItems from 'components/SharedItems'
import SiteLayoutProvider from 'components/SiteLayoutProvider'
import SkipToMainContent from 'components/SkipToMainContent'
import {SAVED_ITEMS} from 'constants/SavedItems'
import {EVENT_TYPES} from 'events/EventTypes'
import {emit} from 'events/useCdmEventLifecycle'
import {SavedItemsAction} from 'hooks/useSavedItems'
import getCustomCollectionCss from 'utils/CollectionStyle'
import {decodeAndTransformText} from 'utils/innerText'
import './SiteLayout.scss'
import he from "he";

const SiteLayout = memo(({collection, siteConfig}) => {

    const intl = useIntl()
    const [mainClass, setMainClass] = useState('cdm-home-page')

    useEffect(() => {
        emit(EVENT_TYPES.appReady())
    },[])

    return (
        <SiteLayoutProvider collection={collection} siteConfig={siteConfig}
                            render={(collData, simpleSearch, savedItemsDispatch, headerEnabled, footerEnabled, styleSheetLink) => {

            window.onstorage = (e) => {
                const isSavedItemEvent = e.key === SAVED_ITEMS
                if (isSavedItemEvent) {
                    const savedItemIds = e && e.newValue ? JSON.parse(e.newValue) : []
                    savedItemsDispatch({type: SavedItemsAction.REFRESH_SAVED_ITEMS, payload: {
                            savedItemIds
                        }})
                }
            }

            const routeSwitch = (
                <Switch>
                    <Route exact path='/' render={() => {
                        let component
                        if(siteConfig.homepage.customLandingPageEnabled) {
                            component = <CustomHtmlPage
                                customHtml={siteConfig.homepage.customLandingPage}
                                setMainClass={setMainClass}/>
                        } else {
                            component = <Home setMainClass={setMainClass}/>
                        }
                        return component
                    }}/>
                    <Route exact path='/digital' render={() => {
                        let component
                        if(siteConfig.homepage.customLandingPageEnabled) {
                            component = <CustomHtmlPage
                                customHtml={siteConfig.homepage.customLandingPage}
                                setMainClass={setMainClass}/>
                        } else {
                            component = <Home setMainClass={setMainClass}/>
                        }
                        return component
                    }}/>
                    <Route exact path='/digital/login' render={() => {
                        return <Login setMainClass={setMainClass}/>
                    }}/>
                    <Route exact path='/digital/about' render={() => {
                        return <About setMainClass={setMainClass} />
                    }}/>
                    <Route exact path='/digital/collection/:collectionAlias/custom/:filename' render={() => {
                        return <CustomPage setMainClass={setMainClass} />
                    }}/>
                    <Route exact path='/digital/collection/:collectionAlias' render={() => {
                        let component
                        if(collData && collData.body && collData.body.customLandingPageEnabled) {
                            component = collData.body.customLandingPage
                                ? <CustomHtmlPage
                                    customHtml={collData.body.customLandingPage}
                                    setMainClass={setMainClass} />
                                : <CollectionLanding setMainClass={setMainClass}/>
                        } else if (collData && collData.body && collData.body.customUrlEnabled) {
                            window.location.href = collData.body.customURL
                        } else if (collData && collData.body) {
                            component = <CollectionLanding setMainClass={setMainClass}/>
                        } else {
                            component = <></>
                        }
                        return component
                    }}/>
                    <Route path='/digital/collection/:collectionAlias/id/:itemId' render={() => {
                        return <ItemView setMainClass={setMainClass}/>
                    }}/>
                    <Route path='/digital/collection/:collectionAlias/search' render={() => {
                        return <Search setMainClass={setMainClass}/>
                    }}/>
                    <Route path='/digital/search/advanced' render={() => {
                        return <AdvancedSearch setMainClass={setMainClass}/>
                    }}/>
                    <Route path='/digital/search/collection/:collectionAlias' render={() => {
                        return <Search setMainClass={setMainClass} />
                    }}/>
                    <Route path='/digital/search' render={() => {
                        return <Search setMainClass={setMainClass} />
                    }}/>
                    <Route exact path='/digital/saved' render={() => {
                        if(siteConfig.enableSavedItems){
                            return <SavedItems setMainClass={setMainClass}/>
                        } else {
                            return <Redirect to="/digital/"/>
                        }
                    }}/>
                    <Route exact path='/digital/saved/:id' render={() => {
                        return <SharedItems setMainClass={setMainClass} />
                    }}/>
                    <Route exact path='/digital/saved/:customLinkText/:id' render={() => {
                        return <SharedItems setMainClass={setMainClass} />
                    }}/>
                    <Route path='/digital/custom/:filename' render={() => {
                        return <CustomPage setMainClass={setMainClass}/>
                    }}/>

                    {/* CDM Legacy 6.x UI Redirects */}
                    <Route exact path="/cdm/helpdocs/collection/:collectionAlias" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}`} />
                    )}/>
                    <Route exact path='/cdm/helpdocs' render={() => (
                        <Redirect to='/digital' />
                    )}/>
                    <Route exact path="/cdm/about/collection/:collectionAlias" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}`} />
                    )}/>
                    <Route exact path="/cdm/about" render={() => (
                        <Redirect to='/digital/about' />
                    )}/>
                    <Route path="/cdm/search/collection/:collectionAlias/*" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/search/${props.match.params['0']}`} />
                    )}/>
                    <Route exact path="/cdm/search" render={() => (
                        <Redirect to='/digital/search' />
                    )}/>
                    <Route exact path="/cdm/search/collection/:collectionAlias" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/search`} />
                    )}/>
                    <Route exact path="/cdm/compoundobject/collection/:collectionAlias/id/:itemId/show/*" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params["0"]}`} />
                    )}/>
                    <Route exact path="/cdm/ref/collection/:collectionAlias/id/:itemId/show/*" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params["0"]}`} />
                    )}/>
                    <Route exact path="/cdm/ref/collection/:collectionAlias/id/:itemId/rec/*" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/compoundobject/collection/:collectionAlias/id/:itemId/rec/*" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/singleitem/collection/:collectionAlias/id/:itemId/rec*" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/ref/collection/:collectionAlias/id/:itemId" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/compoundobject/collection/:collectionAlias/id/:itemId" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/singleitem/collection/:collectionAlias/id/:itemId" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/fullbrowser/collection/:collectionAlias/id/:itemId" render={props => (
                        <Redirect to={`/digital/collection/${props.match.params.collectionAlias}/id/${props.match.params.itemId}`} />
                    )}/>
                    <Route exact path="/cdm/favorites" render={() => (
                        <Redirect to="/digital" />
                    )}/>
                    <Route exact path="/cdm" render={() => (
                        <Redirect to="/digital" />
                    )}/>
                    <Route path="*" render={() => {
                        return <NotFound setMainClass={setMainClass}/>
                    }}/>
                </Switch>
            )

            const siteTitle = he.decode(intl.formatMessage({id: 'SITE_CONFIG_title', defaultMessage: ' '}))
            const customCSSFile = siteConfig.customFiles && siteConfig.customFiles.customCssFilePath
            const decodedTitle = decodeAndTransformText(siteTitle)
            const mainContainer =  <div className={`CoreLayout-maincontainer ${mainClass}`}>
                {customCSSFile
                    ? <link rel="stylesheet" href={`/customizations${customCSSFile}`} />
                    : null}
                <Helmet
                    title={decodedTitle}
                    defaultTitle={decodedTitle}
                    titleTemplate={'%s - ' + decodedTitle}
                />

                <SavedItemsMaxExceededModal/>
                {headerEnabled
                    ? <div className="CoreLayout-headerContainer cdm-header">
                        <SkipToMainContent href={'.cdm-main-content'} tabIndex={3}/>
                        <Header/>
                    </div>
                    : <></>
                }
                <div className="CoreLayout-mainWrapperContainer">
                    <div className="CoreLayout-mainwrapper cdm-main-content">
                        {styleSheetLink
                            ? <div className="CollectionLayout-container">
                                <link rel="stylesheet" href={styleSheetLink} />
                                {collData && collData.body && collData.body.customFiles && collData.body.customFiles.customCssFilePath
                                    ? <link rel="stylesheet" href={getCustomCollectionCss(collData.body.customFiles.customCssFilePath)} />
                                    : null}
                                {routeSwitch}
                            </div>
                            : routeSwitch
                        }
                    </div>
                    { footerEnabled ? <Footer/> : <></>}
                </div>
            </div>

            return mainContainer
        }}>
        </SiteLayoutProvider>
    )
})

SiteLayout.propTypes = {
    collection: PropTypes.string,
    siteConfig: PropTypes.object
}

export default SiteLayout
