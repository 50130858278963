import React from 'react'
import SearchResultsDisplaySelector from 'components/SearchResultsDisplaySelector'
import SearchResultsPerPageSelector from 'components/SearchResultsPerPageSelector'
import './SearchResultsOptionHeaderMobile.scss'

const SearchResultsOptionHeaderMobile = () => {
    return (
        <div id='mobileResultsOptionHeader' className="MobileResultsOptionsHeader-container shared-search-box shared-box visible-xs container">
            <div className="MobileResultsOptionsHeader-SupplementalHeaderBar">
                <SearchResultsDisplaySelector />
                <SearchResultsPerPageSelector idPrefix={'mobile'} />
            </div>
        </div>
    )
}

export default SearchResultsOptionHeaderMobile
