import { DEFAULT_OPTS, getDefaultHeaders } from './ServiceDefaults'

export const getItemInfo = async (collectionId, itemId) => {

    const endpoint = `/digital/api/collections/${collectionId}/items/${itemId}/false`

    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                ...getDefaultHeaders()
            },
            ...DEFAULT_OPTS
        })
        return {
            statusCode: response.status,
            item: await response.json()
        }
    } catch (error) {
        return { error }
    }
}
