import React from 'react'

/**
 * Authentication context providing the details of the currently logged in user
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} authData
 *   @property {string} name - username of the currently logged in user, null if not logged in
 *   @property {boolean} isWCTAdmin - is the user a wct admin
 *   @property {function} refreshAuthenticationData - callback function to invoke whenever someone logs in or logs out
 *     @param {none}
 */
export const AuthenticationContext = React.createContext(/* authData */)

