import {DEFAULT_OPTS, getDefaultHeaders} from "./ServiceDefaults"

export const fetchSavedItems = async (savedItemIds) => {

    const response = await fetch('/digital/api/saved/items', {
        method: 'POST',
        headers: {
            ...getDefaultHeaders(),
            'Content-Type': 'application/json'
        },
        ...DEFAULT_OPTS,
        body: JSON.stringify({ items: savedItemIds })
    })
    return await response.json()
}

export const exportSavedItems = async (savedItemIds, action) => {

    const response = await fetch('/digital/api/export/saved', {
        method: 'POST',
        headers: {
            ...getDefaultHeaders(),
            'Content-Type': 'application/json',
            'Accept-Language': localStorage.getItem('cdm_selected_language')
        },
        ...DEFAULT_OPTS,
        responseType: 'blob',
        body: JSON.stringify({ action: action, items: savedItemIds })
    })
    return await response.blob()
}

export const persistSavedItems = async (savedItemIds, persistType, authname) => {
    if (authname || persistType === 'SAVE_SHARED_LIST') {
        const response = await fetch(`/digital/api/saved/persist`, {
            method: 'POST',
            headers: {
                ...getDefaultHeaders(),
                'Content-Type': 'application/json',
                'Accept-Language': localStorage.getItem('cdm_selected_language')
            },
            ...DEFAULT_OPTS,
            body: JSON.stringify({
                requestType: persistType,
                items: savedItemIds
            })
        })
        return await response.json()
    }
}

export const saveItemsAsync = async (savedItemIds, authname) => {
    if (authname) {
        const response = await fetch(`/digital/api/saved/persist/async`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': localStorage.getItem('cdm_selected_language')
            },
            body: JSON.stringify({
                requestType: 'SAVE_PERSONAL_LIST',
                items: savedItemIds
            })
        });
        await response.json();
    }
}
