import {DEFAULT_OPTS} from "./ServiceDefaults"

export const fetchSharedItems = async (sharedItemUUID) => {

    const response = await fetch(`/digital/api/saved/shared/${sharedItemUUID}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Accept-Language': localStorage.getItem('cdm_selected_language')
        },
        ...DEFAULT_OPTS
    });
    return await response.json();
}
