import {useReducer} from 'react'

/**
 * Enables the header to be displayed
 * @returns {{type: string}}
 */
export const enableHeader = () => {
    return { type: 'enableHeader' }
}

/**
 * Disables the header from being displayed
 * @returns {{type: string}}
 */
export const disableHeader = () => {
    return { type: 'disableHeader' }
}

/**
 * Enables the footer to be displayed
 * @returns {{type: string}}
 */
export const enableFooter  = () => {
    return { type: 'enableFooter' }
}

/**
 * Disables the footer from being displayed
 * @returns {{type: string}}
 */
export const disableFooter = () => {
    return { type: 'disableFooter' }
}

const headerActions = {
    enableHeader: (state) => {
        return { ...state, headerEnabled: true }
    },
    disableHeader: (state) => {
        return { ...state, headerEnabled: false }
    }
}

const footerActions = {
    enableFooter: (state) => {
        return { ...state, footerEnabled: true }
    },
    disableFooter: (state) => {
        return { ...state, footerEnabled: false }
    }
}

const headerReducer = (state, action) => {
    return headerActions[action.type](state, action)
}

const footerReducer = (state, action) => {
    return footerActions[action.type](state, action)
}

export const useHeader = () => {
    return useReducer(headerReducer, {
        headerEnabled: true
    })
}

export const useFooter = () => {
    return useReducer(footerReducer, {
        footerEnabled: true
    })
}
