import {useEffect, useReducer} from 'react'
import {fetchSettings} from 'service/AdvancedSearchService'

export const DEFAULT_QUERY = {
    mode: 'all',
    searchTerm: '',
    field: 'all',
    connector: 'and'
}

export const DEFAULT_DATE_QUERY = {
    mode: 'on',
    searchTerm: '',
    searchTerm2: ''
}

export const Action = {
    'IS_LOADING': 'setLoading',
    'SET_COLLECTIONS': 'setCollections',
    'SET_SINGLE_COLLECTION_SETTINGS': 'setSingleCollectionSettings',
    'SET_SETTINGS': 'setSettings',
    'SET_QUERIES': 'setQueries',
    'ADD_QUERY': 'addQuery',
    'UPDATE_QUERY': 'updateQuery',
    'UPDATE_DATE_QUERY': 'updateDateQuery',
    'RESET_DATE_QUERY': 'resetDateQuery'
}

const actions = {
    setLoading: (state, action) => {
        return {...state, isLoading: action.payload}
    },
    setCollections: (state, action) => {
        return {...state, filters: {collections: action.payload}}
    },
    setSettings: (state, action) => {
        return {...state, searchTermFields: action.payload}
    },
    setSingleCollectionSettings: (state, action) => {
        return {...state, searchTermFields: action.payload}
    },
    setQueries: (state, action) => {
        return {...state, queries: action.payload}
    },
    addQuery: (state, action) => {
        const {queries} = state
        queries.push(action.payload)
        return {...state, queries}
    },
    updateQuery: (state, action) => {
        const {queries} = state
        queries[action.payload.index][action.payload.fieldName] = action.payload.value
        return {...state, queries}
    },
    setNumberOfCollectionsSelected: (state, action) => {
        return {...state, numberOfCollectionsSelected: action.payload}
    },
    updateFilterChanges: (state, action) => {
        return {...state, filters: {collections: action.payload}}
    },
    saveCollectionFilterString: (state, action) => {
        return {...state, collection: action.payload}
    },
    setHasFilterChanges: (state, action) => {
        return {...state, hasFilterChanges: action.payload}
    },
    updateDateQuery: (state, action) => {
        const {dateQuery} = state
        dateQuery[action.payload.fieldName] = action.payload.value
        return {...state, dateQuery}
    },
    resetDateQuery: (state) => {
        return {...state, dateQuery: {...DEFAULT_DATE_QUERY}}
    }
}

const advancedSearchReducer = (state, action) => {
    return actions[action.type](state, action)
}

/**
 * Custom hook to return the data required by advanced search to render the page
 *
 * @returns {object} The data required to render Advanced Search as well as a loading indicator
 */
export const useAdvancedSearch = () => {

    const [advancedSearch, dispatch] = useReducer(advancedSearchReducer, {
        isLoading: true,
        filters: {
            collections: []
        },
        collectionFilterChanged: false,
        collection: '',
        searchTermFields: [],
        queries: [{...DEFAULT_QUERY}],
        dateQuery: { ...DEFAULT_DATE_QUERY},
        hasFilterChanges: false
    })

    useEffect(() => {
        const loadSettings = async () => {
            const settings = await fetchSettings()
            dispatch({ type: Action.SET_SETTINGS, payload: settings })
        }

        const loadAdvancedSearchData = async () => {
            await loadSettings()
            dispatch({ type: Action.IS_LOADING, payload: false })
        }

        loadAdvancedSearchData()
    }, [])

    return [advancedSearch, dispatch]
}
