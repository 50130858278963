import React, {useContext} from "react"
import {useHistory, useLocation} from 'react-router-dom'
import {useIntl} from "react-intl"
import {Pagination} from "react-bootstrap"
import {useTracking} from "react-tracking"
import GoTo from 'components/GoTo'
import PageNumberContext from 'hooks/usePageNumber'
import {turnOnLoadingNextPrevItem} from 'hooks/useItem'
import {collectEventAnalytics} from "metrics/Analytics"
import {addPageToUrl} from 'utils/UrlParameters'
import "./Paginator.scss"
import he from "he";

export const MAX_PAGEABLE_ITEMS = 10000

/**
 * Pagination component
 *
 * @param {object} props
 *   @property {boolean} hideInfo Set to true to hide, false to show
 *   @property {boolean} isSearch Set to true if this paginator is used on the search/browse screen
 *   @property {boolean} isCompoundItem Set to true if this paginator is used on the compound item page
 *   @property {object} pageInfo
 *     @property {Number} cardsPerPage Number of cards to display per page
 *     @property {Number} totalCollections Total number of collections/items
 *     @property {Number} pageNumber Currently selected page number
 *     @property {Array<object>} cards List of cards that are shown on the current page
 *     @property {string} trackPage The name of the page to track, e.g. 'home page'
 *     @property {string} className Additional css classnames here for the paginator
 *     @property {string} itemName The type of pagination, "Collections" or "Records"
 */
const Paginator = (props) => {

    const history = useHistory()
    const location = useLocation()
    const [, setPageNumber] = useContext(PageNumberContext)

    const intl = useIntl()
    const tracking = useTracking()
    const hideInfo = props.hideInfo

    const cardsPerPage = props.pageInfo.cardsPerPage
    const totalPageCap = props.pageInfo.totalCollections > MAX_PAGEABLE_ITEMS ? MAX_PAGEABLE_ITEMS : props.pageInfo.totalCollections
    const totalPages = totalPageCap % cardsPerPage !== 0
        ? Math.floor(totalPageCap / cardsPerPage) + 1
        : Math.floor(totalPageCap / cardsPerPage)

    const isHomepage = location.search && location.search.includes('?page=')
    const homeMatch = location.search.match(/\?page=(\d+)/)
    const browseMatch = location.pathname.match(/\/page\/(\d+)/)
    const itemMatch = props.pageInfo.trackPage === 'Item page'
    const activePage = isHomepage
        ? homeMatch
            ? parseInt(homeMatch[1]) : 1
        : browseMatch ? parseInt(browseMatch[1])
            : itemMatch ? props.pageInfo.pageNumber : 1

    const prevPageMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_goToPreviousPage', defaultMessage: ' '}))
    const nextPageMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_goToNextPage', defaultMessage: ' '}))
    const pageMessage = he.decode(intl.formatMessage({id: 'SITE_KEY_page', defaultMessage: ' '}))
    const disablePrev = activePage < 2
    const disableNext = activePage >= totalPages

    const item = props.pageInfo.item
    const collectionAlias = props.pageInfo.collectionAlias
    const children = item && item.parent.children.map(field => field.id)
    const recNumber = history.location.pathname.split('/rec/')[1]

    const spanPrev = <span key="icon" className={"fa fa-chevron-left"} aria-label={prevPageMessage}
                           title={prevPageMessage}/>
    const spanNext = <span key="icon" className={"fa fa-chevron-right"} aria-label={nextPageMessage}
                           title={nextPageMessage}/>

    // React bootstrap 0.33.0 (for bootstrap 3) does not prevent default automatically
    const handlePrev = (e) => {
        if (activePage > 1) {
            if (props.isCompoundItem) {
            } else {
                const locationObj = props.isSearch
                    ? {pathname: addPageToUrl(location, activePage - 1)}
                    : {pathname: `/digital`, search: `?page=${activePage - 1}`}

                history.push({
                    ...locationObj,
                    state: {page: activePage - 1}
                })
            }
            if (setPageNumber) setPageNumber(activePage - 1)

            if (itemMatch) {
                handlePage(activePage - 1, e)
            }

            collectEventAnalytics(tracking,
                props.pageInfo.trackPage,
                'click',
                'pagination',
                'pagination',
                {
                    'type': 'previous',
                    'page': props.pageInfo.trackPage
                }
                )
        } else {
            e.preventDefault()
        }
    }

    const handleNext = (e) => {
        if (activePage < totalPages) {
            if (!props.isCompoundItem) {
                const locationObj = props.isSearch
                    ? {pathname: addPageToUrl(location, activePage + 1)}
                    : {pathname: `/digital`, search: `?page=${activePage + 1}`}

                history.push({
                    ...locationObj,
                    state: {page: activePage + 1}
                })
            }
            if (setPageNumber) setPageNumber(activePage + 1)

            if (itemMatch) {
                handlePage(activePage + 1, e)
            }

            collectEventAnalytics(tracking,
                props.pageInfo.trackPage,
                'click',
                'pagination',
                'pagination',
                {
                    'type': 'next',
                    'page': props.pageInfo.trackPage
                }
                )
        } else {
            e.preventDefault()
        }
    }

    const handlePage = (page, e) => {
        if (activePage !== page) {
            if (!props.isCompoundItem) {
                const locationObj = props.isSearch
                    ? {pathname: addPageToUrl(location, page)}
                    : {pathname: `/digital`, search: `?page=${page}`}

                history.push({
                    ...locationObj,
                    state: {...history.location.state, page: page}
                })
            }
            if (setPageNumber) setPageNumber(page)

            if (itemMatch) {
                const newIndex = props.pageInfo.cardsPerPage * (page - 1)
                const newId = children[newIndex]

                props.itemDispatch(turnOnLoadingNextPrevItem())
                history.push({
                    pathname: `/digital/collection/${collectionAlias}/id/${newId}${recNumber
                        ? `/rec/${recNumber}` : ''}`,
                    state: {...history.location.state, shouldScroll: false}
                })
            }
            collectEventAnalytics(tracking,
                props.pageInfo.trackPage,
                'click',
                'pagination',
                'pagination',
                {
                    'type': 'page number',
                    'page': props.pageInfo.trackPage
                }
            )
        } else {
            if (e) {
                e.preventDefault()
            }
        }
    }

    const lessThanSevenPages = () => {
        let pages = []
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <Pagination.Item aria-label={`${pageMessage} ${i}`} active={i === activePage} tabIndex={0}
                                 key={i} onClick={() => handlePage(i)}>{i}</Pagination.Item>
            )
        }
        return pages
    }

    const moreThanSevenPages = () => {
        let middle = []

        // Start by filling the array with buttons for all pages
        for (let i = 1; i <= totalPages; i++) {
            middle.push(
                <Pagination.Item aria-label={`${pageMessage} ${i}`} active={i === activePage} tabIndex={0}
                                 key={i} onClick={(e) => handlePage(i, e)}>{i}</Pagination.Item>
            )
        }

        // Replace back-end buttons with an ellipses
        if (activePage + 1 < totalPages - 2) {
            middle.splice(activePage + 1, (totalPages - (activePage + 2)),
                <Pagination.Ellipsis key={'back-ellipses'} disabled/>)
        }

        // Replace front-end buttons with an ellipses
        if (activePage - 1 > 3) {
            middle.splice(1, (activePage - 3), <Pagination.Ellipsis key={'front-ellipses'} disabled/>)
        }

        // Force 3rd button from start
        if (activePage === 1) {
            middle.splice(2, 0,
                <Pagination.Item key={3} aria-label={`${pageMessage} ${3}`}
                                 onClick={() => handlePage(3)}>{3}</Pagination.Item>)
        }

        // Force 3rd button from end
        if (activePage === totalPages) {
            middle.splice((middle.length - 2), 0,
                <Pagination.Item key={totalPages - 2} aria-label={`${pageMessage} ${totalPages - 2}`}
                                 onClick={() => handlePage(totalPages - 2)}>{totalPages - 2}</Pagination.Item>)
        }

        return middle
    }

    const startIndex = activePage === 1 ? 1 : ((activePage * cardsPerPage) - cardsPerPage + 1)
    const endIndex = props.pageInfo.cards.length === cardsPerPage
        ? (activePage * cardsPerPage)
        : (activePage * cardsPerPage) - (cardsPerPage - props.pageInfo.cards.length)
    const itemName = props.pageInfo.itemName
    const total = props.pageInfo.totalCollections
    const homePageAndOnePage = totalPages < 2 && props.pageInfo.trackPage === 'home page'

    const paginatorText = he.decode(intl.formatMessage({id: itemName, defaultMessage: ' '}, {
        begin_index: startIndex,
        end_index: endIndex,
        total_items: total
    }))


    return (
        homePageAndOnePage ? null :
            <div className={`Paginator-paginationwrapper ${props.pageInfo.className}`}>
                <div>
                    <div className={'Paginator-flexGrow'}>
                        {!hideInfo ? <div className={'Paginator-paginationInfo'}>
                            <h3>{paginatorText}</h3>
                        </div> : ''}
                    </div>
                    <div className={`Paginator-paginationPageHolder ${(hideInfo ? 'paginationPageNoInfo' : '')}`}>
                        <Pagination className={'Paginator-pagination'}>
                            <Pagination.Prev disabled={disablePrev} onClick={(e) => handlePrev(e)} children={spanPrev}/>
                            {totalPages <= 7
                                ? lessThanSevenPages()
                                : moreThanSevenPages()
                            }
                            <Pagination.Next disabled={disableNext} onClick={handleNext} children={spanNext}/>
                        </Pagination>
                    </div>
                    <div className={'Paginator-flexGrow'}>
                        {totalPages > 7 && !hideInfo ? <GoTo max={totalPages} onSelect={handlePage}/> : null}
                    </div>
                </div>
            </div>

    )
}

export default Paginator

