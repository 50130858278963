import React, {useContext} from "react"
import {Link} from 'react-router-dom'
import FontAwesome from 'react-fontawesome'
import './Breadcrumbs.scss'
import {useTracking} from "react-tracking"
import {collectEventAnalytics} from "metrics/Analytics"
import {SiteLayoutContext} from "../../contexts/SiteLayoutContext";
import {CollectionContext} from "../../contexts/CollectionContext";
import {useIntl} from "react-intl";
import he from "he"

/**
 * Breadcrumb navigation to return to the collection that the item belongs to,
 * or to the homepage.
 */
const Breadcrumbs = (props) => {
    const intl = useIntl()
    const tracking = useTracking()
    const siteConfig = useContext(SiteLayoutContext)
    const collectionConfig = useContext(CollectionContext)
    const {collectionName, collectionAlias, isSingleItem, itemTitle} = props

    let homeUrl = '/digital'
    const customHomeUrlEnabled = siteConfig && siteConfig.homepage && siteConfig.homepage.customUrlEnabled
        && siteConfig.homepage.customURL
    if (customHomeUrlEnabled) {
        homeUrl = siteConfig.homepage.customURL
    }
    let linkPath = `/digital/collection/${collectionAlias}`
    const customUrlEnabled = collectionConfig && collectionConfig.body && collectionConfig.body.customUrlEnabled
        && collectionConfig.body.customURL != null
    if (customUrlEnabled) {
        linkPath = collectionConfig.body.customURL
    }
    const eventCategory = isSingleItem ? 'single item' : 'compound object'
    const renderHomeLink = (customHomeUrlEnabled) => {
        return customHomeUrlEnabled ?
            <a href={homeUrl} onClick={
                () => {collectEventAnalytics(
                    tracking,
                    eventCategory,
                    'click',
                    'breadcrumbs: home',
                    'breadcrumbs:home')
                }
            }
            >{
                he.decode(intl.formatMessage({
                    defaultMessage: ' ',
                    id: 'SITE_KEY_home'}))}
            </a> :
            <Link to={homeUrl} onClick={
                () => {
                    collectEventAnalytics(
                        tracking,
                        eventCategory,
                        'click',
                        'breadcrumbs: home',
                        'breadcrumbs:home')
                }
            }
            >{
                he.decode(intl.formatMessage(
                    {
                        defaultMessage: ' ',
                        id: 'SITE_KEY_home'
                    }
                ))
            }
            </Link>
    }

    const renderCollectionLink = (customUrlEnabled) => {
        return customUrlEnabled ?
            <a href={linkPath} onClick={() => {collectEventAnalytics(
                tracking,
                eventCategory,
                'click',
                `breadcrumbs: collection:${linkPath}`,
                'breadcrumbs:collection',
                {collection_alias: collectionAlias})
            }}>
                {collectionName}
            </a> :
            <Link to={linkPath} onClick={() => {collectEventAnalytics(
                tracking,
                eventCategory,
                'click',
                `breadcrumbs: collection:${linkPath}`,
                'breadcrumbs:collection',
                { collection_alias: collectionAlias })
            }}>
                {collectionName}
            </Link>
    }

    return (
        <div className={'Breadcrumbs-breadcrumbs'} data-id="breadcrumbs">
            <div className={'Breadcrumbs-breadcrumb'}>
                {renderHomeLink(customHomeUrlEnabled)}
            </div>
            <div className={'Breadcrumbs-breadcrumb'}>
                <FontAwesome name="angle-right" />
                {renderCollectionLink(customUrlEnabled)}
            </div>
            {
                itemTitle
                    ? <span className={'Breadcrumbs-breadcrumb'}><FontAwesome name="angle-right" />
                    <span>{itemTitle}</span></span>
                    : undefined
            }
        </div>

    )
}

export default Breadcrumbs
