import React, {useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {parseUrlForCollectionIds} from 'utils/UrlParameters'
import {getCollectionStyleHref} from 'utils/CollectionStyle'
import {CollectionContext} from 'contexts/CollectionContext'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'

const CollectionAwareRenderer = (props) => {

    const location = useLocation()
    const collectionConfig = useContext(CollectionContext)
    const {preview} = useContext(SiteLayoutContext)
    const [styleSheetLink, setStyleSheetLink] = useState(null)
    const {renderFn, advancedSearch, collData, simpleSearch, savedItemsDispatch, header, footer} = props

    useEffect(() => {
        let href
        const collectionIds = parseUrlForCollectionIds(location)
        if (collectionIds && collectionIds.length === 1) {
            [href] = getCollectionStyleHref(preview, collectionConfig, collectionIds[0])
            setStyleSheetLink(href)
        } else {
            setStyleSheetLink(null)
        }
    }, [location, collectionConfig, preview])

    return !advancedSearch.isLoading
        ? renderFn(collData,
            simpleSearch,
            savedItemsDispatch,
            header.headerEnabled,
            footer.footerEnabled,
            styleSheetLink)
        : <></>
}

export default CollectionAwareRenderer
