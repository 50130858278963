import {DEFAULT_OPTS, getDefaultHeaders} from './ServiceDefaults'

export const login = async (formData) => {

    try {
        const response = await fetch('/digital/login', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                ...getDefaultHeaders()
            },
            credentials: 'include',
            body: formData
        })

        return response
    } catch(error) {
        return error
    }
}

export const logout = async () => {
    const response = await fetch('/digital/logout', {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            ...getDefaultHeaders()
        }
    })

    return response
}

export const getAuthenticationDetails = async () => {

    const response = await fetch('/digital/auth', {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            ...getDefaultHeaders()
        },
        ...DEFAULT_OPTS
    })

    return await response.json()
}
