import React, {useCallback, useContext} from 'react'
import {AuthenticationContext} from "contexts/AuthenticationContext"
import {useIntl} from "react-intl"
import {doubleDecode} from 'utils/doubleDecode'
import {MenuItem, Dropdown, SafeAnchor} from 'react-bootstrap'
import {Link, useLocation, useHistory} from "react-router-dom"
import {useTracking} from "react-tracking"
import {collectEventAnalytics, toAbsoluteUrl} from 'metrics/Analytics'
import FontAwesome from 'react-fontawesome'
import {SiteLayoutContext} from "contexts/SiteLayoutContext"
import {useLogout} from 'hooks/useLogout'
import useAnalytics from 'hooks/useAnalytics'
import isAbsoluteURI from 'utils/isAbsoluteURI'
import {LocaleContext} from "contexts/LocaleContext"
import {parsePathForCollectionIds, parseUrlForCollectionIds} from 'utils/UrlParameters'
import he from "he";

const HeaderDropdown = () => {

    const intl = useIntl()
    const tracking = useTracking()
    const authData = useContext(AuthenticationContext)
    const localeData = useContext(LocaleContext)
    const siteConfig = useContext(SiteLayoutContext)
    const logout = useLogout()
    const sendAnalytics = useAnalytics()
    const location = useLocation()
    const history = useHistory()

    const browseHref = () => {
        return siteConfig.collectionId ? `/collection/${siteConfig.collectionId}/search` : '/digital/search'
    }

    const browseUrl = () => {
        let browseUrl

        let collectionIdsScope = []
        if (history.location && history.location.state && history.location.state.collectionScope) {
            collectionIdsScope = parsePathForCollectionIds(history.location.state.collectionScope)
        }

        if (collectionIdsScope && collectionIdsScope.length > 0) {
            const pathname = collectionIdsScope.length === 1 ? `/digital/collection/${collectionIdsScope[0]}/search` : `/digital/search/collection/${collectionIdsScope.join('!')}`
            browseUrl = {pathname, state: { ...location.state, collectionScope: location.pathname}}
        } else {
            collectionIdsScope = parseUrlForCollectionIds(location)
            if (collectionIdsScope && collectionIdsScope.length > 0) {
                const pathname = collectionIdsScope.length === 1 ? `/digital/collection/${collectionIdsScope[0]}/search` : `/digital/search/collection/${collectionIdsScope.join('!')}`
                browseUrl = {pathname, state: { ...location.state, collectionScope: location.pathname}}
            } else {
                browseUrl = browseHref()
            }
        }

        return browseUrl
    }

    const onLoginMenuItemClick = useCallback(() => {
        sendAnalytics('navigation',
            'click',
            'Log in link',
            'log in link',
            {source: 'hamburger menu'})
    },[sendAnalytics])

    const onLogoutMenuItemClick = useCallback(async () => {
        sendAnalytics('navigation',
            'click',
            'Log out link',
            'log out link',
            {source: 'hamburger menu'})
        logout()
    },[sendAnalytics, logout])

    const renderLoginLinks = () => {
        const logoutMessage = he.decode(intl.formatMessage({ id: 'SITE_KEY_logout', defaultMessage: ' ' }))
        const loginMessage = he.decode(intl.formatMessage({ id: 'SITE_KEY_login', defaultMessage: ' ' }))

        if (siteConfig.header.navmenu.enableLogin) {
            if (authData.name) {
                return <MenuItem onClick={onLogoutMenuItemClick}>
                    <h3>
                        {`${logoutMessage}, ${authData.name}`}
                    </h3>
                </MenuItem>
            } else {
                return <MenuItem componentClass={Link} eventKey='5'
                                 to={{pathname: '/digital/login', state: { ...location.state, prevPath: window.location.pathname }}}
                                 href={'/digital/login'}
                                 onClick={onLoginMenuItemClick}>
                    <h3>
                        {loginMessage}
                    </h3>
                </MenuItem>
            }
        }
        return undefined
    }

    const createRelativeInternalLink = (link, customLink, linkTitle, externalLinkIcon, index, target, savedItemsMatch) => {
        let relativeInternalLink = null
        if (link.openInWindow === true) {
            // Need to make the internal link relative to the document root so it will open correctly
            // customLink = customLink[0] === '/' ? `/digital${customLink}` : `/digital/${customLink}`
            relativeInternalLink =
                <MenuItem key={index} eventKey={index + 3} to={customLink} href={customLink} componentClass={Link}
                               target={target}
                               onClick={() => {collectEventAnalytics(tracking, 'navigation',
                                   'click', `link:${toAbsoluteUrl(customLink)}`, 'custom link', {source: 'header', link: toAbsoluteUrl(customLink)})}}>

                    <h3>{savedItemsMatch
                        ? <FontAwesome className="Header-faCustom star" name='star' />
                        : undefined
                    }{doubleDecode(linkTitle)}{externalLinkIcon}
                    </h3>
                </MenuItem>
        }

        return relativeInternalLink
    }

    const customLinks = (headerProps) => {

        return (
            headerProps.navmenu.links.map((link, i) => {
                if (link.url) {
                    const linkTitle = localeData.messages[link.title]
                    let target = '_blank'
                    if(linkTitle) {
                        // Check for mailto links (CDMRUI-1861)
                        if (link.url.startsWith('mailto:')) {
                            target = ''
                        }

                        let externalLinkIcon = <FontAwesome role="presentation" name='external-link'
                            className={'Header-hamburgerExternalLinkIcon'}>
                            <span className={'Header-hideText'}>Opens in External Window</span>
                        </FontAwesome>

                        if (isAbsoluteURI(link.url)) {
                            const savedItemsPattern = /digital\/saved$/
                            const savedItemsMatch = link.url.match(savedItemsPattern)
                            let icon = ''
                            if (savedItemsMatch) {
                                icon = <FontAwesome className="Header-faCustom star" name='star' />
                            }

                            // Follow user settings unless it is mailto:
                            if (link.openInWindow === false && target !== '') {
                                target = '_self'
                                externalLinkIcon = ''
                            }

                            return <MenuItem key={i} eventKey={i + 3}
                                             href={link.url} target={target}
                                             onClick={() => {collectEventAnalytics(tracking,
                                                 'navigation',
                                                 'click',
                                                 `link:${link.url}`,
                                                 'custom link',
                                                 {
                                                     source: 'header',
                                                     link: link.url
                                                 })}}
                            > <h3>{icon}{doubleDecode(linkTitle)}{externalLinkIcon}</h3>
                            </MenuItem>
                        } else {
                            const savedItemsPattern = /^digital\/saved$/
                            const savedItemsMatch = link.url.match(savedItemsPattern)
                            let icon = ''
                            if (savedItemsMatch) {
                                icon = <FontAwesome className="Header-faCustom star" name='star' />
                            }

                            // If we execute the commented-out line below it will force a relative path based on the
                            // current location
                            // let customLink = link.url.replace(/^\/*digital\//g, '')
                            let customLink = link.url.startsWith('/') ? link.url : `/${link.url}`

                            if (customLink !== '') {
                                // We tell the site admins to add /digital/ in the URL in the WCT so we need to strip it here
                                // because it's added by the app by default
                                let customLinkHTML =
                                    <MenuItem componentClass={Link} key={i + 10} eventKey={i + 10}
                                              to={customLink} href={customLink}
                                              onClick={() => {collectEventAnalytics(tracking,
                                                  'navigation',
                                                  'click',
                                                  `link:${link.url}`,
                                                  'custom link',
                                                  {
                                                      source: 'header',
                                                      link: link.url
                                                  }
                                                  )}}>
                                        <h3>{icon}{doubleDecode(linkTitle)}</h3>
                                    </MenuItem>

                                let relativeInternalLink = createRelativeInternalLink(link, customLink,
                                    linkTitle, externalLinkIcon, i, target, savedItemsMatch)

                                return relativeInternalLink ? relativeInternalLink : customLinkHTML

                            } else {
                                return undefined
                            }
                        }
                    } else {
                        return undefined
                    }
                } else {
                    // we need this for code coverage but it makes sense to explicitly have it I guess, otherwise we have
                    // no return path
                    return undefined
                }
            }).filter(m => m !== undefined)
        )
    }

    const homeMessage = he.decode(intl.formatMessage({ id: 'SITE_CONFIG_navLinksHome', defaultMessage: ' ' }))
    const browseMessage = he.decode(intl.formatMessage({ id: 'SITE_CONFIG_navLinksBrowse', defaultMessage: ' ' }))
    const aboutMessage = he.decode(intl.formatMessage({ id: 'SITE_CONFIG_navLinksAbout', defaultMessage: ' ' }))
    const homeLink = (siteConfig.homepage || {}).customURL || '/digital/';
    const isExternal = homeLink.includes('http')

    return(
        <div className="Header-hamburgerHolder">
            <Dropdown id="bg-nested-dropdown-header" pullRight>
                <Dropdown.Toggle noCaret className={'headerIconButton Header-headerButtonPadding'}
                                 aria-label={'Navigation Menu'}
                                 onClick={(e) => {e.stopPropagation(); collectEventAnalytics(tracking, 'navigation',
                                     'click', 'hamburger menu', 'open hamburger menu')}}>
                    {/*eslint-disable-next-line*/}
                    <a className={'headerIcon'}><FontAwesome name='bars' size='2x' /></a>
                </Dropdown.Toggle>
                <Dropdown.Menu className={'Header-headerMenuLinks'} tabIndex={6} id="dropdown-menu-pull-right">
                    {siteConfig.header.navmenu.enableHome ? <MenuItem key='1' eventKey={"1"} href={homeLink}
                              to={homeLink} componentClass={isExternal ? SafeAnchor : Link}
                              onClick={() => {collectEventAnalytics(tracking, 'navigation',
                                  'click', 'home link', 'home link', {source: 'hamburger menu'})}}>
                        <h3>{doubleDecode(homeMessage)}</h3>
                    </MenuItem>: null}

                    {siteConfig.header.navmenu.enableBrowse ? <MenuItem key='2' eventKey={"2"} to={browseUrl(siteConfig)} href={browseHref(siteConfig)}
                              componentClass={Link}
                              onClick={() => {collectEventAnalytics(tracking, 'navigation',
                                  'click', 'browse', 'browse link', {source: 'hamburger menu'})}}>
                        <h3>{doubleDecode(browseMessage)}</h3>
                    </MenuItem> : null}

                    {siteConfig.header.navmenu.enableAbout ? <MenuItem key='3' eventKey={"3"} to={'/digital/about'}
                              href={'/digital/about'} componentClass={Link}
                              onClick={() => {collectEventAnalytics(tracking, 'navigation',
                            'click', 'About Link', 'about link', {source: 'hamburger menu'})}}>
                            <h3>{doubleDecode(aboutMessage)}</h3>
                    </MenuItem> : null}
                    {customLinks(siteConfig.header)}
                    {renderLoginLinks(siteConfig)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default HeaderDropdown
