import {useContext} from 'react'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {useRouteRefresh} from 'hooks/useRouteRefresh'
import {logout as logoutUser} from 'service/AuthenticationService'

export const useLogout = () => {

    const authData = useContext(AuthenticationContext)
    const refreshRoute = useRouteRefresh()

    const logout = async () => {
        await logoutUser()
        authData.refreshAuthenticationData()
        refreshRoute()
    }

    return logout
}
