import {DEFAULT_OPTS, getDefaultHeaders} from './ServiceDefaults'

export const getCustomPage = async (collectionAlias, filename) => {

    const origin = process.env.NODE_ENV === 'production'
        ? window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
        : `https://${process.env.REACT_APP_STATIC_HOST}`

    let endpoint = `${origin}/customizations/global/pages/${filename}.html`

    if (collectionAlias) {
        endpoint = `${origin}/customizations/collection/${collectionAlias}/pages/${filename}.html`
    }

    try {
        const response = await fetch(endpoint, {
            method: 'GET',
            headers: {
                ...getDefaultHeaders()
            },
            ...DEFAULT_OPTS
        })
        const body = await response.text()
        return {body: body, status: response.status}

    } catch (error) {
        return error
    }
}
