const AbsoluteURIREs = [
    // idea from here: http://stackoverflow.com/a/19709846
    // test for tel: sms: mailto: http: https:
    /^[a-z]+:/i
];

const A_URL_HELPER = document.createElement('a');

/**
 * return to absolute URL by link/pathname based on current domain
 *
 * @example
 * toAbsoluteUrl('test') -> http://example/test
 * toAbsoluteUrl('http://google.com') -> http://google.com
 *
 * @export
 * @param {String} link
 * @returns {String}
 */
export function toAbsoluteUrl(link) {
    A_URL_HELPER.href = link;
    return A_URL_HELPER.href;
}

export function isAbsoluteURI(str) {
    return AbsoluteURIREs.some(rx => rx.test(str));
}

export default isAbsoluteURI
