import React, {useEffect} from 'react'
import {useIntl} from 'react-intl'
import cdmClasses from 'utils/CdmClasses'
import {useCdmEvents} from 'events/useCdmEvents'
import {doubleDecodeMessageByKey} from 'utils/Decode'
import {decodeAndTransformText} from 'utils/innerText'
import he from "he"
import './About.scss'

const About = ({setMainClass}) => {

    useCdmEvents(cdmClasses.ABOUT_PAGE)

    const intl = useIntl()
    const aboutTitle = he.decode(intl.formatMessage({ id: 'SITE_CONFIG_aboutPageTitle', defaultMessage: ' ' }))
    const decodedTitle = decodeAndTransformText(aboutTitle)

    useEffect(() => {
        setMainClass('cdm-about-page')
    })

    return (
        <div className={'About-maincontainer shared-box'} data-id="aboutContainer">
            <div className={`About-maincontentAbout shared-contentContainer ${cdmClasses.ABOUT_PAGE}`}>
                <div className={'About-maincontentAboutTitle'}>
                    <h1 data-id="aboutTitle" tabIndex={0}>
                        {decodedTitle}
                    </h1>
                </div>

                <div>
                    <div className='grayDividerLine'></div>
                    <div data-id="aboutBody" tabIndex={0}>
                        {doubleDecodeMessageByKey(intl,'SITE_CONFIG_aboutPageHtml')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
