import React, {useCallback, useContext, useState} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from "react-intl";
import {Link, useHistory} from 'react-router-dom'
import {Modal, Button} from 'react-bootstrap'
import MetadataFields from 'components/MetadataFields'
import FavoritesButton from 'components/FavoritesButton'
import {ItemPagingContext} from 'contexts/ItemPagingContext'
import {SimpleSearchContext} from 'contexts/SimpleSearchContext'
import useAnalytics from 'hooks/useAnalytics'
import {usePageNumber} from 'hooks/usePageNumber'
import {useResultsPerPage} from 'hooks/useResultsPerPage'
import {getFullTextTotals} from 'service/ItemSearchInfo'
import {MAX_SAVED_ITEMS} from 'constants/SavedItems'
import './SearchResultsListCard.scss'
import he from "he";
import * as ReactDOM from "react-dom/server";

const SearchResultsListCard = (props) => {

    const sendAnalytics = useAnalytics()
    const [itemPagingState] = useContext(ItemPagingContext)
    const [pageNumber] = usePageNumber()
    const [simpleSearch] = useContext(SimpleSearchContext)
    const history = useHistory()
    const [resultsPerPage] = useResultsPerPage()
    const intl = useIntl()
    const [show, setShow] = useState(false)
    const {paginatedItems, totalResults} = itemPagingState
    
    const {
        thumbnailEnabled,
        title,
        metadataFields,
        thumbnailUri,
        collectionAlias,
        itemId
    } = props.item

    const messages = {
        savedLimitReached: he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsLimit', defaultMessage: ' '}))
    }

    const { enableSavedItems, query} = props
    let {pageIndex} = props

    if(pageNumber > 1){
        pageIndex += ((pageNumber - 1) * resultsPerPage)
    }

    const url = `/digital/collection/${collectionAlias}/id/${itemId}/rec/${pageIndex + 1}`

    const handleCardClick = useCallback(() => {
        sendAnalytics(
            'search results',
            'click',
            `/digital/collection/${collectionAlias}/id/${itemId}/rec/${pageIndex + 1}`,
            'search result clicked',
            {
                collection_alias: collectionAlias,
                item_id: `${collectionAlias}/id/${itemId}`,
                type: 'list'
            }
        );
    }, [sendAnalytics, collectionAlias, pageIndex, itemId])

    const loadItemSearchResults = useCallback(async (e) => {
        e.preventDefault()
        if (query) {
            handleCardClick()
            let itemSearchInfo = await getFullTextTotals(collectionAlias, itemId, query)
            history.push({
                pathname: `/digital/collection/${collectionAlias}/id/${itemSearchInfo.records[0]
                    ? itemSearchInfo.records[0][0] : itemId}/rec/${pageIndex + 1}`,
                state: {...simpleSearch, itemSearchInfo: itemSearchInfo, shouldScroll: false,
                    searchResults: {paginatedItems: paginatedItems, totalResults: totalResults, url: window.location.pathname}}
            })
        } else {
            handleCardClick()
            history.push({
                pathname: `/digital/collection/${collectionAlias}/id/${itemId}/rec/${pageIndex + 1}`,
                state: {...history.location.state, shouldScroll: false,
                    searchResults: {paginatedItems: paginatedItems, totalResults: totalResults, url: window.location.pathname}}
            })
        }
    }, [history, handleCardClick, paginatedItems, totalResults, pageIndex, simpleSearch, collectionAlias, itemId, query])

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const SAVED_ITEMS_TMX = ReactDOM.renderToString(<b>{messages.savedItems}</b>)
    const formattedMaxMsg = he.decode(intl.formatMessage({
        defaultMessage: `You have reached the maximum number of {MAX_SAVED_ITEMS, number} for {SAVED_ITEMS_TMX}.`,
        id: 'SITE_KEY_MaxSavedItems'
    }, {SAVED_ITEMS_TMX, MAX_SAVED_ITEMS}))

    const removeSavedItemsInstructionsMsg = he.decode(intl.formatMessage({
        defaultMessage: `You cannot save more items until you visit the {SAVED_ITEMS_TMX} `
            + 'page and remove some items.', id: 'SITE_KEY_RemoveSavedInstruction'
    }, {SAVED_ITEMS_TMX}))


    return [
        <Link key={collectionAlias + itemId} to={url}
              onClick={loadItemSearchResults}
              className={`SearchResult-container shared-search-box shared-box row SearchResult cdm-item-card ${
                  enableSavedItems ? 'SearchResult-favoritesListContainer': null}`} tabIndex={0}>

            {thumbnailEnabled
                ? <div className="SearchResult-thumbnailHolder">
                    <img src={'/digital' + thumbnailUri} className="SearchResult-thumbnail" alt={title} />
                </div>
                : null
            }

            <div className="SearchResult-contentHolder">
                <MetadataFields fields={metadataFields} />
            </div>
            {enableSavedItems
                ? <FavoritesButton itemId={itemId.toString()} collectionId={collectionAlias}
                                   saveKey={`${collectionAlias}-${itemId}`} handleShow={handleShow}
                                   cardType='list' />
                : null
            }
        </Link>,
        <Modal className='FavoritesButton-favoritesModal' show={show} key={2}
               onHide={handleClose} autoFocus>
            <Modal.Header closeButton className='FavoritesButton-maxItemsModalHeader'>
                <Modal.Title componentClass='h1'>{messages.savedLimitReached}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='FavoritesButton-modalText'>
                    <span dangerouslySetInnerHTML={{__html: formattedMaxMsg}}/>
                    <br/><br/>
                    <span dangerouslySetInnerHTML={{__html: removeSavedItemsInstructionsMsg}}/>
                </p>
            </Modal.Body>
            <Modal.Footer className='FavoritesButton-modalFooter'>
                <Button className='cdm-btn' bsStyle='primary'
                        onClick={handleClose}>OK</Button>
            </Modal.Footer>
        </Modal>
    ]
}

SearchResultsListCard.propTypes = {
    enableSavedItems: PropTypes.bool,
    item: PropTypes.object.isRequired,
    query: PropTypes.string,
    pageIndex: PropTypes.number.isRequired
}

export default SearchResultsListCard
