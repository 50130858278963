import React, {useCallback} from 'react'
import {useIntl} from 'react-intl'
import {Action} from 'hooks/useAdvancedSearch'
import './DateSearch.scss'
import he from "he"

const DateSearch = (props) => {

    const {disabled, query, dispatch} = props
    const intl = useIntl()

    const modes = {
        on: he.decode(intl.formatMessage({id: 'SITE_KEY_onDate', defaultMessage: ' '})),
        after: he.decode(intl.formatMessage({id: 'SITE_KEY_after', defaultMessage: ' '})),
        before: he.decode(intl.formatMessage({id: 'SITE_KEY_before', defaultMessage: ' '})),
        between: he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_between', defaultMessage: ' '}))
    }

    const handleUpdateMode = useCallback((e) => {
        dispatch({ type: Action.UPDATE_DATE_QUERY, payload: { fieldName: 'mode', value: e.target.value }})
    }, [dispatch])

    const handleUpdateSearchTerm = useCallback((e) => {
        dispatch({ type: Action.UPDATE_DATE_QUERY, payload: { fieldName: 'searchTerm', value: e.target.value }})
    }, [dispatch])

    const handleUpdateSearchTerm2 = useCallback((e) => {
        dispatch({ type: Action.UPDATE_DATE_QUERY, payload: { fieldName: 'searchTerm2', value: e.target.value }})
    }, [dispatch])

    const canRenderSecondDateField = query.mode === 'between'

    const renderSecondDateField = () => {
        return (
            <div className="DateSearch-input">
                <label>
                    <span>
                        {he.decode(intl.formatMessage({id: 'SITE_KEY_fromDateToDate', defaultMessage: 'to'}))}
                    </span>
                    <input
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_enter_date_2', defaultMessage: ' '}))}
                        type="text"
                        disabled={disabled}
                        className="form-control"
                        value={query.searchTerm2}
                        onChange={handleUpdateSearchTerm2}
                        data-id="secondDateField"
                    />
                </label>
            </div>
        )
    }

    return (
        <div className="well well-sm DateSearch-container">

            <h2>{he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_enter_date', defaultMessage: ' '}))}:</h2>

            <div className="DateSearch-flexContainer">
                <div className="DateSearch-input">
                    <select
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_date_modifier', defaultMessage: ' '}))}
                        className="form-control"
                        disabled={disabled}
                        value={query.mode}
                        onChange={handleUpdateMode}
                        data-id="dateModeField"
                    >
                        {Object.keys(modes).map((m, i) => {
                            return <option key={i} value={m}>{modes[m]}</option>
                        })}
                    </select>
                </div>

                <div className="DateSearch-input">
                    <input
                        aria-label={he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_enter_date', defaultMessage: ' '}))}
                        type="text"
                        disabled={disabled}
                        className="form-control"
                        value={query.searchTerm}
                        onChange={handleUpdateSearchTerm}
                        data-id="firstDateField"
                    />
                </div>

                {canRenderSecondDateField ? renderSecondDateField() : null}
            </div>

            <div className="DateSearch-footer">
                <div />
                <div>
                    <span>{he.decode(intl.formatMessage({id: 'SITE_cdm_advanced_search_KEY_date_format_help_text', defaultMessage: ' '}))}</span>
                </div>
                {canRenderSecondDateField ? <div /> : null}
            </div>

            <div className="clearfix"></div>
        </div>
    )
}

export default DateSearch
