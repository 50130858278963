import React, {useEffect, useCallback, useContext} from 'react'
import {useIntl} from 'react-intl'
import {useCardView} from 'hooks/useCardView'
import useAnalytics from 'hooks/useAnalytics'
import {SavedItemsAction} from 'hooks/useSavedItems'
import cdmClasses from 'utils/CdmClasses'
import {Button, Alert} from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import {BrowserRouter, Link} from 'react-router-dom'
import {GRID_VIEW} from 'constants/GridListView'
import {SavedItemsContext} from 'contexts/SavedItemsContext'
import SavedItemsCreateLinkButton from 'components/SavedItemsCreateLinkButton'
import SavedItemsCreateLinkModal from 'components/SavedItemsCreateLinkModal'
import SearchResultsDisplaySelector from 'components/SearchResultsDisplaySelector'
import SavedItemsExportButton from 'components/SavedItemsExportButton'
import SelectableItemListCard from 'components/SelectableItemListCard'
import SelectableItemGridCard from 'components/SelectableItemGridCard'
import SavedItemsRemoveModal from 'components/SavedItemsRemoveModal'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import CardViewContext from "hooks/useCardView"
import {useCdmEvents} from 'events/useCdmEvents'
import doubleDecode from 'utils/doubleDecode'
import {fetchSavedItems} from 'service/SavedItemsService'
import {SAVED_ITEMS} from 'constants/SavedItems'
import './SavedItems.scss'
import he from "he";
import * as ReactDOM from "react-dom/server";

const SavedItems = (props) => {

    const intl = useIntl()
    const authentication = useContext(AuthenticationContext)
    const sendAnalytics = useAnalytics()
    const [cardView, cardViewDispatch] = useCardView()
    const [savedItems, dispatch] = useContext(SavedItemsContext)
    const authData = useContext(AuthenticationContext)

    useCdmEvents(cdmClasses.SAVED_ITEMS_PAGE, savedItems && !savedItems.loading)
    useEffect(() => {
        props.setMainClass('cdm-saved-items-page')
    })

    useEffect(() => {
        const getSavedItems = async () => {
            const parsedSavedItemIds = JSON.parse(localStorage.getItem(SAVED_ITEMS)) || []
            dispatch({type: SavedItemsAction.SET_LOADING, payload: true})
            const items = await fetchSavedItems(parsedSavedItemIds)
            dispatch({
                type: SavedItemsAction.SET_SAVED_ITEMS, payload: {
                    items: items.items.reverse(),
                    authname: authentication.name
                }
            })
        }
        getSavedItems()
    }, [authentication.name, dispatch])

    useEffect(() => {
        // Deselect everything when the user logs in/out
        dispatch({
            type: SavedItemsAction.SET_SAVED_ITEMS_ALL_SELECTED, payload: {
                allSelected: false,
                selectedText: he.decode(intl.formatMessage({id: 'SITE_KEY_SelectAllSavedItems', defaultMessage: ' '}))
            }
        })
    }, [authData.name, dispatch, intl])

    const toggleSelectAll = useCallback((e) => {
        let selectedText = ''
        if (e.target.checked) {
            selectedText = he.decode(intl.formatMessage({id: 'SITE_KEY_DeselectAllSavedItems', defaultMessage: ' '}))
            sendAnalytics('saved items', 'click', 'deselect all', 'saved items: deselect all', {type: 'deselect'})
        } else {
            selectedText = he.decode(intl.formatMessage({id: 'SITE_KEY_SelectAllSavedItems', defaultMessage: ' '}))
            sendAnalytics('saved items', 'click', 'select all', 'saved items: select all', {type: 'select'})
        }

        dispatch({
            type: SavedItemsAction.SET_SAVED_ITEMS_ALL_SELECTED, payload: {
                allSelected: e.target.checked,
                selectedText
            }
        })
    }, [dispatch, sendAnalytics, intl])

    const onRemoveClick = useCallback(() => {
        sendAnalytics('saved items', 'click', 'open remove items dialog', 'saved items: open remove items')
        dispatch({type: SavedItemsAction.SHOW_REMOVE_MODAL, payload: true})
    }, [sendAnalytics, dispatch])

    const onRemoveCancel = useCallback(() => {
        sendAnalytics('saved items', 'click', 'remove items dialog cancel', 'saved items: cancel remove items')
        dispatch({type: SavedItemsAction.SHOW_REMOVE_MODAL, payload: false})
    }, [sendAnalytics, dispatch])

    const onRemoveOk = useCallback(() => {
        sendAnalytics('saved items', 'click', 'remove items dialog confirm', 'saved items: confirm remove items')
        dispatch({type: SavedItemsAction.REMOVE_SAVED_ITEMS, payload: {authname: authData.name}})
    }, [sendAnalytics, dispatch, authData.name])

    const loginLinkClick = useCallback(() => {
        sendAnalytics('saved items', 'click', 'login', 'log in link')
    }, [sendAnalytics])

    const renderResults = () => {
        if (!savedItems.loading) {

            return savedItems.items && savedItems.items.map((item, index) => {
                return cardView === GRID_VIEW
                    ? <SelectableItemGridCard
                        key={index}
                        index={index}
                        selectEnabled
                        selected={item.selected}
                        dispatch={dispatch}
                        thumbnailUri={item.thumbnailUri}
                        thumbnailEnabled={item.thumbnailEnabled}
                        title={item.title}
                        metadataFields={item.metadata}
                        collectionAlias={item.alias}
                        itemId={item.id}/>
                    : <SelectableItemListCard
                        key={index}
                        index={index}
                        selectEnabled
                        selected={item.selected}
                        dispatch={dispatch}
                        thumbnailUri={item.thumbnailUri}
                        thumbnailEnabled={item.thumbnailEnabled}
                        title={item.title}
                        metadataFields={[item.title, ...item.metadata]}
                        collectionAlias={item.alias}
                        itemId={item.id}/>
            })
        }
    }

    const isDisabled = () => savedItems.buttonState === 'disabled'
        || (!savedItems.loading && savedItems.items && savedItems.items.filter(item => item.selected).length === 0)

    const renderViewSelectorAndItemCount = (className) => (
        <div className={className}>
            <SearchResultsDisplaySelector/>

            <span className="SavedItems-savedItems">
                <h3 className="SavedItems-savedItemsCount">
                        {savedItems.items
                            ? he.decode(intl.formatMessage({
                                defaultMessage: ' ',
                                id: 'SITE_KEY_NumItemsLabel'
                            }, {num_of_items: savedItems.items.length}))
                            : null
                        }
                </h3>
            </span>
        </div>
    )

    const renderDialogs = () => {
        const numberOfSelectedSavedItems = savedItems.items.filter((item) => {
            return item.selected === true
        }).length

        const removeSavedItemsModal = <SavedItemsRemoveModal
            show={savedItems.showRemoveModal}
            numberOfSelectedSavedItems={numberOfSelectedSavedItems}
            onCancel={onRemoveCancel}
            onRemove={onRemoveOk}/>

        return <div>
            {removeSavedItemsModal}
        </div>
    }

    const renderButtons = (position) => {

        return (
            <CardViewContext.Provider value={[cardView, cardViewDispatch]}>
                <div className="SavedItems-buttonContainer row">

                    {/* For mobile view, we need the grid/list view selector rendered ABOVE the buttons */}
                    {renderViewSelectorAndItemCount(`SavedItems-displaySelectorAndResultsMobile${position}`)}

                    <span className="SavedItems-selectionInput checkbox">
                        <label>
                            <input type="checkbox" label={savedItems.selectedText} onChange={toggleSelectAll}
                                   checked={savedItems.allSelected}
                                   aria-label={savedItems.selectedText}/>
                            {savedItems.selectedText}
                        </label>
                    </span>
                    <div className="SavedItems-buttonGroup">
                        <Button bsStyle="primary" className="cdm-btn" onClick={onRemoveClick}
                                data-id="removeSavedItemsButton" disabled={isDisabled()}
                                aria-label={he.decode(intl.formatMessage({
                                    id: 'SITE_KEY_RemoveButton',
                                    defaultMessage: ' '
                                }))}>
                            <FontAwesome className="SavedItems-desktopIcon SavedItems-trash fa-lg" name="trash"/>
                            <FontAwesome className="SavedItems-mobileIcon SavedItems-trash fa-2x" name="trash"/>
                            <span className="SavedItems-buttonText">
                                {he.decode(intl.formatMessage({id: 'SITE_KEY_RemoveButton', defaultMessage: ' '}))}
                            </span>
                        </Button>

                        {savedItems ? <SavedItemsCreateLinkButton
                            disabled={isDisabled()}
                            renderCreateLink={(createLinkState,
                                               numberOfSelectedSavedItems,
                                               onCreateLinkCancel,
                                               onCreateLinkOk,
                                               onRecaptchaSuccess,
                                               dispatch) => {
                                return <SavedItemsCreateLinkModal
                                    key={1}
                                    show={createLinkState.showCreateLinkModal}
                                    numberOfSelectedSavedItems={numberOfSelectedSavedItems}
                                    onCancel={onCreateLinkCancel}
                                    onCreateLink={onCreateLinkOk}
                                    onRecaptchaSuccess={onRecaptchaSuccess}
                                    enableCreateLinkButton={createLinkState.recaptchaSuccess}
                                    isCreatingLink={createLinkState.isCreatingLink}
                                    dispatch={dispatch}
                                />
                            }}/> : undefined}
                        <SavedItemsExportButton/>
                    </div>

                    {/* For desktop view, we need the grid/list view selector rendered to the right of the buttons */}
                    {position === 'Top'
                        ? renderViewSelectorAndItemCount(`SavedItems-displaySelectorAndResultsDesktop${position}`)
                        : null}
                </div>
            </CardViewContext.Provider>
        )
    }

    const renderNoItems = () => (
        <div className="SavedItems-noSavedItems">{doubleDecode(intl.formatMessage({
            id: 'SITE_KEY_NoSavedItems',
            defaultMessage: ' '
        }))}</div>
    )

    const loginOrCreateAccountTmx = ReactDOM.renderToString(
        <BrowserRouter>
            <Link className="SavedItems-url"
                  to="/digital/login?targetUrl=/saved" role="link"
                  title={he.decode(intl.formatMessage({
                      id: 'SITE_KEY_loginOrCreateAccount',
                      defaultMessage: ' '
                  }))}
                  onClick={loginLinkClick} target=''>
                {he.decode(intl.formatMessage({
                    id: 'SITE_KEY_loginOrCreateAccount',
                    defaultMessage: ' '
                }))}
            </Link>
        </BrowserRouter>)
    const saveItemsText = he.decode(intl.formatMessage({
        defaultMessage: ' ',
        id: 'SITE_KEY_SavedItemsSessionWarning'
    }, {loginOrCreateAccountTmx}))
    const renderBody = () => (
        <div>
            {renderDialogs()}

            {authData.name
                ? <></>
                : <div className="SavedItems-notification" dangerouslySetInnerHTML={{__html: saveItemsText}}/>
            }
            <div className="SavedItems-notification">
                {doubleDecode(intl.formatMessage({id: 'SITE_KEY_keepRecordOfSavedItems', defaultMessage: ' '}))}
            </div>

            {renderButtons('Top')}
            {renderResults()}
            {renderButtons('Bottom')}
        </div>
    )

    return (
        <div className="SavedItems-container shared-box">
            <SavedItemsContext.Provider value={savedItems}>
                <h1 className="SavedItems-pageTitle" tabIndex={0}
                    title={he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsPageTitle', defaultMessage: ' '}))}>
                    <FontAwesome className="SavedItems-faTitle star" name='star'/>
                    {he.decode(intl.formatMessage({id: 'SITE_KEY_SavedItemsPageTitle', defaultMessage: ' '}))}
                </h1>
                <div className="grayDividerLine SavedItems-divider"></div>

                {savedItems.loading
                    ? <Alert className="SavedItems-alert" bsStyle="info">
                        <FontAwesome name="info-circle"/>
                        <span
                            data-id="FetchingNotification"
                            tabIndex={0}
                            dangerouslySetInnerHTML={{
                                __html: he.decode(intl.formatMessage({
                                    id: 'SITE_KEY_loadingContent',
                                    defaultMessage: ' '
                                }))
                            }}
                        />
                        <FontAwesome className="SavedItems-spinner" name="refresh" aria-hidden="true"/>
                    </Alert>
                    : <></>
                }

                {!savedItems.loading
                    ? savedItems.savedItemIds === null || savedItems.savedItemIds === undefined ||
                    savedItems.savedItemIds.length === 0 || savedItems.items.length === 0
                        ? renderNoItems()
                        : renderBody()
                    : <></>
                }
            </SavedItemsContext.Provider>
        </div>
    )
}

export default SavedItems
