import React, {memo, useCallback, useContext} from 'react'
import {Link, useParams, useHistory, useLocation} from 'react-router-dom'
import { collectEventAnalytics, toAbsoluteUrl } from 'metrics/Analytics'
import {useIntl} from 'react-intl'
import {useTracking} from 'react-tracking'
import isAbsoluteURI from 'utils/isAbsoluteURI'
import FontAwesome from 'react-fontawesome'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {LocaleContext} from 'contexts/LocaleContext'
import useAnalytics from 'hooks/useAnalytics'
import {useLogout} from 'hooks/useLogout'
import doubleDecode from 'utils/doubleDecode'
import {parsePathForCollectionIds, parseUrlForCollectionIds} from 'utils/UrlParameters'
import './FooterNavigationLinks.scss'
import he from "he";

const FooterNavigationLinks = memo(function FooterNavigationLinks() {

    const intl = useIntl()
    const tracking = useTracking()
    const { collectionId } = useParams()
    const sendAnalytics = useAnalytics()
    const logout = useLogout()
    const history = useHistory()
    const location = useLocation()
    const siteConfig = useContext(SiteLayoutContext)
    const authData = useContext(AuthenticationContext)
    const navmenu = siteConfig.header.navmenu

    const handleLogoutClick = useCallback(() => {
        sendAnalytics('navigation', 'click', 'Footer Log out Link', 'log out link', {source: 'footer'})
        logout()
    }, [logout, sendAnalytics])

    const sendEventAnalytics = useCallback((tracking, category, action, label, name, mapOfParams) => {
        collectEventAnalytics(tracking, category, action, label, name, mapOfParams)
    }, [])

    const homeLinkMessage = doubleDecode(intl.formatMessage({ id: 'SITE_CONFIG_navLinksHome', defaultMessage: ' ' }))
    const aboutLinkMessage = doubleDecode(intl.formatMessage({ id: 'SITE_CONFIG_navLinksAbout', defaultMessage: ' ' }))
    const browseLinkMessage = doubleDecode(intl.formatMessage({ id: 'SITE_CONFIG_navLinksBrowse', defaultMessage: ' ' }))

    // <Link to='/digital'> props changes are triggered after the route changes in the main content area, but
    // we don't want a re-render.  useCallback creates a memoized function to help us prevent unnecessary renders
    const customHomeUrlEnabled = siteConfig && siteConfig.homepage && siteConfig.homepage.customUrlEnabled
        && siteConfig.homepage.customURL
    const homeUrl = customHomeUrlEnabled ? siteConfig.homepage.customURL : '/digital'
    const renderHomeLink = customHomeUrlEnabled
        ? <li><a href={homeUrl} aria-label={homeLinkMessage} title={homeLinkMessage} //Added to match old UI
            onClick={() => sendEventAnalytics(tracking, 'navigation', 'click', 'Footer Home link', 'home link', {source: 'footer'})}>
        {homeLinkMessage}</a></li>
        : <li><Link to={homeUrl} aria-label={homeLinkMessage} title={homeLinkMessage} //Added to match old UI
            onClick={() => sendEventAnalytics(tracking, 'navigation', 'click', 'Footer Home link', 'home link', {source: 'footer'})}>
        {homeLinkMessage}</Link></li>

    const renderAboutLink = <li>
        <Link to='/digital/about'
              aria-label={aboutLinkMessage}
              title={aboutLinkMessage}
              onClick={() => sendEventAnalytics(tracking, 'navigation', 'click', 'Footer About link', 'about link', {source: 'footer'})}>
            {aboutLinkMessage}
        </Link>
    </li>

    const renderBrowseLink = () => {
        let browseUrl = '/digital/search'
        let collectionIdsScope = []
        if (history.location && history.location.state && history.location.state.collectionScope) {
            collectionIdsScope = parsePathForCollectionIds(history.location.state.collectionScope)
        }

        if (collectionIdsScope && collectionIdsScope.length > 0) {
            const pathname = collectionIdsScope.length === 1 ? `/digital/collection/${collectionIdsScope[0]}/search` : `/digital/search/collection/${collectionIdsScope.join('!')}`
            browseUrl = {pathname, state: { ...location.state, collectionScope: location.pathname}}
        } else {
            collectionIdsScope = parseUrlForCollectionIds(location)
            if (collectionIdsScope && collectionIdsScope.length > 0) {
                const pathname = collectionIdsScope.length === 1 ? `/digital/collection/${collectionIdsScope[0]}/search` : `/digital/search/collection/${collectionIdsScope.join('!')}`
                browseUrl = {pathname, state: { ...location.state, collectionScope: location.pathname}}
            } else {
                browseUrl = collectionId ? `digital/collection/${collectionId}/search` : '/digital/search'
            }
        }

        return (
            <li>
                <Link to={browseUrl}
                      aria-label={browseLinkMessage}
                      title={browseLinkMessage}
                      onClick={() => sendEventAnalytics(tracking, 'navigation', 'click', 'Footer Browse link', 'browse link', {source: 'footer'})}>
                    {browseLinkMessage}
                </Link>
            </li>
        )
    }

    const determineLoginOrLogout = () => {
        let link = null

        if (authData.name) {
            const logoutMessage = he.decode(intl.formatMessage({ id: 'SITE_KEY_logout', defaultMessage: ' ' }))

            link =
                <Link to="/digital" aria-label={logoutMessage} title={logoutMessage}
                    onClick={handleLogoutClick}>
                    {logoutMessage}
                </Link>
        } else {
            const loginMessage = he.decode(intl.formatMessage({ id: 'SITE_KEY_login', defaultMessage: ' ' }))
            link =
                <Link to={{pathname: '/digital/login', state: { ...location.state, prevPath: window.location.pathname}}}
                    aria-label={loginMessage}
                    title={loginMessage}
                    onClick={() => sendEventAnalytics(tracking, 'navigation', 'click', 'Footer Log in link', 'log in link', {source: 'footer'})}>
                    {loginMessage}
                </Link>
        }

        return link
    }

    const renderLoginLogoutLink = <li>{determineLoginOrLogout(authData)}</li>

    let externalLinkIcon =
        <FontAwesome role="presentation" name='external-link' className="FooterNavigationLinks-footerExternalLinkIcon">
            <span className="FooterNavigationLinks-hideText">Opens in External Window</span>
        </FontAwesome>

    const renderCustomNavLinks = () => {
        return (
            <LocaleContext.Consumer>
                {localeData =>
                    navmenu.links.map(function (link, i) {
                        let result
                        const linkTitle = localeData.messages[link.title]
                        let target = '_blank'
                        if (linkTitle) {
                            if (isAbsoluteURI(link.url)) {
                                // per CDMRUI-1861 we need to also check for mailto links
                                if (link.url.startsWith('mailto:')) {
                                    target = ''
                                } else if (link.openInWindow === false) {
                                    target = '_self'
                                    externalLinkIcon = ''
                                }

                                result =
                                    <li key={i}>
                                        <a title={doubleDecode(linkTitle)} aria-label={doubleDecode(linkTitle)} href={link.url} target={target}
                                           onClick={collectEventAnalytics.bind(this, tracking,
                                               'navigation',
                                               'click',
                                               `Footer link:${toAbsoluteUrl(link.url)}`,
                                               'custom link',
                                               {
                                                   link: `${toAbsoluteUrl(link.url)}`,
                                                   source: 'footer'
                                               }
                                           )}>
                                            {doubleDecode(linkTitle)}
                                            {externalLinkIcon}
                                        </a>
                                    </li>
                            } else {

                                // per CDMRUI-1861 we need to also check for mailto links
                                if (link.url.startsWith('mailto:')) {
                                    target = ''
                                } else if (link.openInWindow === false) {
                                    target = ''
                                    externalLinkIcon = ''
                                }

                                let customLink = link.url.startsWith('/') ? link.url : `/${link.url}`

                                if (customLink !== '') {
                                    result =
                                        <li key={i}>
                                            <Link to={customLink} role="link" title={doubleDecode(linkTitle)} target={target}
                                                  onClick={collectEventAnalytics.bind(this, tracking,
                                                      'navigation',
                                                      'click',
                                                      `Footer link:${toAbsoluteUrl(customLink)}`,
                                                      'custom link',
                                                      {
                                                          link: `${toAbsoluteUrl(customLink)}`,
                                                          source: 'footer'
                                                      }
                                                  )}>
                                                {doubleDecode(linkTitle)}
                                                {externalLinkIcon}
                                            </Link>
                                        </li>

                                } else {
                                    result = undefined
                                }
                            }
                        }

                        return result
                    }).filter(m => m !== undefined)
                }
            </LocaleContext.Consumer>
        )
    }

    const renderLinks = <>
        {navmenu.enableHome ? renderHomeLink : null}
        {navmenu.enableBrowse ? renderBrowseLink() : null}
        {navmenu.enableAbout ? renderAboutLink : null}
        {renderCustomNavLinks()}
        {navmenu.enableLogin ? renderLoginLogoutLink : null}
    </>

    return <ul className="FooterNavigationLinks-footerLinksHolder">{renderLinks}</ul>
})

export default FooterNavigationLinks
