import React from 'react'
import {useTracking} from "react-tracking"
import {collectEventAnalytics} from "metrics/Analytics"

/**
 * Placeholder component for audio items.
 */
export const ItemAudio = (props) => {

    const tracking = useTracking()
    //todo: analytics not working. They don't work on old UI. Find workaround
    const trackingPath = `/digital/collection/${props.itemInfo.collectionAlias}/id/${props.itemInfo.itemId}`

    return (
        <audio src={props.streamUri} controls
            onPlay={() => {
                collectEventAnalytics(
                    tracking,
                    'single item',
                    'click',
                    `play: ${trackingPath}`,
                    'play audio',
                    {
                        collection_alias: props.itemInfo.collectionAlias,
                        item_id: `${props.itemInfo.collectionAlias}/id/${props.itemInfo.itemId}`
                    }
                );
            }}></audio>
    )
}

export default ItemAudio
