import React, {useCallback, useContext, useEffect, useRef, useState} from 'react'
import {parseUrl} from 'query-string'
import {Alert, Button} from 'react-bootstrap'
import {Helmet} from 'react-helmet'
import {useIntl} from 'react-intl'
import {useHistory, useLocation} from 'react-router-dom'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import {login} from 'service/AuthenticationService'
import {useCdmEvents} from 'events/useCdmEvents'
import cdmClasses from 'utils/CdmClasses'
import {cerberusDomain} from 'utils/env'
import './Login.scss'
import he from "he";

const Login = (props) => {

    useCdmEvents(cdmClasses.LOGIN_PAGE)
    useEffect(() => {
        props.setMainClass('cdm-login-page')
    })

    const intl = useIntl()
    const authData = useContext(AuthenticationContext)
    const history = useHistory()
    const location = useLocation()

    const [authenticated, setAuthenticated] = useState(false)
    const [clearAlert, setClearAlert] = useState(true)

    let username = useRef()
    let password = useRef()

    const [title, usernameLabel, passwordLabel, loginLabel, cancelLabel, authenticationFailedError, forgotPassword,
        createAccount, afterAccountCreation] = [
        he.decode(intl.formatMessage({id: 'SITE_KEY_loginTitle', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_username', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_password', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_login', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_cancel', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_error_KEY_authentication_failed', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_forgotPassword', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_createAccount', defaultMessage: ' '})),
        he.decode(intl.formatMessage({id: 'SITE_KEY_afterAccountCreation', defaultMessage: ' '})),
    ]

    const goBack = useCallback(() => {
        history.goBack()
    }, [history])

    const submitForm = useCallback(async (e) => {
        e.preventDefault()
        setClearAlert(true)

        const formData = new FormData()
        formData.append('targetUrl', location.state && location.state.prevPath
            ? location.state.prevPath
            : parseUrl(location.search).query.targetUrl)
        formData.append('username', username.current.value)
        formData.append('password', password.current.value)

        const response = await login(formData)

        if (response && response.status === 200) {
            if (response.url && response.url.includes('error')) {
                setAuthenticated(false)
            } else {
                setAuthenticated(true)
                authData.refreshAuthenticationData()
                goBack()
            }
        } else {
            setClearAlert(false)
        }
    }, [authData, goBack, location.search, location.state, username, password])

    return (

        <div className="LoginView-maincontainer shared-box">
            <div className="LoginView-maincontentLogin shared-contentContainer">

                <Helmet title={title}/>
                <form className="form-horizontal" method="POST" action="">
                    <h1>{loginLabel}</h1>

                    {!clearAlert && authenticated === false
                        ? <div className="form-group">
                            <Alert bsStyle="danger" className="col-sm-12">
                                {authenticationFailedError}
                            </Alert>
                        </div>
                        : undefined}
                    <div className="form-group">
                        <label htmlFor="login-username-input"
                               className="col-xs-3 col-sm-3 control-label LoginView-loginFormControlLabel">
                            {usernameLabel}</label>
                        <div className="col-xs-9 col-sm-9">
                            <input type="text" className="form-control" id="login-username-input" tabIndex={0}
                                   name="username" ref={username}
                                   autoFocus="autoFocus"/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="login-pwd-input"
                               className="col-xs-3 col-sm-3 control-label LoginView-loginFormControlLabel">
                            {passwordLabel}
                        </label>
                        <div className="col-xs-9 col-sm-9">
                            <input type="password" className="form-control" id="login-pwd-input" tabIndex={0}
                                   name="password" ref={password}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-xs-12 col-sm-12">
                            <Button type="submit" name="login"
                                    bsStyle={'primary'}
                                    className="btn btn-primary cdm-btn btn-block"
                                    onClick={submitForm}>{loginLabel}</Button>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-xs-12 col-sm-12">
                            <Button type="button" name="cancel"
                                    onClick={goBack}
                                    className="btn-block cdm-btn">{cancelLabel}</Button>
                        </div>
                    </div>
                    <div tabIndex={0} className="LoginView-forgotPassword">
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href={`${cerberusDomain}/forgot`} tabIndex={0}>
                            {forgotPassword}
                        </a>
                    </div>
                    <div tabIndex={0} className="LoginView-createAccount">
                        <a target="_blank"
                           rel="noopener noreferrer"
                           href={`${cerberusDomain}/create`} tabIndex={0}>
                            {createAccount}
                        </a>
                    </div>
                    <div tabIndex={0}>
                        <div className="LoginView-registration">
                            {afterAccountCreation}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login
