export let googleAnalytics3 = false
export let googleAnalytics4 = false

export const setGoogleAnalytics3 = (enable) => {
    googleAnalytics3 = enable;
}

export const setGoogleAnalytics4 = (enable) => {
    googleAnalytics4 = enable;
}

export const initMetrics = (siteConfiguration) => {

    window.googleAnalyticsEnabled = siteConfiguration.analytics.googleAnalyticsEnabled

    // Google Analytics Initialization
    if (window.googleAnalyticsEnabled) {
        const googleAnalyticsTrackerIds = siteConfiguration.analytics.googleAnalyticsTrackerIds

        if (googleAnalyticsTrackerIds !== null && googleAnalyticsTrackerIds !== undefined && googleAnalyticsTrackerIds.length > 0) {
            const googleAnalyticsTrackerIdArray = googleAnalyticsTrackerIds[0].split(',')
            googleAnalyticsTrackerIdArray.forEach((trackerId) => {
                trackerId = trackerId.trim();
                if (trackerId.startsWith("G-")) {
                    googleAnalytics4 = true;
                    window.gtag('config', trackerId);
                } else if (trackerId.startsWith("UA-")) {
                    googleAnalytics3 = true;
                    window.ga('create', trackerId, 'auto')
                }
            })
        }
    }

    // Initialize other supported vendors here...
}

export const sendMetrics = (data) => {

    // Google Analytics
    if (window.googleAnalyticsEnabled) {
        // Set the basic GA3/GA4 data object
        const basicGoogleAnalyticsData = {
            'hitType': data.hitType,
            'eventAction': data.eventAction,
            'eventCategory': data.eventCategory,
            'eventLabel': data.eventLabel,
        };
        if (googleAnalytics3) {
            window.ga('send', basicGoogleAnalyticsData)
        }

        if (googleAnalytics4) {
            var gtagObject = basicGoogleAnalyticsData;

            if (data.params) {
                for (var key in data.params){
                    if (data.params.hasOwnProperty(key)) {
                        gtagObject[key] = data.params[key];
                    }
                }
            }

            let eventName = data.eventName;
            if (!eventName) {
                eventName =  `${data.eventCategory}_${data.eventLabel}`;
            }
            if (data.hitType === "event") {
                window.gtag(
                    'event',
                    eventName,
                    gtagObject
                );
            }
        }
    }

}
