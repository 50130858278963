import React, {useCallback, useContext} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useIntl, defineMessages} from 'react-intl'
import {SavedItemsContext} from 'contexts/SavedItemsContext'
import {SavedItemsAction} from 'hooks/useSavedItems'
import './SavedItemsMaxExceededModal.scss'
import he from "he";
import * as ReactDOM from "react-dom/server";

const messages = defineMessages({
    title: {
        id: 'SITE_KEY_SavedItemsLimit', defaultMessage: ' '
    },
    savedItemsTitle: {
        id: 'SITE_KEY_SavedItemsPageTitle', defaultMessage: ' '
    },
    confirmButton: {
        id: 'SITE_KEY_ok', defaultMessage: ' '
    }
})

const SavedItemsMaxExceededModal = () => {

    const [savedItemsData, savedItemsDispatch] = useContext(SavedItemsContext)
    const intl = useIntl()

    const handleMaxItemsConfirm = useCallback(() => {
        savedItemsDispatch({type: SavedItemsAction.SET_SHOW_MAX_ITEMS_EXCEEDED_DIALOG, payload: false})
    }, [savedItemsDispatch])

    const MAX_SAVED_ITEMS = ReactDOM.renderToString(<b>200</b>)
    const SAVED_ITEMS_TMX = ReactDOM.renderToString(<b>{he.decode(intl.formatMessage(messages.savedItemsTitle))}</b>)
    const maxSavedItems = he.decode(intl.formatMessage({
        id: 'SITE_KEY_MaxSavedItems',
        defaultMessage: ' '
    }, {MAX_SAVED_ITEMS, SAVED_ITEMS_TMX}))
    const removedSaveInstruction = he.decode(intl.formatMessage({
        defaultMessage: ' ',
        id: 'SITE_KEY_SavedItemsRemoveSavedInstruction'
    }, {SAVED_ITEMS_TMX}))

    return <Modal show={savedItemsData.showMaxItemsExceededDialog} className="SavedItemsMaxExceededModal-container"
                  size='lg' aria-labelledby={he.decode(intl.formatMessage(messages.title))}
                  onHide={handleMaxItemsConfirm} autoFocus>
        <Modal.Header closeButton>
            <Modal.Title componentClass='h1'>{he.decode(intl.formatMessage(messages.title))}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p dangerouslySetInnerHTML={{__html: maxSavedItems}}/>
            <br/>
            <p dangerouslySetInnerHTML={{__html: removedSaveInstruction}}/>
        </Modal.Body>
        <Modal.Footer>
            <Button className="cdm-btn" bsStyle='primary' onClick={handleMaxItemsConfirm}>
                {he.decode(intl.formatMessage(messages.confirmButton))}
            </Button>
        </Modal.Footer>
    </Modal>
}

export default SavedItemsMaxExceededModal
