import React from 'react'

/**
 * Locale context providing i18n messages to the entire site and ability to change the language on the fly
 *
 * @type {React.Context<any>}
 *
 * @typedef {object} localeData
 *   @property {boolean} isLoading - Determines if the locale data is loading
 *   @property {object} messages - i18n messages from the server
 *   @property {function} changeLanguage - callback function to invoke whenever the user changes language
 *     @param {string} localeCode - The locale of the new language being selected
 *
 */
export const LocaleContext = React.createContext(/* localeData */)
