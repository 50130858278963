import React, {memo, useContext, useCallback} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useHistory} from 'react-router-dom'
import {SimpleSearchContext} from 'contexts/SimpleSearchContext'
import ResultsPerPageContext from 'hooks/useResultsPerPage'
import PageNumberContext from 'hooks/usePageNumber'
import useAnalytics from 'hooks/useAnalytics'
import {removePageFromUrl} from 'utils/UrlParameters'
import './SearchResultsPerPageSelector.scss'
import he from 'he'

const SearchResultsPerPageSelector = memo(({idPrefix}) => {

    const values = [10, 20, 50, 100, 200]
    const intl = useIntl()
    const location = useLocation()
    const history = useHistory()
    const sendAnalytics = useAnalytics()

    const [ resultsPerPage, setResultsPerPage ] = useContext(ResultsPerPageContext)
    const [ simpleSearch, setSimpleSearch ] = useContext(SimpleSearchContext)
    const [, setPageNumber] = useContext(PageNumberContext)

    let options = values.map((value, key) => {
        return <option key={key} value={value}>{value}</option>
    });

    const onChange = useCallback((e) => {
        setPageNumber(null) // Mimic cdm-responsive behavior by resetting pagination to default
        setResultsPerPage(e.target.value)
        setSimpleSearch({...simpleSearch, resultsPerPage: e.target.value})
        sendAnalytics('search results', 'click', `number of results changed to: ${e.target.value}`, 'search results per page changed', {count: `${e.target.value}`})
        history.push(removePageFromUrl(location))
    }, [sendAnalytics, setPageNumber, setResultsPerPage, setSimpleSearch, simpleSearch, history, location])

    return (
        <div className="ResultsPerPageSelector-resultsPerPageSelectorFlexContainer">
            <h3 className="ResultsPerPageSelector-resultsPerPageSelectorLabel" id={`${idPrefix}ResultsPerPageSelectorLabel`}>
                {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_results_per_page'}))}:
            </h3>
            <select id={`${idPrefix}ResultsPerPageSelector`}
                    aria-labelledby={`${idPrefix}ResultsPerPageSelectorLabel`}
                    className="ResultsPerPageSelector-resultsPerPageSelector form-control" defaultValue={resultsPerPage}
                    onChange={onChange}>
                {options}
            </select>
        </div>
    )
})

export default SearchResultsPerPageSelector
