import React, {useContext} from 'react'
import {Button} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {AuthenticationContext} from 'contexts/AuthenticationContext'
import he from 'he'

const GenerateMapButton = (props) => {
    const intl = useIntl()
    const showMapButton = useContext(AuthenticationContext).isWCTAdmin

    if (showMapButton) {
        const btnProps = {}
        /* eslint-disable react/forbid-foreign-prop-types */
        Object.keys(Button.propTypes).forEach(key => {
            if (Object.hasOwnProperty.call(props, key)) {
                btnProps[key] = props[key]
            }
        })

        return (
            <Button data-id='generateMapButton' bsStyle="primary" {...btnProps} className="cdm-btn">
                <span className="fa fa-map-marker">&nbsp;</span>
                {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_KEY_generate_map_mobile'}))}
            </Button>
        )
    }
    return null
}

export default GenerateMapButton
