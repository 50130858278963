import {useCdmEnterAndReadyEventParams} from 'events/useCdmEnterAndReadyEventParams'
import {useCdmUpdateEventParams} from 'events/useCdmUpdateEventParams'
import {useCdmEventLifecycle} from 'events/useCdmEventLifecycle'

/**
 * Hook that weaves the standard CDM events (:enter, :ready, :update, and :leave) without any parameters
 *
 * @param {string} cdmPage The UI page/class name as defined in utils/CdmClasses
 * @param {boolean} isComponentReady (optional) When this flag is true the default isComponentReady logic is
 * overridden and determined by the calling component
 */
export const useCdmEvents = (cdmPage, isComponentReady = undefined) => {

    useCdmEventLifecycle(
        cdmPage,
        useCdmEnterAndReadyEventParams(),
        useCdmUpdateEventParams(),
        isComponentReady !== undefined ? isComponentReady : true
    )
}
