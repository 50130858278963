import React, {useCallback, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import useAnalytics from 'hooks/useAnalytics'
import {SavedItemsAction} from 'hooks/useSavedItems'
import {buildStyleString, getStyle} from 'utils/truncation'
import './SelectableItemGridCard.scss'

const SelectableItemGridCard = (props) => {

    const sendAnalytics = useAnalytics()
    const intl = useIntl()
    const titleHolder = useRef(null)

    useEffect(() => {
        if (titleHolder.current) {
            titleHolder.current.style.cssText = buildStyleString(
                titleHolder.current.innerText,
                getStyle(titleHolder.current, 'font-size').replace(/[^-\d.]/g, ''),
                getStyle(titleHolder.current, 'line-height').replace(/[^-\d.]/g, ''),
                3,
                'h2'
            )
        }
    }, [titleHolder])

    const {
        selectEnabled,
        selected,
        dispatch,
        title,
        thumbnailUri,
        thumbnailEnabled,
        collectionAlias,
        itemId
    } = props

    const url = `/digital/collection/${collectionAlias}/id/${itemId}`

    const onCardSelect = useCallback((e) => {
        const type = `${e.target.checked ? 'select' : 'deselect'}`
            sendAnalytics(
                'saved items grid card',
                'click',
                `${type} collection: ${collectionAlias} item: ${itemId}`,
                'select saved item',
                {
                    type: type,
                    collection_alias: collectionAlias,
                    item_id: `${collectionAlias}/id/${itemId}`
                }
        );

        dispatch({ type: SavedItemsAction.SET_SELECTED_SAVED_ITEM, payload: {
            alias: collectionAlias,
            id: itemId,
            selected: e.target.checked,
            intl
        }})

    }, [dispatch, intl, collectionAlias, itemId, sendAnalytics])

    const handleLinkClick = useCallback(() => {
        sendAnalytics(
            'saved items grid card',
            'click',
            `/digital/collection/${collectionAlias}/id/${itemId}`,
            'view saved item',
            {
                collection_alias: collectionAlias,
                item_id: `${collectionAlias}/id/${itemId}`
            }
        );
    },[sendAnalytics, collectionAlias, itemId])

    return (
        <div className="SelectableItemGridCard-container">
            {selectEnabled ? <input className="SelectableItemGridCard-selectItem"
                                    onChange={onCardSelect}
                                    type="checkbox" checked={selected ? 'checked' : ''}
                                    aria-label={`${title} ${selected ? 'selected' : 'not selected'}`} /> : null}
            <Link to={url} onClick={handleLinkClick} tabIndex={0} className="SelectableItemGridCard-item SelectableItemGridCard-container">
                {thumbnailEnabled
                    ? <div className="SelectableItemGridCard-thumbnailHolder">
                        <img src={'/digital' + thumbnailUri} className="SelectableItemGridCard-thumbnail" alt={`${title}`} />
                    </div>
                    : null
                }
                <h2 ref={titleHolder} className="SelectableItemGridCard-cardTitle truncation">{title}</h2>
            </Link>
        </div>
    )
}

export default SelectableItemGridCard
