import {useParams} from 'react-router-dom'
import {useCdmEnterAndReadyEventParams} from 'events/useCdmEnterAndReadyEventParams'
import {useCdmEventLifecycle} from 'events/useCdmEventLifecycle'
import {useCdmUpdateEventParams} from 'events/useCdmUpdateEventParams'
import { useNormalizedCdmEventFields } from 'events/useNormalizedCdmEventFields'

/**
 * Hook that weaves the standard CDM events (:enter, :ready, :update, and :leave) for any page that needs
 * to support these events for the given collection alias.
 *
 * @param {string} cdmPage The UI page/class name as defined in utils/CdmClasses
 * @param {object} updateParams An object representing the collection or any properties that wish to be monitored for updates
 *  @property {string} collectionAlias|collectionId|alias The collection identifier
 *  @property {boolean} isLoading The loading indicator that determines if the component is fetching data. This
 *  is used to determine when to fire the :udpate event. The :update event will fire when the updateParams have change
 *  and isLoading has changed from true to false
 *  @property {*} Any other properties whose changes need to be tracked for :updates
 * @param {boolean} isComponentReady (optional) When this flag is true the default isComponentReady logic is
 * overridden and determined by the calling component
 */
export const useCdmEventsForCollection = (cdmPage, updateParams, isReadyOverride = undefined) => {

    // Use the collection alias from the url
    const enterAndReadyEventPayload = useParams()
    const [enterAndReadyCollectionIdField] = useNormalizedCdmEventFields(enterAndReadyEventPayload)
    const cdmEnterAndReadyEventParams = useCdmEnterAndReadyEventParams(enterAndReadyEventPayload[enterAndReadyCollectionIdField])

    const [updateCollectionIdField] = useNormalizedCdmEventFields(updateParams)
    const cdmUpdateEventParams = useCdmUpdateEventParams(updateParams, updateCollectionIdField)

    const isReady = isReadyOverride === undefined ? !!updateParams[updateCollectionIdField] : isReadyOverride

    useCdmEventLifecycle(
        cdmPage,
        cdmEnterAndReadyEventParams,
        cdmUpdateEventParams,
        isReady
    )
}
