import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import {useIntl} from 'react-intl'
import SearchInfo from 'components/SearchInfo'
import SearchResultsDisplaySelector from 'components/SearchResultsDisplaySelector'
import SearchResultsPerPageSelector from 'components/SearchResultsPerPageSelector'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import {buildSearchUrl} from 'components/Search/SearchUrl'
import PageNumberContext from 'hooks/usePageNumber'
import useAnalytics from 'hooks/useAnalytics'
import './SearchHeaderDesktop.scss'
import he from "he"

const SearchHeaderDesktop = () => {

    const intl = useIntl()
    const history = useHistory()
    const sendAnalytics = useAnalytics()

    const sortBy = he.decode(intl.formatMessage({id: 'SITE_cdm_search_KEY_sortby', defaultMessage: ' '}))
    const relevanceLabel = he.decode(intl.formatMessage({id: 'SITE_CONFIG_nosortRelevance', defaultMessage: ' '}))

    const searchResults = useContext(SearchResultsContext)
    const [searchRequest] = useContext(SearchRequestContext)
    const [,setPageNumber] = useContext(PageNumberContext)

    const {
        sortFields,
        fields
    } = searchResults

    const toValue = function() {
        return Array.from(arguments).join('_')
    }

    const selectedSort = () => {
        let [order, orderDirection] = [fields.order, fields.direction]

        // Override when there is a user defined sort order
        if (searchRequest.order && searchRequest.orderDirection) {
            ({order, orderDirection} = searchRequest)
        }

        const selectedOption = sortFields.find(m => m.order === order && m.ad === orderDirection)
        let value = ''

        if (selectedOption) {
            value = toValue(
                selectedOption.order,
                selectedOption.ad
            )
        }
        return value
    }

    const onSortChange = (e) => {
        const [orderField, orderDirection] = e.currentTarget.value.split('_')
        searchRequest.order = orderField
        searchRequest.orderDirection = orderDirection
        setPageNumber(null) // Mimic cdm-responsive behavior by resetting pagination to default
        searchRequest.page = null
        sendAnalytics('search results', 'click', `sortby: ${orderField} ${orderDirection}`, 'change search sort', { type: `${orderField} ${orderDirection}`})
        history.push(buildSearchUrl(searchRequest))
    }

    const getSelectBoxOptions = () => {
        return sortFields.map((m, i) => {
            let name = m.name
            let direction = ''

            if (!(name === 'Relevance' || name === '')) {
                direction = he.decode(intl.formatMessage({
                    id: m.ad === 'asc'
                        ? 'SITE_cdm_search_KEY_ascending'
                        : 'SITE_cdm_search_KEY_descending', defaultMessage: ' '
                }))
            } else if (name === 'Relevance') {
                name = relevanceLabel
            }
            const value = [name, direction].filter(it => it !== '').join(' ')
            return <option key={i} value={toValue(m.order, m.ad)}>{value}</option>
        })
    }

    return (
        <div className="DesktopSearchHeader-container DesktopSearchHeader-searchHeaderRow hidden-xs">
            <SearchInfo />
            <div className="DesktopSearchHeader-desktopSortBySelectContainer">
                <h3 className="DesktopSearchHeader-desktopSortBySelectLabel" id="desktopSortBySelectLabel">
                    {he.decode(sortBy)}:
                </h3>
                <select id="desktopSortBySelect" className="form-control" onChange={onSortChange}
                        aria-labelledby="desktopSortBySelectLabel" value={selectedSort()}>
                    {getSelectBoxOptions()}
                </select>
            </div>
            <SearchResultsDisplaySelector />
            <SearchResultsPerPageSelector idPrefix="desktop"/>
        </div>
    )
}

export default SearchHeaderDesktop
