import React, {useContext} from 'react'
import {useIntl} from 'react-intl'
import he from 'he'
import {SiteLayoutContext} from "contexts/SiteLayoutContext"
import {LocaleContext} from "contexts/LocaleContext"
import {doubleDecodeMessageByKey} from "utils/Decode";
import cdmClasses from 'utils/CdmClasses'
import './ContentHeader.scss'

const ContentHeader = () => {

    // Alternative to using dangerouslySetInnerHtml -> Here we use the useIntl()
    // hook to allow us to access the message *before* rendering it.  In this case
    // the message is an html string containing i18n that needs to be decoded.
    // This is why we didn't use <FormattedMessage id=...> directly.
    const intl = useIntl()

    const siteConfig = useContext(SiteLayoutContext)

    const getPrefix = () => {
        let prefix = ''
        if (process.env.NODE_ENV !== 'production') {
            prefix = `https://${process.env.REACT_APP_STATIC_HOST}`
        }
        return prefix
    }

    const renderSplashImage = () => {
        return (
            <LocaleContext.Consumer>
                {localeData =>
                    siteConfig.homepage.image.smallImagePath !== ''
                        ? <div className='ContentHeader-mainImageHolder'>
                            <img data-id='siteLandingImage'
                                 alt={he.decode(localeData.messages[siteConfig.homepage.image.imageAltText])}
                                 src={getPrefix() + siteConfig.homepage.image.smallImagePath}
                                 srcSet={`${getPrefix() + siteConfig.homepage.image.smallImagePath} 768w,
                                         ${getPrefix() + siteConfig.homepage.image.mediumImagePath} 1199w,
                                         ${getPrefix() + siteConfig.homepage.image.largeImagePath} 1280w`}
                            />
                        </div>
                        : undefined
                }
            </LocaleContext.Consumer>
        );
    }

    return (
        <div className="ContentHeader-position">
            <div className={`ContentHeader-maincontainer shared-box ${cdmClasses.CONTENT_HEADER}`}>
                {renderSplashImage()}
                <h1 className="sr-only">
                    {doubleDecodeMessageByKey(intl, 'SITE_CONFIG_title')}
                </h1>
                <div tabIndex={0} className="ContentHeader-mainCopyHolder">
                    {doubleDecodeMessageByKey(intl, 'SITE_CONFIG_globalHomePageHtml')}
                </div>
            </div>
        </div>
    )
}

export default ContentHeader
