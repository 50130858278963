import React, {useContext, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import Alert from 'react-bootstrap/lib/Alert'
import FontAwesome from 'react-fontawesome'
import Helmet from "react-helmet"
import CollectionNotFound from 'components/CollectionNotFound'
import SearchProvider from 'components/SearchProvider'
import FetchingNotification from 'components/FetchingNotification'
import SearchResults from 'components/SearchResults'
import SearchHeader from 'components/SearchHeader'
import SearchTerms from 'components/SearchTerms'
import SearchFilters from 'components/SearchFilters'
import {CollectionContext} from 'contexts/CollectionContext'
import {doubleDecodeMessageByKey} from 'utils/Decode'
import './Search.scss'
import he from "he";

const Search = (props) => {

    const intl = useIntl()
    const collectionConfig = useContext(CollectionContext)
    const collectionNotFound = collectionConfig && collectionConfig.status !== 200
    let {collectionAlias} = useParams()
    if (collectionAlias) {collectionAlias = encodeURIComponent(collectionAlias)}
    const singleCollection = collectionAlias && collectionAlias.split('!').length === 1 ? collectionAlias : null
    const containerClassNames = singleCollection
        ? `cdm-collection-page cdm-collection-page-${collectionAlias} cdm-collection-search-page`
        : 'cdm-search-page'

    const messages = {
        search: he.decode(intl.formatMessage({ id: 'SITE_cdm_search_KEY_title_search_results', defaultMessage: ' ' })),
        browse: he.decode(intl.formatMessage({id: 'SITE_cdm_search_KEY_title_browse', defaultMessage: ' '}))
    }

    useEffect(() => { props.setMainClass(containerClassNames) })

    return (
        <SearchProvider render={(isLoading, searchRequest, searchResults, searchErrorComponent) => {
            const searchContainer =
                <div className="Search-container">
                    <Helmet title={searchRequest.query ? messages.search : messages.browse}/>
                    <div className="row">
                        <div className="Search-alertArea">
                            {searchErrorComponent ? searchErrorComponent : null}
                            {isLoading ? <FetchingNotification/> : <></>}
                            {searchRequest && searchRequest.numberOfCollectionsSelected === 0
                                ? <Alert bsStyle="danger">
                                    <FontAwesome name="ban"/>
                                    {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_youmustselectonecollection'}))}
                                </Alert>
                                : <></>
                            }
                            {!isLoading && searchResults && searchResults.items.length === 0
                                ? <Alert bsStyle="warning">
                                    <FontAwesome name="exclamation-circle"/>
                                    {doubleDecodeMessageByKey(intl, "SITE_cdm_search_KEY_no_search_results_found")}
                                </Alert>
                                : <></>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="Search-filterContent col-sm-4 col-lg-3 hidden-xs">
                            {/* Filters & Facets */}
                            {isLoading || searchErrorComponent ? null : <SearchFilters/>}
                        </div>
                        {!isLoading ? <div className="Search-mainContent col-sm-8 col-lg-9" data-id="mainContent">

                            {/* Main Content - Search Header, Paginators, Search results */}

                            {searchErrorComponent ? null : <SearchHeader/>}
                            {searchErrorComponent ? null : <SearchTerms/>}
                            {searchErrorComponent || (searchResults && searchResults.items.length === 0)
                                ? <></>
                                : <SearchResults/>}
                        </div> : null}
                    </div>
                </div>

            return (
                collectionNotFound && singleCollection
                    ? <CollectionNotFound collectionId={singleCollection}/>
                    : searchContainer
            )
        }}>
        </SearchProvider>
    )

}

export default Search
