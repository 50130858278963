import React, {useContext, useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import MobileModal from 'components/MobileModal'
import Panel from 'components/Panel'
import {SearchResultsContext} from 'contexts/SearchResultsContext'
import {MobileModalContext} from 'contexts/MobileModalContext'
import useAnalytics from 'hooks/useAnalytics'
import {buildSearchUrl} from 'components/Search/SearchUrl'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import PageNumberContext from 'hooks/usePageNumber'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {Button} from 'react-bootstrap'
import './SearchSortModalMobile.scss'
import he from "he"

const SearchSortModalMobile = () => {

    const intl = useIntl()
    const history = useHistory()
    const closeModal = useContext(MobileModalContext)
    const sendAnalytics = useAnalytics()
    const searchResults = useContext(SearchResultsContext)
    const [searchRequest] = useContext(SearchRequestContext)
    const [,setPageNumber] = useContext(PageNumberContext)
    const siteSearchConfiguration = useContext(SiteLayoutContext)
    const fields = searchResults.sortFields

    const {
        browseAllDefaultSortField,
        searchResultsDefaultSortField
    } = searchResults

    const browseAscendingIsEnabled = siteSearchConfiguration.browseSortOrderAscIsEnabled
    const searchAscendingIsEnabled = siteSearchConfiguration.searchSortOrderAscIsEnabled
    const relevanceLabel = he.decode(intl.formatMessage({id: 'SITE_CONFIG_nosortRelevance', defaultMessage: ' '}))

    const toValue = function() {
        return Array.from(arguments).join('_')
    }

    const selectedSort = function (fields) {
        let [order, orderDirection] = searchRequest.query
            ? [ searchResultsDefaultSortField, searchAscendingIsEnabled ? 'asc' : 'desc']
            : [ browseAllDefaultSortField, browseAscendingIsEnabled ? 'asc' : 'desc']

        // Override when there is a user defined sort order
        if (searchRequest.order && searchRequest.orderDirection) {
            ({order, orderDirection} = searchRequest)
        }

        const selectedOption = fields.find(m => m.order === order && m.ad === orderDirection)
        let value = ''

        if (selectedOption) {
            value = toValue(
                selectedOption.order,
                selectedOption.ad
            )
        }

        return value
    }

    const [value, setValue] = useState(selectedSort(fields))

    const onChangeSort = (e) => {
        setValue(e.currentTarget.value)
    }

    const wrapOnChangeSort = (e) => {
        onChangeSort(e)
        let [order, direction] = searchRequest.query
            ? [ searchResultsDefaultSortField, searchAscendingIsEnabled ? 'asc' : 'desc']
            : [ browseAllDefaultSortField, browseAscendingIsEnabled ? 'asc' : 'desc']
        let field = fields.find(w => w.ad === direction && w.order === order) || {}
        const directionName = field.order === 'nosort'
            ? ''
            : field.ad === 'asc'
                ? he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_ascending'}))
                : he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_descending'}))
        const name = [field.name, directionName].filter(it => !!it).join(' ')
        sendAnalytics('search results', 'click', `sortby:${name}`, 'change search sort', {type: `${name}`})
    }

    const onSave = useCallback(() => {
        const [orderField, orderDirection] = value.split('_')
        searchRequest.order = orderField
        searchRequest.orderDirection = orderDirection
        setPageNumber(null) // Mimic cdm-responsive behavior by resetting pagination to default
        searchRequest.page = null
        history.push(buildSearchUrl(searchRequest))
        closeModal()
    }, [history, closeModal, searchRequest, setPageNumber, value])

    return <MobileModal>
            <Panel expanded={true} isModal={true} headerText={he.decode(intl.formatMessage({ id: 'SITE_cdm_search_KEY_sortby', defaultMessage: ' '}))}>
                <div className="MobileSortModal-container">
                    {fields.map((m, i) => {
                        let name = m.name
                        let direction = ''

                        if (name === '') {
                            return null
                        }

                        if (name !== 'Relevance') {
                            const directionKey = m.ad === 'asc'
                                ? 'SITE_cdm_search_KEY_ascending'
                                : 'SITE_cdm_search_KEY_descending'
                            direction = intl.formatMessage({id: directionKey})
                        } else {
                            name = relevanceLabel
                        }

                        const checked = toValue(m.order, m.ad) === value

                        return <div key={i} className="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sort"
                                    onChange={wrapOnChangeSort}
                                    value={toValue(m.order, m.ad)}
                                    checked={checked}
                                />
                                {name} {direction}
                            </label>
                        </div>
                    })}

                    <Button bsStyle="primary" className="cdm-btn" block onClick={onSave}>
                        {he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_cdm_search_KEY_save'}))}
                    </Button>
                </div>
            </Panel>
        </MobileModal>
}

export default SearchSortModalMobile
