import React, {useEffect} from 'react'
import './Card.scss'
import cdmClasses from 'utils/CdmClasses'
import { Link } from 'react-router-dom'
import {useTracking} from "react-tracking"
import {collectEventAnalytics} from "metrics/Analytics"
import isAbsoluteURI from 'utils/isAbsoluteURI'
import he from "he"
import {buildStyleString, getStyle} from 'utils/truncation';

const Card = (props) => {

    const tracking = useTracking()
    const titleRef = React.createRef()
    const bodyRef = React.createRef()

    useEffect(() => {
        updateTruncation()
        //eslint-disable-next-line
    }, [])

    const updateTruncation = () => {
        titleRef.current.style.cssText = buildStyleString(
            titleRef.current.innerText,
            getStyle(titleRef.current, 'font-size').replace(/[^-\d.]/g, ''),
            getStyle(titleRef.current, 'line-height').replace(/[^-\d.]/g, ''),
            2
        )

        bodyRef.current.style.cssText = buildStyleString(
            bodyRef.current.innerText,
            getStyle(bodyRef.current, 'font-size').replace(/[^-\d.]/g, ''),
            getStyle(bodyRef.current, 'line-height').replace(/[^-\d.]/g, ''),
            3
        );
    }

    const cardAttrs = (alias) => {
        if (isAbsoluteURI(alias)) {
            return collectEventAnalytics(tracking,
                'home page',
                'click',
                alias,
                'open collection',
                {collection_alias: alias}
            )
        }
        return collectEventAnalytics(tracking,
            'home page',
            'click',
            `/digital/collection/${alias}`,
            'open collection',
            {collection_alias: alias}
        )
    }

    const getUrl = () => {
        const { alias, customURL } = props
        return customURL || `/digital/collection/${alias}`
    }

    const renderCardLink = () => {

        const { customURL, alias } = props

        return customURL
            ? <a href={getUrl()} className={'Card-fullcard shared-box'} onClick={() => {cardAttrs(customURL|| alias)}}>
                {renderCardBody(alias)}
            </a>
            : <Link to={getUrl()} className={'Card-fullcard shared-box'} onClick={() => {cardAttrs(customURL|| alias)}}>
                {renderCardBody(alias)}
            </Link>
    }

    const renderCardBodyText = () => {
        const p = document.createElement('p')
        p.innerHTML = he.decode(props.description)
        let bodyText = p.innerText;
        return <div>
            <p ref={bodyRef} className={'Card-cardText truncation'}
               dangerouslySetInnerHTML={{__html: bodyText}} />
        </div>
    }

    const renderCardBody = (alias) => {

        let imgSrc = `/digital/image/${alias}/thumbnail`
        if (process.env.NODE_ENV !== 'production') {
            imgSrc = `https://${process.env.REACT_APP_STATIC_HOST}/digital/image/${alias}/thumbnail`
        }

        return (
            <div>
                <div className={'Card-cardImageHolder'}>
                    <img className={'Card-cardImage'}
                        src={imgSrc + (props.preview.enabled ? '?v=' + props.preview.version : '')}
                        alt={props.title} />
                </div>

                <div className={'Card-cardTextHolder'}>
                    <div data-alias={`${alias}`}>
                        <h2 ref={titleRef} className={'titleDiv'}>{props.title}</h2>
                    </div>
                    {renderCardBodyText()}

                </div>
            </div>
        )
    }

    return (
        <div className={'Card-cardcontainer col-sm-6 ' + cdmClasses.COLLECTION_CARD}>
            {renderCardLink()}
        </div>
    )
}

export default Card
