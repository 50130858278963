import {useEffect, useReducer} from 'react'
import {fetchSharedItems} from 'service/SharedItemsService'

export const SharedItemsAction = {
    'SET_SHARED_ITEMS': 'setSharedItems',
    'SET_LOADING': 'setLoading'
}

const actions = {
    setSharedItems: (state, action) => {
        return {...state, shared: action.payload, loading: false}
    },
    setLoading: (state, action) => {
        return {...state, loading: action.payload}
    }
}

const sharedItemsReducer = (state, action) => {
    return actions[action.type](state, action)
}

export const useSharedItems = (sharedItemsUUID) => {

    const [sharedItems, dispatch] = useReducer(sharedItemsReducer, {
        shared: {
            items: [],
            siteName: null,
            siteUrl: null,
            uuid: null
        },
        loading: true
    })

    useEffect(() => {
        const getSharedItems = async () => {
            if (sharedItemsUUID) {
                const shared = await fetchSharedItems(sharedItemsUUID)
                dispatch({ type: SharedItemsAction.SET_SHARED_ITEMS, payload: shared})
            } else {
                dispatch({ type: SharedItemsAction.SET_LOADING, payload: false })
            }
        }
        getSharedItems()
    }, [sharedItemsUUID])

    return [sharedItems, dispatch]
}

