import React from 'react'
import FontAwesome from "react-fontawesome"
import {useIntl} from "react-intl"
import {Alert} from "react-bootstrap"
import he from 'he'

const ItemNotFound = () => {
    const intl = useIntl()
    return (
        <Alert bsStyle={'danger'} data-id={'itemNotFound'}>
            <FontAwesome name="ban" />
            <span>{he.decode(intl.formatMessage({defaultMessage: ' ', id: 'SITE_error_KEY_error_6'}))}</span>
        </Alert>
    )
}
export default ItemNotFound
