import React from 'react'
import SearchHeaderDesktop from 'components/SearchHeaderDesktop'
import SearchHeaderMobile from 'components/SearchHeaderMobile'
import SearchResultsOptionHeaderMobile from 'components/SearchResultsOptionHeaderMobile'
import './SearchHeader.scss'

const SearchHeader = () => {

    return (
        <div>
            <div className="SearchHeader-container shared-search-box shared-box cdm-content-header">
                <SearchHeaderDesktop />
                <SearchHeaderMobile />
            </div>

            <SearchResultsOptionHeaderMobile />
        </div>
    );
}

export default SearchHeader
