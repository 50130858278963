import React, {useCallback, useContext} from 'react'
import FontAwesome from 'react-fontawesome'
import {FormattedNumber, useIntl} from 'react-intl'
import {useHistory} from 'react-router-dom'
import BackToResults from 'components/BackToResults'
import {CurrentItemContext} from 'contexts/CurrentItemContext'
import {ItemPagingContext} from 'contexts/ItemPagingContext'
import useAnalytics from 'hooks/useAnalytics'
import {turnOffLoadingNextPrevItem, turnOnLoadingNextPrevItem} from 'hooks/useItem'
import {itemPagingReady} from 'hooks/useItemPaging'
import {useResultsPerPage} from 'hooks/useResultsPerPage'
import {search} from 'service/SearchService'
import {getFullTextTotals} from 'service/ItemSearchInfo'
import {parseUrlForSearchRequest} from 'utils/UrlParameters'
import './ItemViewPager.scss'
import he from "he";

const ItemViewPager = () => {

    const intl = useIntl()
    const history = useHistory()
    const sendAnalytics = useAnalytics()
    const [, itemPagingDispatch] = useContext(ItemPagingContext)
    const [itemState, itemDispatch] = useContext(CurrentItemContext)
    const {item} = itemState
    const [resultsPerPage] = useResultsPerPage()
    const {searchResults: searchResultsState} = history.location.state || {}
    const {totalResults, paginatedItems, url} = searchResultsState || {totalResults: 0, paginatedItems: [], url: ''}
    const recNumber = history.location.pathname.split('/rec/')[1]
    const currentPage = Math.ceil(recNumber/resultsPerPage)
    const currentItemIndex = recNumber - ((currentPage - 1) * resultsPerPage) - 1
    const isCompoundObject = item && !!item.parent

    const message = {
        result     : { id: 'SITE_KEY_result', defaultMessage: ' ' },
        previous   : { id: 'SITE_KEY_previous_record', defaultMessage: ' ' },
        next       : { id: 'SITE_KEY_next_record', defaultMessage: ' ' }
    }

    const previous = he.decode(intl.formatMessage(message.previous))
    const next = he.decode(intl.formatMessage(message.next))

    const searchWithPage = useCallback(async (newPage) => {
        const searchRequest = await parseUrlForSearchRequest({ pathname: url })
        const searchResult = await search(searchRequest, resultsPerPage, newPage)
        return [searchResult.items, searchResult.totalResults]
    }, [resultsPerPage, url])

    const handleSearch = useCallback(async (newPageNumber, index) => {
        let factor = ((currentPage - 1) * resultsPerPage) + index
        itemDispatch(turnOnLoadingNextPrevItem())
        const [paginatedItems, newTotalResults] = await searchWithPage(newPageNumber)

        let newSelectedIndex = newPageNumber < currentPage
            ? paginatedItems.length - 1
            : 0

        const {collectionAlias, itemId} = paginatedItems[newSelectedIndex]
        itemPagingDispatch(itemPagingReady(paginatedItems, newTotalResults))
        itemDispatch(turnOffLoadingNextPrevItem())
        const searchTerm = history.location.state && history.location.state.searchTerm
        let itemSearchInfo = searchTerm ? await getFullTextTotals(collectionAlias, itemId, searchTerm) : null
        history.push({
            pathname: `/digital/collection/${collectionAlias}/id/${itemSearchInfo && itemSearchInfo.records[0]
                ? itemSearchInfo.records[0][0] : itemId}/rec/${factor + 1}`,
            state: {...history.location.state, itemSearchInfo: itemSearchInfo, shouldScroll: false,
                searchResults: {paginatedItems: paginatedItems, totalResults: newTotalResults, url: url}}
        })

    }, [itemPagingDispatch, history, searchWithPage, currentPage, itemDispatch, resultsPerPage, url])

    const handleClick = useCallback(async (index, analyticCategory) => {
        let factor = ((currentPage - 1) * resultsPerPage) + index
        if (index > paginatedItems.length - 1) {
            await handleSearch(currentPage + 1, index)
        } else if (index < 0) {
            await handleSearch(currentPage - 1, index)
        } else {
            const {collectionAlias, itemId} = paginatedItems[index]
            let resultType = analyticCategory === 'previous result' ? 'previous' : 'next';
                sendAnalytics(
                    isCompoundObject ? 'compound object' : 'single item',
                    'click',
                    `${analyticCategory}: /digital/collection/${collectionAlias}/id/${itemId}/rec/${factor + 1}`,
                    'move to next result',
                    {
                        type: `${resultType}`,
                        collection_alias: `${collectionAlias}`,
                        item_id: `${collectionAlias}/id/${itemId}`
                    }
                );
            itemDispatch(turnOnLoadingNextPrevItem())
            let searchTerm = history.location.state && history.location.state.searchTerm
            let itemSearchInfo = searchTerm ? await getFullTextTotals(collectionAlias, itemId, searchTerm) : null
            history.push({
                pathname: `/digital/collection/${collectionAlias}/id/${itemSearchInfo && itemSearchInfo.records[0]
                    ? itemSearchInfo.records[0][0] : itemId}/rec/${factor + 1}`,
                state: {...history.location.state, itemSearchInfo: itemSearchInfo, url: url, shouldScroll: false}
            })
        }

    }, [paginatedItems, url, resultsPerPage, history, handleSearch, currentPage, itemDispatch, sendAnalytics, isCompoundObject])

    const handlePreviousClick = useCallback(async () => {
        await handleClick(currentItemIndex - 1, 'previous result')
    }, [handleClick, currentItemIndex])

    const handleNextClick = useCallback(async () => {
        await handleClick(currentItemIndex + 1, 'next result')
    }, [handleClick, currentItemIndex])

    const calcBound = useCallback(() => {
        return ((currentPage - 1) * resultsPerPage) + currentItemIndex
    }, [currentPage, resultsPerPage, currentItemIndex])

    return ((totalResults > 0 && url) || searchResultsState)
        ? <div className="ItemView-itemViewPager ItemViewPager-pager">
            <button aria-label={previous} title={previous} type="button"
                    className="cdm-btn btn btn-primary ItemViewPager-angle"
                    onClick={handlePreviousClick} disabled={calcBound() < 1}>
                <FontAwesome name="angle-left" className="fa-2x" />
            </button>

            <span className="ItemViewPager-result">
                {`${he.decode(intl.formatMessage(message.result))} ${intl.formatNumber(recNumber)} of `}
                <FormattedNumber value={totalResults} />
            </span>

            <button aria-label={next} title={next} type="button"
                    className="cdm-btn btn btn-primary ItemViewPager-angle"
                    onClick={handleNextClick} disabled={calcBound() >= totalResults - 1}>
                <FontAwesome name="angle-right" className="fa-2x" />
            </button>
            <BackToResults />
        </div>
        : <></>
}

export default ItemViewPager
