import {useReducer} from 'react'

export const SavedItemsLinkAction = {
    'SHOW_CREATE_LINK_MODAL': 'showCreateLinkModal',
    'HIDE_CREATE_LINK_MODAL': 'hideCreateLinkModal',
    'SHOW_LINK_CREATED_MODAL': 'showLinkCreatedModal',
    'HIDE_LINK_CREATED_MODAL': 'hideLinkCreatedModal',
    'SET_RECAPTCHA_SUCCESS': 'setRecaptchaSuccess',
    'COPY_LINK_TO_CLIPBOARD': 'copyLinkToClipboard',
    'START_LINK_CREATION': 'startLinkCreation',
    'END_LINK_CREATION': 'endLinkCreation'
}

const actions = {
    showCreateLinkModal: (state) => {
        return {
            ...state,
            showCreateLinkModal: true,
            recaptchaSuccess: false
        }
    },
    hideCreateLinkModal: (state) => {
        return {
            ...state,
            showCreateLinkModal: false,
            recaptchaSuccess: false,
            urlCopied: false
        }
    },
    showLinkCreatedModal: (state) => {
        return {
            ...state,
        }
    },
    hideLinkCreatedModal: (state) => {
        return {
            ...state,
            showLinkCreatedModal: false,
            urlCopied: false
        }
    },
    setRecaptchaSuccess: (state, action) => {
        return {
            ...state,
            recaptchaSuccess: action.payload
        }
    },
    copyLinkToClipboard: (state) => {
        return {
            ...state,
            urlCopied: true
        }
    },
    startLinkCreation: (state) => {
        return {
            ...state,
            isCreatingLink: true
        }
    },
    endLinkCreation: (state, action) => {
        const {loc, port, customLinkText, id} = action.payload

        return {
            ...state,
            showLinkCreatedModal: true,
            showCreateLinkModal: false,
            isCreatingLink: false,
            urlCopied: false,
            createdLinkUrl: `${loc.protocol}//${loc.hostname}${port}${loc.pathname}${customLinkText}${id}`
        }
    }
}

const savedItemsCreateLinkButtonReducer = (state, action) => {
    return actions[action.type](state, action)
}

const useSavedItemsCreateLink = () => {
    return useReducer(savedItemsCreateLinkButtonReducer, {
        showCreateLinkModal: false,
        showLinkCreatedModal: false,
        recaptchaSuccess: false,
        createdLinkUrl: '',
        customLinkText: null,
        isCreatingLink: false,
        urlCopied: false
    })
}

export default useSavedItemsCreateLink
