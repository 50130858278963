import {useCallback, useContext, useEffect, useReducer} from 'react'
import {useParams} from 'react-router-dom'
import {getItemInfo} from 'service/ItemInfo'
import {CollectionContext} from 'contexts/CollectionContext'

/**
 * Creates an itemReady action for the useReducer dispatch call
 *
 * @param {object} itemResponse The item and statuscode that is ready to use. item is defined by the ItemParent class
 * @returns {{payload: {item}, type: string}}
 */
export const itemReady = (itemResponse) => ({
    type: 'itemReady',
    payload: {item: itemResponse.item, statusCode: itemResponse.statusCode}
})

/**
 * Turns on the loading notification when the user clicks the next or previous item page
 *
 * @returns {type: string}
 */
export const turnOnLoadingNextPrevItem = () => ({
    type: 'turnOnLoadingNextPrevItem'
})

/**
 * Turns off the loading notification when the user clicks the next or previous item page
 *
 * @returns {type: string}
 */
export const turnOffLoadingNextPrevItem = () => ({
    type: 'turnOffLoadingNextPrevItem'
})

/**
 * Turns on a loading notification when the user loads a new item level search
 * @returns {{type: string}}
 */
export const turnOnItemSearchLoading = () => ({
    type: 'turnOnItemSearchLoading'
})

/**
 * Turns off a loading notification when the user loads a new item level search
 * @returns {{type: string}}
 */
export const turnOffItemSearchLoading = () => ({
    type: 'turnOffItemSearchLoading'
})

/**
 * Sets the error that occurred in getItemInfo
 *
 * @param error {object} the error object from the getItemInfo call
 * @returns {{payload: {error}, type: string}}
 */
export const setGetItemInfoError = (error) => ({
    type: 'setGetItemInfoError',
    payload: { error }
})

/**
 * Object that contains implementations of all the actions used by this hook with useReducer
 *
 * @type {object}
 */
const actions = {
    itemReady: (state, action) => {
        const {item, statusCode} = action.payload
        return {
            ...state,
            collectionAlias: item.collectionAlias,
            itemId: item && item.id ? item.id.toString() : null,
            item: { ...item },
            isLoading: false,
            statusCode
        }
    },
    turnOnLoadingNextPrevItem: (state) => {
        return { ...state, isLoadingNextPrevItem: true}
    },
    turnOffLoadingNextPrevItem: (state) => {
        return { ...state, isLoadingNextPrevItem: false}
    },
    turnOnItemSearchLoading: (state) => {
        return { ...state, isLoadingItemSearch: true}
    },
    turnOffItemSearchLoading: (state) => {
        return { ...state, isLoadingItemSearch: false}
    },
    setGetItemInfoError: (state, action) => {
        return { ...state, fetchError: action.payload.error, isLoading: false }
    }
}

/**
 * Item reducer function for useReducer hook
 *
 * @param state The requested state for the context to use
 * @param action The name of the action to perform
 * @returns {*}
 */
const itemReducer = (state, action) => {
    return actions[action.type](state, action)
}

/**
 * This hook provides item state as well as a dispatch to perform various actions on the item when necessary
 *
 * @returns {[itemState, dispatch]}
 */
export const useItem = () => {

    const {collectionAlias, itemId} = useParams()

    const [itemState, dispatch] = useReducer(itemReducer, {
        collectionAlias: collectionAlias,
        itemId: itemId,
        item: {
            collectionAlias: null,
            id: null,
            filename: null
        },
        isLoading: true,
        isLoadingNextPrevItem: false,
        isLoadingItemSearch: false,
        fetchError: null
    })

    const fetchItem = useCallback( async () => {
        let itemResponse = await getItemInfo(collectionAlias, itemId)
        if (itemResponse && itemResponse.error) {
            dispatch(setGetItemInfoError(itemResponse.error))
        }
        if (itemResponse && itemResponse.item && !itemResponse.item.error) {
            dispatch(itemReady(itemResponse))
            dispatch(turnOffLoadingNextPrevItem())
        }
    }, [collectionAlias, itemId])

    const collectionConfig = useContext(CollectionContext)

    useEffect(() => {
        if (collectionConfig && collectionConfig.body && itemState.item.id !== parseInt(itemId)) {
            fetchItem()
        }
        //eslint-disable-next-line
    }, [collectionAlias, itemId, fetchItem, collectionConfig])

    return [itemState, dispatch]
}
