import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import {buildSearchUrl} from 'components/Search/SearchUrl'
import {SearchRequestContext} from 'contexts/SearchRequestContext'
import {useMobileModalClose} from 'hooks/useMobileModalClose'
import useAnalytics from 'hooks/useAnalytics'
import './Facet.scss'

const Facet = ({field, title, count}) => {

    let [searchRequest] = useContext(SearchRequestContext)
    const sendAnalytics = useAnalytics()
    const closeModal = useMobileModalClose()

    const buildSearchUrlWithFacet = () => {
        let replacementRegEx = /%2F/gi;
        const urlTitle = encodeURIComponent(title).replace(replacementRegEx, '%252F')
        searchRequest = {
            ...searchRequest,
            query: searchRequest.query ? `${urlTitle}!${searchRequest.query}` : urlTitle,
            field: searchRequest.field ? `${field}!${searchRequest.field}` : searchRequest.query ? `${field}!all` : field,
            mode: searchRequest.mode ? `exact!${searchRequest.mode}` : searchRequest.query ? 'exact!all' : 'exact',
            connector: searchRequest.connector ? `and!${searchRequest.connector}` : searchRequest.query ? 'and!all' : 'and',
            page: ''
        }

        return buildSearchUrl(searchRequest)
    }

    const percentLink = () => {
        const handleClick = () => {
            closeModal()
            sendAnalytics('search results',
                'click',
                `facet: ${title}`,
                'select search facet',
                {
                    title: `${title}`
                })
        }
        const url = buildSearchUrlWithFacet()
        //React-Router v5 contains bugged version of history dependency -- V6 release est. 2021
        return title.includes('%')
            ? <a href={url} onClick={handleClick}>{title} ({count})</a>
            : <Link to={url} onClick={handleClick}>{title} ({count})</Link>

    }

    return (
        <div className="Facet-container">
            {percentLink()}
        </div>
    )
}

export default Facet
