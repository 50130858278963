import React, {useState, useCallback, useRef} from 'react'
import {useIntl} from 'react-intl'
import {doubleDecode} from 'utils/doubleDecode'
import {Button, Modal} from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import Spinner from 'components/Spinner'
import './SavedItemsCreateLinkModal.scss'
import he from "he";

const SavedItemsCreateLinkModal = (props) => {

    const intl = useIntl()

    const {
        show,
        numberOfSelectedSavedItems,
        onCancel,
        onCreateLink,
        onRecaptchaSuccess,
        enableCreateLinkButton,
        isCreatingLink
    } = props

    // Odin forgive me
    const hostname = window && window.location && window.location.hostname
    const devDomains = RegExp(/(cdm(.*)\.(dev|qa)\.oclc\.org)|(cdm(.*).localhost)/, 'g')

    const [state] = useState({
        recaptchaSiteKey: devDomains.test(hostname)
            ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
            : '6Leh1MsUAAAAAE9w4ePS1lIwwkQ0-wJBzsB1vrgr'
    })

    const customLink = useRef()

    const preventInvalidCharacters = useCallback((e) => {
        const char = /[a-z0-9_-]/gi
        if (!char.test(e.key)) {
            e.preventDefault()
        }
    }, [])

    const onOk = useCallback(() => {
        const customLinkText = customLink.current.value
        onCreateLink(
            customLinkText !== null && customLinkText !== undefined && customLinkText !== ''
                ? `/${customLinkText}/`
                : '/'
        )
    }, [onCreateLink])

    return <Modal show={show} className="SavedItemsCreateLinkModal-createLinkModal"
                  aria-labelledby={he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateLink', defaultMessage: ' ' }))}
                  onHide={onCancel} autoFocus>
        <Modal.Header closeButton className="SavedItemsCreateLinkModal-createLinkHeader">
            <Modal.Title componentClass='h1'>{he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateLink', defaultMessage: ' ' }))}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="SavedItemsCreateLinkModal-createLinkDialogText">
                {he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateSharedLink', defaultMessage: ' ' }, {
                    num_of_items: numberOfSelectedSavedItems
                }))}
                <br /><br />

                {he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateSharedLinkViewItems', defaultMessage: ' ' }))}
                <br /><br />

                {`${he.decode(intl.formatMessage(
                    { id: 'SITE_KEY_ExampleLink', defaultMessage: ' ' }
                ))}: https://cdm10010.contentdm.oclc.org/digital/saved/`}
                <span className="SavedItemsCreateLinkModal-customSection">CustomLinkText</span>
                /1e78aefc-daef-4e11-8e30-e5ebf84c8fd2

                <div className="SavedItemsCreateLinkModal-modalBackground">
                    <div className="SavedItemsCreateLinkModal-header">
                        {he.decode(intl.formatMessage({ id: 'SITE_KEY_Optional', defaultMessage: ' ' }))}
                    </div>
                    <div className="SavedItemsCreateLinkModal-exampleContent">
                        {he.decode(intl.formatMessage({ id: 'SITE_KEY_AddCustomLink', defaultMessage: ' ' }))}
                    </div>

                    <input type="text" maxLength={40} ref={customLink}
                           onKeyPress={preventInvalidCharacters} />

                    <div className="SavedItemsCreateLinkModal-exampleContent" dangerouslySetInnerHTML={{
                        __html: doubleDecode(intl.formatMessage({ id: 'SITE_KEY_CustomSharedLinkExamples', defaultMessage: ' ' }))
                    }} />
                </div>
                <div className="SavedItemsCreateLinkModal-recaptcha">
                    <ReCAPTCHA onChange={onRecaptchaSuccess} sitekey={state.recaptchaSiteKey} />
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer className="SavedItemsCreateLinkModal-createLinkDialogFooter">

            <Button className='cdm-btn' bsStyle='default' onClick={onCancel}>
                {he.decode(intl.formatMessage({ id: 'SITE_KEY_cancel', defaultMessage: ' ' }))}
            </Button>

            {enableCreateLinkButton
                ? isCreatingLink
                    ? <Spinner />
                    : <Button className="SavedItemsCreateLinkModal-modalLinkButton cdm-btn" bsStyle='primary'
                              onClick={onOk}>
                            <span>
                                {he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateLink', defaultMessage: ' ' }))}
                            </span>
                    </Button>
                : <Button className="SavedItemsCreateLinkModal-modalLinkButton cdm-btn" bsStyle='primary' disabled>
                        <span>
                            {he.decode(intl.formatMessage({ id: 'SITE_KEY_CreateLink', defaultMessage: ' ' }))}
                        </span>
                </Button>
            }

        </Modal.Footer>
    </Modal>
}

export default SavedItemsCreateLinkModal
