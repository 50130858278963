/**
 * Determines which fields of the eventParams should be used for the collectionId and itemId fields in the event
 * payload.
 *
 * @param {object} eventParams An object representing event payload
 *  @property {string} collectionAlias|collectionId|alias The collection identifier
 *  @property {string|Number} id|itemId The item identifier
 * @returns {[(string), (string)]}
 */
export const useNormalizedCdmEventFields = (eventParams) => {
    const supportedCollectionIdNames = ['collectionId', 'collectionAlias', 'alias']
    const supportedItemIdNames = ['itemId', 'id' ]

    const collectionIdField = supportedCollectionIdNames.find(collectionIdField => eventParams.hasOwnProperty(collectionIdField))
    const itemIdField = supportedItemIdNames.find(itemIdField => eventParams.hasOwnProperty(itemIdField))
    return [collectionIdField, itemIdField]
}
