import React, {useReducer, useEffect, useContext} from 'react'
import {GRID_VIEW, LIST_VIEW, GRID_VIEW_ENABLED} from 'constants/GridListView'
import {SiteLayoutContext} from 'contexts/SiteLayoutContext'
import {CollectionContext} from 'contexts/CollectionContext'

/**
 * Defines a context for the card view
 *
 * @type {React.Context<string>}
 */
const CardViewContext = React.createContext()
export default CardViewContext

export const Action = {
    'SWITCH_TO_LIST_VIEW': 'switchToListView',
    'SWITCH_TO_GRID_VIEW': 'switchToGridView',
    'SET_GRID_LIST_VIEW': 'setGridListView'
}

const actions = {
    switchToListView: () => {
        localStorage.setItem(GRID_VIEW_ENABLED, 'false')
        return LIST_VIEW
    },
    switchToGridView: () => {
        localStorage.setItem(GRID_VIEW_ENABLED, 'true')
        return GRID_VIEW
    },
    setGridListView: (state, action) => {
        return action.payload
    }
}

const cardViewReducer = (state, action) => {
    return actions[action.type](state, action)
}

/**
 * Custom hook that allows consumers to access the current value of the selected card view as well as update it
 * when needed.
 *
 * @returns {object} the cardView and setCardView functions
 */
export const useCardView = () => {

    const {search} = useContext(SiteLayoutContext)
    const collData = useContext(CollectionContext)

    const overrideGlobal = collData && collData.collSearchInfo && collData.collSearchInfo.gridOverrideGlobal
    const isCollectionLevelGrid = collData && collData.collSearchInfo && collData.collSearchInfo.gridViewEnabled
    const isLocalStorageEmpty = localStorage.getItem(GRID_VIEW_ENABLED) === undefined || localStorage.getItem(GRID_VIEW_ENABLED) === null

    // User selected values have precedence over WCT configurations for duration of the session
    const newView = isLocalStorageEmpty
        ? overrideGlobal
            ? isCollectionLevelGrid
                ? GRID_VIEW : LIST_VIEW
            : search.gridViewEnabled ? GRID_VIEW : LIST_VIEW
        : localStorage.getItem(GRID_VIEW_ENABLED) === 'true' ? GRID_VIEW : LIST_VIEW

    const [returnedView, dispatch] = useReducer(cardViewReducer, newView)

    useEffect(() => {
        dispatch({type: Action.SET_GRID_LIST_VIEW, payload: newView})
    },[collData, newView])

    return [returnedView, dispatch]
}